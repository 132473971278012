import clsx from '@alexis/helpers/clsx';
import React, { useState, useEffect, useRef } from 'react';

import styles from '../styles/components/Image.module.scss';

const Image: React.FC<ImageProps> = ({
  className,
  style,
  isLazy,
  imageUrl,
  noImageUrl,
  alt,
  onClick,
}): JSX.Element => {
  const intersectionObserverRef = useRef<IntersectionObserver>();
  const imgRef = useRef<HTMLImageElement>(null);

  const isMounted = useRef<boolean>(true);

  const [isInViewPort, setIsInViewPort] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [currentImageUrl, setCurrentImageUrl] = useState<string>();

  const getIntersectionObserver = (): IntersectionObserver => {
    if (!intersectionObserverRef.current) {
      intersectionObserverRef.current = new IntersectionObserver(
        (entries: Array<IntersectionObserverEntry>, observer: IntersectionObserver) => {
          for (const entry of entries) {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              setIsInViewPort(true);
            }
          }
        },
      );
    }
    return intersectionObserverRef.current;
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!isLazy && 'IntersectionObserver' in window) {
      const intersectionObserver = getIntersectionObserver();

      intersectionObserver.observe(imgRef.current!);

      return () => {
        intersectionObserver.unobserve(imgRef.current!);
      };
    } else {
      setIsInViewPort(true);
    }
  }, [imageUrl]);

  useEffect(() => {
    setCurrentImageUrl(noImageUrl || '');
  }, [noImageUrl]);

  useEffect(() => {
    if ((!isLazy || (isLazy && isInViewPort && !isLoaded)) && !!imageUrl) {
      const image = new window.Image();

      image.onload = () => {
        if (isMounted.current) {
          setCurrentImageUrl(imageUrl);
        }
      };

      image.onerror = () => {
        if (isMounted.current) {
          setCurrentImageUrl(noImageUrl || '');
        }
      };

      image.src = imageUrl;

      setIsLoaded(true);
    }
  }, [isLazy, isInViewPort, isLoaded, imageUrl]);

  return (
    <img
      ref={imgRef}
      className={clsx(styles.container, className)}
      style={style}
      src={currentImageUrl}
      loading={isLazy ? 'lazy' : undefined}
      alt={alt}
      onClick={onClick}
    />
  );
};

export default Image;
