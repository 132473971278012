import { getSiteConfigByHostname } from '@alexis/helpers/site';
import React, { useMemo } from 'react';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getLocaleParam, getLocaleSearchParam, getPreferredLocale } from '@helpers/locale';

import { useSearchParams } from '@hooks/useSearchParams';

type LocaleNavigatorProps = {
  children: React.ReactNode;
};

export default function LocaleNavigator({ children }: LocaleNavigatorProps) {
  const { pathname, search, state } = useLocation();
  const searchParams = useSearchParams();
  const history = useHistory();

  const currentSite = useMemo(() => getSiteConfigByHostname(window.location.hostname), []);

  const localeParam = getLocaleParam(pathname);
  const localeSearchParam = getLocaleSearchParam(searchParams);
  const preferredLocale = getPreferredLocale(pathname);

  const activeLocale = localeSearchParam || localeParam || currentSite.defaultLocale;

  const expectedLocale =
    localeSearchParam || localeParam || preferredLocale || currentSite.defaultLocale;

  const normalizeLocaleRoute = useCallback(() => {
    if (!localeParam) return;

    const normalizedLocaleHref = (() => {
      const pathnameWithoutLocaleParam = pathname.replace(`/${localeParam}`, '');

      if (
        localeParam === currentSite.defaultLocale ||
        !currentSite.supportedLocales.includes(localeParam)
      ) {
        const searchParams = new URLSearchParams(search);
        if (!localeSearchParam) {
          searchParams.set('ulang', localeParam);
        }

        return {
          pathname: pathnameWithoutLocaleParam || '/',
          search: searchParams.toString(),
        };
      }

      return {
        pathname: `/${localeParam}${pathnameWithoutLocaleParam}`.replace(/\/$/, '') || '/',
        search,
      };
    })();

    if (normalizedLocaleHref.pathname === pathname && normalizedLocaleHref.search === search)
      return;

    history.replace({ ...normalizedLocaleHref, state });
  }, [
    currentSite.defaultLocale,
    currentSite.supportedLocales,
    localeParam,
    localeSearchParam,
    history,
    pathname,
    search,
    state,
  ]);

  const applyPreferredLocale = useCallback(() => {
    // Disregard preferred locale if locale is found in the url
    if (localeSearchParam || localeParam || !preferredLocale) return;

    const localeHref = (() => {
      if (preferredLocale === currentSite.defaultLocale) {
        return {
          pathname,
          search,
        };
      }

      if (currentSite.supportedLocales.includes(preferredLocale)) {
        const searchParams = new URLSearchParams(search);

        return {
          pathname: `/${preferredLocale}${pathname}`.replace(/\/$/, '') || '/',
          search: searchParams.toString(),
        };
      }

      const searchParams = new URLSearchParams(search);
      searchParams.set('ulang', preferredLocale);

      return {
        pathname,
        search: searchParams.toString(),
      };
    })();

    if (localeHref.pathname === pathname && localeHref.search === search) return;

    history.replace({ ...localeHref, state });
  }, [
    currentSite.defaultLocale,
    currentSite.supportedLocales,
    localeParam,
    localeSearchParam,
    history,
    pathname,
    preferredLocale,
    search,
    state,
  ]);

  useEffect(() => {
    normalizeLocaleRoute();
  }, [normalizeLocaleRoute]);

  useEffect(() => {
    applyPreferredLocale();
  }, [applyPreferredLocale]);

  const isProcessingLocale = activeLocale !== expectedLocale;

  if (isProcessingLocale) {
    return null;
  }

  return <>{children}</>;
}
