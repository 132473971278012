type Subscriber = (rates: Array<ExchangeRate>) => void;
type Unsubscribe = () => void;

export function createExchangeRateService() {
  const subscribers = new Set<Subscriber>();
  let intervalId: NodeJS.Timeout | undefined;

  async function fetchExchangeRates(url: string): Promise<Array<ExchangeRate>> {
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json() as Promise<Array<ExchangeRate>>;
  }

  function stopPolling() {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = undefined;
    }
  }

  function notifySubscribers(rates: Array<ExchangeRate>) {
    subscribers.forEach((callback) => callback(rates));
  }

  async function startPolling(url: string) {
    if (intervalId) {
      return;
    }

    const poll = async () => {
      try {
        const rates = await fetchExchangeRates(url);
        notifySubscribers(rates);
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
      }
    };

    // Initial fetch
    await poll();
    // Set up polling every hour
    intervalId = setInterval(poll, 3600000);
  }

  function subscribe(callback: Subscriber): Unsubscribe {
    subscribers.add(callback);

    return () => {
      subscribers.delete(callback);
      if (subscribers.size === 0) {
        stopPolling();
      }
    };
  }

  return {
    startPolling,
    subscribe,
  };
}
