import { FlightSearch } from 'flightSearch';

import { FlightRecentSearchesActionTypes } from './types';

export const addFlightRecentSearch = (
  flightSearch: FlightSearch,
): { type: FlightRecentSearchesActionTypes; payload: FlightSearch } => ({
  type: FlightRecentSearchesActionTypes.AddFlightRecentSearch,
  payload: flightSearch,
});

export const removeFlightRecentSearch = (
  flightSearch: FlightSearch,
): { type: FlightRecentSearchesActionTypes; payload: FlightSearch } => ({
  type: FlightRecentSearchesActionTypes.RemoveFlightRecentSearch,
  payload: flightSearch,
});

export const clearFlightRecentSearches = (): {
  type: FlightRecentSearchesActionTypes;
  payload: null;
} => ({
  type: FlightRecentSearchesActionTypes.ClearFlightRecentSearches,
  payload: null,
});

export const updateFlightRecentSearches = (
  flightRecentSearches: Array<FlightSearch>,
): {
  type: FlightRecentSearchesActionTypes;
  payload: Array<FlightSearch>;
} => ({
  type: FlightRecentSearchesActionTypes.UpdateFlightRecentSearches,
  payload: flightRecentSearches,
});
