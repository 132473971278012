import { HotelPrice } from '@wegoTypes/Hotel/hotelPrice';
import { MetasearchHotelRatePrice } from '@wegoTypes/Metasearch/Hotels/metasearchHotelRatePrice';

export const convertFlightPriceToPrice = (
  flightPrice: FlightPrice,
  showTotalPrice: boolean,
): Price => {
  const { currencyCode, amount, amountUsd, totalAmount, totalAmountUsd } = flightPrice;

  return {
    amount: showTotalPrice ? totalAmount : amount,
    amountUsd: showTotalPrice ? totalAmountUsd : amountUsd,
    currencyCode,
  };
};

export const convertHotelPriceToPrice = (
  hotelPrice: HotelPrice | MetasearchHotelRatePrice,
  showTotalPrice: boolean,
  isIncludingTax: boolean,
): Price => {
  const {
    currencyCode,
    taxInclusive,
    amount,
    amountUsd,
    totalAmount,
    totalAmountUsd,
    taxAmount,
    taxAmountUsd,
    totalTaxAmount,
    totalTaxAmountUsd,
    localTaxAmount,
    localTaxAmountUsd,
    totalLocalTaxAmount,
    totalLocalTaxAmountUsd,
  } = hotelPrice;

  let calculatedAmount: number = 0;
  let calculatedAmountUsd: number = 0;

  let toBeCalculatedAmount = taxInclusive ? amount - taxAmount : amount;
  let toBeCalculatedAmountUsd = taxInclusive ? amountUsd - taxAmountUsd : amountUsd;
  let toBeCalculatedTaxAmount = taxAmount;
  let toBeCalculatedTaxAmountUsd = taxAmountUsd;
  let toBeCalculatedLocalTaxAmount = localTaxAmount;
  let toBeCalculatedLocalTaxAmountUsd = localTaxAmountUsd;

  if (showTotalPrice) {
    toBeCalculatedAmount = taxInclusive ? totalAmount - totalTaxAmount : totalAmount;
    toBeCalculatedAmountUsd = taxInclusive ? totalAmountUsd - totalTaxAmountUsd : totalAmountUsd;
    toBeCalculatedTaxAmount = totalTaxAmount;
    toBeCalculatedTaxAmountUsd = totalTaxAmountUsd;
    toBeCalculatedLocalTaxAmount = totalLocalTaxAmount;
    toBeCalculatedLocalTaxAmountUsd = totalLocalTaxAmountUsd;
  }

  if (isIncludingTax) {
    calculatedAmount =
      toBeCalculatedAmount +
      toBeCalculatedTaxAmount +
      (toBeCalculatedLocalTaxAmount > 0 ? toBeCalculatedLocalTaxAmount : 0);
    calculatedAmountUsd =
      toBeCalculatedAmountUsd +
      toBeCalculatedTaxAmountUsd +
      (toBeCalculatedLocalTaxAmountUsd > 0 ? toBeCalculatedLocalTaxAmountUsd : 0);
  } else {
    calculatedAmount = toBeCalculatedAmount;
    calculatedAmountUsd = toBeCalculatedAmountUsd;
  }

  return { amount: calculatedAmount, amountUsd: calculatedAmountUsd, currencyCode };
};

export const convertBrandedFarePriceToPrice = (brandedFarePrice: BrandedFarePrice): Price => {
  return {
    amount: brandedFarePrice.totalAmount,
    amountUsd: brandedFarePrice.totalAmountUsd,
    currencyCode: brandedFarePrice.currencyCode,
  };
};

export const convertBrandedFareWithoutBookingFee = (brandedFarePrice: BrandedFarePrice): Price => {
  return {
    amount: brandedFarePrice.totalAmount - brandedFarePrice.totalBookingFee,
    amountUsd: brandedFarePrice.totalAmountUsd - brandedFarePrice.totalBookingFeeUsd,
    currencyCode: brandedFarePrice.currencyCode,
  };
};

export const convertBrandedFarePriceOriginalAmountToPrice = (
  brandedFarePrice: BrandedFarePrice,
): Price => {
  return {
    amount: brandedFarePrice.totalOriginalAmount,
    amountUsd: brandedFarePrice.totalOriginalAmountUsd,
    currencyCode: brandedFarePrice.currencyCode,
  };
};

export const addBrandedFarePriceWithAddOnFarePrice = (
  brandedFarePrice: BrandedFarePrice,
  addOnFarePrice: AddonFarePrice | undefined,
): Price => {
  const price = convertBrandedFarePriceToPrice(brandedFarePrice);

  if (!!addOnFarePrice) {
    price.amount += addOnFarePrice.totalAmount;
    price.amountUsd += addOnFarePrice.totalAmountUsd;
  }
  return price;
};

export const getTotalBookingFare = (
  brandedFarePrice: BrandedFarePrice,
  addOnFarePrice: AddonFarePrice | undefined,
  paymentProcessingFee: PaymentProcessingFee | undefined,
  promo?: FlightReservePromoCodeResponse,
  isExcludeBookingFee?: boolean,
): Price => {
  const price = isExcludeBookingFee
    ? convertBrandedFareWithoutBookingFee(brandedFarePrice)
    : convertBrandedFarePriceToPrice(brandedFarePrice);

  if (!!addOnFarePrice) {
    price.amount += addOnFarePrice.totalAmount;
    price.amountUsd += addOnFarePrice.totalAmountUsd;
  }

  if (!!paymentProcessingFee) {
    const totalProcessingFee = paymentProcessingFee.amount;
    const totalProcessingInUsd = paymentProcessingFee.amountInUsd;
    price.amount += totalProcessingFee;
    price.amountUsd += totalProcessingInUsd;
  }

  if (!!promo?.applicable) {
    price.amount -= promo.amount;
    price.amountUsd -= promo.amount;
  }

  return price;
};

export const getPaymentProcessingFee = (
  processingFee: PaymentProcessingFee,
  totalBookingFare: Price,
): Price => {
  const totalProcessingFee = processingFee.amount;
  const totalProcessingInUsd = processingFee.amountInUsd;

  return {
    amount: totalProcessingFee,
    amountUsd: totalProcessingInUsd,
    currencyCode: processingFee.currencyCode,
  };
};

export const getPaymentProcessingFeeV2 = (processingFee: PaymentProcessingFee): Price => {
  return {
    amount: processingFee.amount,
    amountUsd: processingFee.amountInUsd,
    currencyCode: processingFee.currencyCode,
  };
};

export const convertPenaltyToPrice = (currencyCode: string, penalty?: Penalty): Price => {
  return {
    amount: penalty?.amount ?? 0,
    amountUsd: penalty?.amountUsd ?? 0,
    currencyCode: penalty?.currencyCode ?? currencyCode,
  };
};

export const isBigValueCurrency = (currencyCode: string): boolean => {
  return /COP|IDR|IRR|KRW|LBP|VND/.test(currencyCode.toUpperCase());
};
