import clsx from '@alexis/helpers/clsx';
import { getCookie } from '@alexis/helpers/cookie';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import {
  dateApiFormat,
  dateDisplayFormat,
  numberOfNights,
  toISOStringWithTimezone,
} from '@alexis/helpers/date';
import isIOS from '@alexis/helpers/device';
import { genzoTrack, genzoTrackCarouselClick, genzoTrackPageView } from '@alexis/helpers/genzo';
import { gtmTrack } from '@alexis/helpers/gtm';
import { generateCUID } from '@alexis/helpers/identity';
import { appendSearchParams } from '@alexis/helpers/searchParams';
import { translateText } from '@alexis/helpers/translation';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import sha256 from 'crypto-js/sha256';
import cuid from 'cuid';
import { uniqBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import { getFlightFlexSearchCities } from '@apis/flight';
import { getPartnerPromos } from '@apis/marketing';

import UseAppButton from '@components/Button/UseAppButton';
import Drawer from '@components/Drawer';
import FoldableSection from '@components/FoldableSection';
import Footer from '@components/home/Footer';
import HeroImage from '@components/home/HeroImage';
import HomePopularDestination from '@components/home/HomePopularDestination';
import MiniBlog from '@components/home/MiniBlog';
import RecommendedHotelFilter from '@components/home/RecommendedHotelFilter';
import ServicesMenu from '@components/home/ServicesMenu';
import MiniHotelCard from '@components/hotels/MiniHotelCard/MiniHotelCard';
import Image from '@components/Image';
import Menu from '@components/Menu';
import ShopcashWallet from '@components/shopcash/ShopcashWallet';

import { GTM_CLIENT_TYPE } from '@constants/tracking';

import { notUndefinedAndNull } from '@helpers/array';
import { isGoogleBot } from '@helpers/bots';
import { convertGuestRoomsToSearchParam, convertHotelSummaryToMiniHotel } from '@helpers/hotel';
import { cloudflareImageTransformations } from '@helpers/imageTransformations';
import {
  airlinesRoute,
  cheapFlightsToRouteTranslation,
  flightsRoute,
  flightsToRoute,
  hotelsRoute,
} from '@helpers/routeTranslation';
import { isWegoShopcashEnabled } from '@helpers/shopCash';

import { useSearchParams } from '@hooks/useSearchParams';

import MenuIcon from '@icons/menu.svg';

import {
  getApiBaseUrlState,
  getCurrencyState,
  getCurrentSiteState,
  getExchangeRateState,
  getHotelRecentSearchesState,
  getIsRtlState,
  getLocaleState,
  getShopCashUserState,
  getTranslationsState,
  getUserState,
  getWebEngageState,
} from '@redux/selectors';

import styles from '@styles/components/home/Home.module.scss';

import { HomePageType } from '@wegoTypes/homePageType';
import { HotelSummary } from '@wegoTypes/Hotel/hotelSummary';
import { HotelSearch } from '@wegoTypes/Hotel/Search/hotelSearch';
import type { Location } from '@wegoTypes/location';
import { PartnerPromo, PartnerPromoGenzoData } from '@wegoTypes/PartnerPromo/partnerPromo';

import NtoBannerAd from './NtoBannerAd';
import Propositions from './Propositions';

interface HomeProps {
  clientId: string | undefined;
  clientSessionId: string | undefined;
  domainBasedApiBaseUrl: string;
  gtmAuth: string;
  gtmPreview: string;
  homepage: Homepage | undefined;
  nearestCity: Location | undefined;
}

const Home: React.FC<HomeProps> = ({
  clientId,
  clientSessionId,
  domainBasedApiBaseUrl,
  gtmAuth,
  gtmPreview,
  homepage,
  nearestCity,
}) => {
  const { webEngageAnonymousId } = useSelector(getWebEngageState);
  const apiBaseUrl = useSelector(getApiBaseUrlState);
  const hotelRecentSearches: Array<HotelSearch> = useSelector(getHotelRecentSearchesState);
  const locale: string = useSelector(getLocaleState);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const currency: Currency = useSelector(getCurrencyState);
  const exchangeRate: ExchangeRate = useSelector(getExchangeRateState);
  const translations = useSelector(getTranslationsState);
  const isRtl = useSelector(getIsRtlState);
  const user = useSelector(getUserState);
  const shopCashUser: ShopCashUser | null = useSelector(getShopCashUserState);
  const hotelRecentSearch = hotelRecentSearches[0];

  const [pageViewId, setPageViewId] = useState<string>('');

  const [isUseAppGreen, setIsUseAppGreen] = useState<boolean>(false);
  const [headerBarStyle, setHeaderBarStyle] = useState<React.CSSProperties>({});
  const [headerBarLogoStyle, setHeaderBarLogoStyle] = useState<React.CSSProperties>({});
  const [headerBarIconStyle, setHeaderBarIconStyle] = useState<React.CSSProperties>({});
  const [flightsAndHotelsMiniLinkContainerStyle, setFlightsAndHotelsMiniLinkContainerStyle] =
    useState<React.CSSProperties>({});

  const [isAppInstallBannerCollapse, setIsAppInstallBannerCollapse] = useState<boolean>(false);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [popularDestinations, setPopularDestinations] = useState<Array<FlexSearchCity>>([]);
  const [nearbyHotels, setNearbyHotels] = useState<Array<HotelSummary>>([]);
  const [recommendedHotelFilters, setRecommendedHotelFilters] = useState<
    Array<RecommendedHotelFilter>
  >([]);
  const [partnerPromos, setPartnerPromos] = useState<Array<PartnerPromo>>([]);

  const [nearbyHotelsSearchUrl, setNearbyHotelsSearchUrl] = useState<string>();
  const [nearbyHotelsCheckInDate, setNearbyHotelsCheckInDate] = useState<string>();
  const [nearbyHotelsCheckOutDate, setNearbyHotelsCheckOutDate] = useState<string>();

  const { search } = useLocation();
  const params = useParams<{ locale?: string }>();

  const searchParams = useSearchParams();
  const wegoSourceSearchParam = searchParams.get('wg_source');
  const isBowOnly = searchParams.get('bow_only') === 'true';

  // Genzo Page View Tracking
  useEffect(() => {
    if (!isGoogleBot() && !!clientId && !!clientSessionId && !!webEngageAnonymousId) {
      const clientData: any = {
        id: clientId,
        session_id: clientSessionId,
        ...(!!user && { user_hash: user.userHash }),
      };

      const pageData: any = {
        name: 'Main Homepage',
        product: 'homepage',
        base_type: 'homepage',
        sub_type: 'homepage',
        locale: locale,
        site_code: currentSite.countryCode,
        url: window.location.href,
        referrer_url: document.referrer,
      };

      const pageViewId = generateCUID();
      setPageViewId(pageViewId);

      const data = {
        id: pageViewId,
        client: clientData,
        page: pageData,
        created_at: toISOStringWithTimezone(new Date()),
        external_services: {
          crm_id: user ? user.userHash : webEngageAnonymousId,
        },
      };

      genzoTrackPageView(apiBaseUrl, data);
    }
  }, [user, clientId, clientSessionId, webEngageAnonymousId]);

  // Todo: Remove old genzo visits
  useEffect(() => {
    if (!isGoogleBot() && !!clientId && !!clientSessionId && !!pageViewId) {
      let visitData: any = {
        id: pageViewId,
        client: {
          app_type: 'MOBILE_WEB_APP',
          resolution: window.screen.availHeight + 'x' + window.screen.availWidth,
          id: clientId,
          session_id: clientSessionId,
          ...(!!user && { user_hash: user.userHash }),
        },
        campaign: {
          source: wegoSourceSearchParam,
          ts_code: getCookie('wego_ts_code') ?? null,
        },
        page: {
          locale: locale,
          site_code: currentSite.countryCode,
          url: window.location.href,
          referrer_url: document.referrer,
          name: 'Main Homepage',
          base_type: 'homepage',
        },
        created_at: new Date().toISOString(),
        ...(!!user && { user: { email: user.email } }),
        external_service: {
          crm_id: user ? user.userHash : webEngageAnonymousId,
        },
      };

      genzoTrack(`${apiBaseUrl}/genzo/v2/visits`, visitData);
    }
  }, [user, clientId, clientSessionId, pageViewId]);

  // Gtm Tracking
  useEffect(() => {
    if (!isGoogleBot() && !!clientId && !!clientSessionId && !!nearestCity) {
      const pageData: HomeGtmPageData = {
        client_type: GTM_CLIENT_TYPE,
        url: window.location.href,
        domain: window.location.hostname,
        locale: locale,
        site_code: currentSite.countryCode,
        user_country_code: nearestCity.countryCode,
        session_id: clientSessionId,
        user_id: getCookie('wego_user_id') ?? null,
        ts_code: getCookie('wego_ts_code') ?? null,
        event: 'pageChanged',
        page_name: 'Main Homepage',
        page_type: 'homepage',
        product: 'home',
        ...(!!user?.email && { e_id: sha256(user?.email).toString() }),
      };

      gtmTrack('GTM-K5FWXG4', gtmAuth, gtmPreview, pageData);
    }
  }, [locale, clientId, clientSessionId, nearestCity, user]);

  // Attached to window scroll event for transition of header bar and sticky flights and hotels link
  useEffect(() => {
    const handleScroll = (ev: Event): void => {
      const scrollY = window.scrollY;
      // const headerBarOpacity = Math.min(1, Math.max(0, scrollY) / 124);
      const headerBarOpacity = Math.min(1, Math.max(0, scrollY) / 62);
      setIsUseAppGreen(headerBarOpacity === 1);
      setHeaderBarStyle({ backgroundColor: `rgba(255, 255, 255, ${headerBarOpacity})` });
      setHeaderBarLogoStyle({ opacity: headerBarOpacity });
      setHeaderBarIconStyle({
        transition: 'all 200ms ease-in-out',
        fill: headerBarOpacity > 0.5 ? '#44b50c' : 'white',
      });

      const flightsAndHotelsMiniLinkContainerOpacity = Math.min(1, Math.max(0, scrollY - 68) / 56);
      setFlightsAndHotelsMiniLinkContainerStyle({
        transform: `translateY(${Math.min(56, -48 + (56 / 124) * scrollY)}px)`,
        opacity: flightsAndHotelsMiniLinkContainerOpacity,
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!!nearestCity) {
      const checkInDate = new Date();
      setNearbyHotelsCheckInDate(dateApiFormat(checkInDate));

      const checkOutDate = new Date();
      checkOutDate.setDate(checkInDate.getDate() + 1);
      setNearbyHotelsCheckOutDate(dateApiFormat(checkOutDate));

      setNearbyHotelsSearchUrl(
        `${hotelsRoute(
          currentSite,
          params.locale,
        )}/searches/${nearestCity.cityCode.toLowerCase()}/${dateApiFormat(
          checkInDate,
        )}/${dateApiFormat(checkOutDate)}`,
      );
    }
  }, [nearestCity]);

  // Get flex search cities
  useEffect(() => {
    if (!!nearestCity) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initFlexSearchCities = async (cancelToken: CancelToken): Promise<void> => {
        try {
          const response = await getFlightFlexSearchCities(
            apiBaseUrl,
            locale,
            nearestCity.cityCode,
            currency.code,
            'roundtrip',
            10,
            cancelToken,
          );
          setPopularDestinations(response.cities);
        } catch (_) {}
      };

      initFlexSearchCities(cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [nearestCity, apiBaseUrl, locale, currency]);

  // Get partner promos
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initPartnerPromos = async (cancelToken: CancelToken): Promise<void> => {
      try {
        const response = await getPartnerPromos(
          apiBaseUrl,
          locale,
          currentSite.countryCode,
          'mobile',
          'MOBILE_WEB_APP',
          cancelToken,
        );
        setPartnerPromos(response.data.list);
      } catch (_) {}
    };

    initPartnerPromos(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [currentSite, locale]);

  // Get nearby hotels
  useEffect(() => {
    if (!!nearestCity) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initNearbyHotels = async (cancelToken: CancelToken): Promise<void> => {
        const searchParams: any = {
          app_type: 'MOBILE_WEB_APP',
          locale,
          currency_code: currency.code,
          site_code: currentSite.countryCode,
          city_code: nearestCity.cityCode,
          page: 1,
          per_page: 8,
          rates_count: 1,
        };

        const url = appendSearchParams(`${apiBaseUrl}/hotels/hotels`, searchParams);

        try {
          const response = await axios.get<Array<HotelSummary>>(url, { cancelToken });

          if (response.status === 200) {
            setNearbyHotels(response.data);
          }
        } catch (_) {}
      };

      initNearbyHotels(cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [nearestCity, apiBaseUrl, locale, currency, currentSite]);

  // Get recommended hotel filters
  useEffect(() => {
    if (!!hotelRecentSearch) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const initRecommendedHotels = async (cancelToken: CancelToken): Promise<void> => {
        const url = appendSearchParams(`${apiBaseUrl}/hotels/recommended/collections`, {
          app_type: 'MOBILE_WEB_APP',
          locale,
          city_code: hotelRecentSearch.destinationLocation!.cityCode,
        });

        try {
          const response = await axios.get<Array<RecommendedHotelFilter>>(url, { cancelToken });

          if (response.status === 200) {
            setRecommendedHotelFilters(response.data);
          }
        } catch (_) {}
      };

      initRecommendedHotels(cancelTokenSource.token);

      return () => {
        cancelTokenSource.cancel('Component unmount.');
      };
    }
  }, [hotelRecentSearch, apiBaseUrl, locale]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [popularDestinations, nearbyHotels, recommendedHotelFilters]);

  const handleOnPartnerPromoClick = (
    carouselData: PartnerPromoGenzoData,
    actionId: string,
  ): void => {
    genzoTrackCarouselClick({
      apiBaseUrl,
      clientId: clientId!,
      clientSessionId: clientSessionId!,
      userHash: user?.userHash,
      carouselData,
      id: actionId,
      pageViewId,
    });
  };

  return (
    <div className={clsx(styles.container)}>
      <div
        className={styles.flightsAndHotelsMiniLinkContainer}
        style={flightsAndHotelsMiniLinkContainerStyle}
      >
        <Link
          className={styles.link}
          to={{ pathname: flightsRoute(currentSite, params.locale), search: search }}
        >
          <div className={clsx(styles.flightsHotelsActivitiesImage, styles.flights)}></div>
          {translations.Flights}
        </Link>

        <div className={styles.verticalLine}></div>

        <Link
          className={styles.link}
          to={{ pathname: hotelsRoute(currentSite, params.locale), search: search }}
        >
          <div className={clsx(styles.flightsHotelsActivitiesImage, styles.hotels)}></div>
          {translations.Hotels}
        </Link>
      </div>
      {/* Section: Header */}
      <div className={styles.headerBar} style={headerBarStyle}>
        <MenuIcon
          className={styles.icon}
          style={headerBarIconStyle}
          onClick={() => setIsMenuOpen(true)}
        />
        <div className={styles.logo} style={headerBarLogoStyle}></div>
        {isUseAppGreen && (
          <UseAppButton
            homePageType={HomePageType.Root}
            isUseAppGreen={isUseAppGreen}
            locale={locale}
            translations={translations}
          />
        )}
      </div>
      {/* Section: Background Hero Image */}
      <HeroImage />

      {/* Section: Shopcash Wallet */}
      {isWegoShopcashEnabled(currentSite.countryCode) && !!shopCashUser ? (
        <div className={styles.shopcashWalletContainer}>
          <ShopcashWallet
            user={user}
            shopCashUser={shopCashUser}
            locale={locale}
            exchangeRate={exchangeRate}
            currency={currency}
            translations={translations}
          />
        </div>
      ) : null}
      {/* Section: Flights and Hotels Link */}
      <div className={styles.productLinksContainer}>
        <Link
          className={styles.link}
          to={{ pathname: flightsRoute(currentSite, params.locale), search: search }}
        >
          <Image
            className={styles.flightsIcon}
            imageUrl='https://zen.wego.com/cdn-cgi/image/width=144,height=144/web/icons/flights.png'
          />
          <span>{translations.Flights}</span>
        </Link>

        <Link
          className={styles.link}
          to={{ pathname: hotelsRoute(currentSite, params.locale), search: search }}
        >
          <Image
            className={styles.hotelsIcon}
            imageUrl='https://zen.wego.com/cdn-cgi/image/width=144,height=144/web/icons/hotels.png'
          />
          <span>{translations.Hotels}</span>
        </Link>
      </div>

      <NtoBannerAd countryCode={currentSite.countryCode} locale={locale} isUserLoggedIn={!!user} />

      <ServicesMenu clientId={clientId} clientSessionId={clientSessionId} pageViewId={pageViewId} />

      {/* Egpyt Central Bank Notice */}
      {currentSite.countryCode === 'EG' ? (
        <div className={styles.egpytCentralBankNotice}>{translations.eg_central_bank_fee_text}</div>
      ) : null}
      {/* Partner Promos */}
      {partnerPromos.length > 0 ? (
        <div className={styles.partnerPromos}>
          {partnerPromos.map((promo, index) => {
            const genzoActionId = cuid();
            const deeplinkUrl = promo.deeplinkUrl.startsWith('https')
              ? promo.deeplinkUrl
              : `https://${promo.deeplinkUrl}`;
            const href = new URL(deeplinkUrl);
            href.searchParams.set('wego_click_id', genzoActionId);

            const carouselData: PartnerPromoGenzoData = {
              id: promo.id,
              position: index + 1,
              agreement_term_id: promo.agreementTermId,
              url: href.toString(),
              site_code: currentSite.countryCode,
              locale: locale,
            };

            return (
              <a
                key={promo.imageUrl}
                className={styles.partnerPromo}
                href={href.toString()}
                onClick={() => handleOnPartnerPromoClick(carouselData, genzoActionId)}
                rel='noopener noreferrer nofollow'
                target='_blank'
              >
                <Image
                  className={styles.image}
                  isLazy
                  imageUrl={cloudflareImageTransformations(promo.imageUrl, {
                    format: 'auto',
                    width: partnerPromos.length > 1 ? 488 : (window.innerWidth - 24) * 2,
                    quality: 90,
                    ...(partnerPromos.length > 1 ? { height: 244 } : {}),
                  })}
                />
              </a>
            );
          })}
        </div>
      ) : null}
      {/* Section: Popular Destination */}
      {popularDestinations.length > 0 ? (
        <>
          <Link className={styles.sectionLink} to={`/`}>
            <div className={styles.heading}>{translations.popular_destinations}</div>
            <div className={styles.subHeading}>{translations.estimated_fares}</div>
          </Link>

          <div className={styles.popularDestinations}>
            {popularDestinations.map((popularDestination) => (
              <HomePopularDestination
                key={popularDestination.id}
                className={styles.popularDestination}
                currentSite={currentSite}
                locale={locale}
                translations={translations}
                currency={currency}
                exchangeRate={exchangeRate}
                city={popularDestination}
              />
            ))}
          </div>
        </>
      ) : null}
      {/* Section: App Install Banner */}
      <div className={clsx(styles.appInstallBanner, isAppInstallBannerCollapse && styles.collapse)}>
        <div className={styles.top}>
          <div className={clsx(styles.flightsHotelsActivitiesImage, styles.wegoImage)}></div>

          <div>
            <div>
              {isIOS ? translations.install_banner_ios : translations.install_banner_android}
            </div>
            <div className={styles.subHeading}>{translations.install_banner_desc}</div>
          </div>
        </div>
        <div className={styles.bottom}>
          <a href='https://wegotravel.onelink.me/pGV9/2885adde'>{translations.install_app}</a>

          <div onClick={() => setIsAppInstallBannerCollapse(true)}>{translations.not_now}</div>
        </div>
      </div>
      {/* Section: Nearby Hotels */}
      {nearbyHotels.length > 0 ? (
        <>
          <Link
            className={styles.sectionLink}
            to={{
              pathname: nearbyHotelsSearchUrl,
              search: `${!!search ? `${search}&guests=2` : '?guests=2'}`,
              state: { from: '/' },
            }}
          >
            <div className={styles.heading}>
              {translateText(translations.hotels_in, locale, nearestCity!.cityName)}
            </div>
          </Link>

          <div className={styles.nearbyHotels}>
            {nearbyHotels.map((nearbyHotel) => (
              <MiniHotelCard
                key={nearbyHotel.id}
                className={styles.nearbyHotel}
                hotel={convertHotelSummaryToMiniHotel(nearbyHotel)}
                to={{
                  pathname: `${hotelsRoute(
                    currentSite,
                    params.locale,
                  )}/searches/${nearestCity!.cityCode.toLowerCase()}/${nearbyHotelsCheckInDate}/${nearbyHotelsCheckOutDate}/${
                    nearbyHotel.id
                  }`,
                  search: `${!!search ? `${search}&guests=2` : '?guests=2'}`,
                  state: { from: '/' },
                }}
                priceOptions={{
                  showTotalPrice: false,
                  isIncludingTaxOrFee: false,
                }}
              />
            ))}
          </div>
        </>
      ) : null}
      {/* Section: Recommended Hotel filters */}
      {recommendedHotelFilters.length > 0 ? (
        <>
          <Link
            className={styles.sectionLink}
            to={{
              pathname: `${hotelsRoute(
                currentSite,
                params.locale,
              )}/searches/${hotelRecentSearch!.destinationLocation!.cityCode?.toLowerCase()}/${dateApiFormat(
                new Date(hotelRecentSearch!.checkInDateMilliseconds!),
              )}/${dateApiFormat(new Date(hotelRecentSearch!.checkOutDateMilliseconds!))}`,
              search: `${
                !!search
                  ? `${search}&guests=${convertGuestRoomsToSearchParam(
                      hotelRecentSearch!.guestRooms,
                    )}`
                  : `?guests=${convertGuestRoomsToSearchParam(hotelRecentSearch!.guestRooms)}`
              }`,
              state: { from: '/' },
            }}
          >
            <div className={styles.heading}>
              {translateText(
                translations.hotel_prop_types,
                locale,
                hotelRecentSearch!.destinationLocation!.cityName ?? '',
              )}
            </div>
            <div className={styles.subHeading}>{`${dateDisplayFormat(
              new Date(hotelRecentSearch!.checkInDateMilliseconds!),
            )} - ${dateDisplayFormat(
              new Date(hotelRecentSearch!.checkOutDateMilliseconds!),
            )} (${translateText(
              translations.night_count,
              locale,
              numberOfNights(
                hotelRecentSearch!.checkInDateMilliseconds!,
                hotelRecentSearch!.checkOutDateMilliseconds!,
              ),
            )})`}</div>
          </Link>

          <div className={styles.recommendedHotelFilters}>
            {recommendedHotelFilters.map((recommendedHotelFilter, index) => (
              <RecommendedHotelFilter
                key={index}
                className={styles.recommendedHotelFilter}
                currentSite={currentSite}
                locale={locale}
                translations={translations}
                hotelRecentSearch={hotelRecentSearch!}
                recommendedHotelFilter={recommendedHotelFilter}
              />
            ))}
          </div>
        </>
      ) : null}

      {/* Section: Stories */}
      <MiniBlog />

      {/* Section: Propositions */}
      <Propositions isBowOnly={isBowOnly} translations={translations} />
      {/* Section: SEO Links */}
      {!!homepage &&
      (homepage.flights.cities.length > 0 || homepage.flights.domesticCities.length > 0) ? (
        <FoldableSection className={styles.seoLinks} title={translations.top_cities}>
          {/* Section: Flight to top cities */}
          {[
            ...homepage.flights.cities.filter(notUndefinedAndNull).slice(0, 10),
            ...homepage.flights.domesticCities.filter(notUndefinedAndNull).slice(0, 10),
          ].map((city: HomepageCity) => (
            <a
              key={city.id}
              className={styles.seoLink}
              href={`${flightsToRoute(
                currentSite,
                params.locale,
              )}/${city.cityCode.toLowerCase()}/${cheapFlightsToRouteTranslation(
                params.locale ?? currentSite.defaultLocale,
                city.cityPermalink,
              )}${search}`}
              target='_blank'
              rel='noreferrer'
            >
              {translateText(translations.flights_to, locale, city.name)}
            </a>
          ))}
        </FoldableSection>
      ) : null}
      {!!homepage && homepage.flights.countries.length > 0 ? (
        <FoldableSection className={styles.seoLinks} title={translations.top_countries}>
          {/* Section: Flight to top countries */}
          {homepage.flights.countries
            .filter(notUndefinedAndNull)
            .map((country: HomepageCountry) => (
              <a
                key={country.id}
                className={styles.seoLink}
                href={`${flightsToRoute(
                  currentSite,
                  params.locale,
                )}/${country.code.toLowerCase()}/${cheapFlightsToRouteTranslation(
                  params.locale ?? currentSite.defaultLocale,
                  country.countryPermalink,
                )}${search}`}
                target='_blank'
                rel='noreferrer'
              >
                {translateText(translations.flights_to, locale, country.name)}
              </a>
            ))}
        </FoldableSection>
      ) : null}
      {!!homepage && homepage.flights.popularAirlines.length > 0 ? (
        <FoldableSection className={styles.seoLinks} title={translations.seo_top_airlines}>
          {/* Section: Top airlines */}
          {homepage.flights.popularAirlines.filter(notUndefinedAndNull).map((popularAirline) => (
            <a
              key={popularAirline.code}
              className={styles.seoLink}
              href={`${airlinesRoute(currentSite, params.locale)}/${
                popularAirline.permalink
              }-${popularAirline.code.toLowerCase()}${search}`}
              target='_blank'
              rel='noreferrer'
            >{`${popularAirline.name} (${popularAirline.code})`}</a>
          ))}
        </FoldableSection>
      ) : null}
      {!!homepage &&
      (homepage.hotels.cities.length > 0 || homepage.hotels.domesticCities.length > 0) ? (
        <FoldableSection className={styles.seoLinks} title={translations.hotels_top_cities}>
          {/* Section: Hotels in top cities */}
          {uniqBy(
            [
              ...homepage.hotels.cities.filter(notUndefinedAndNull).slice(0, 10),
              ...homepage.hotels.domesticCities.filter(notUndefinedAndNull).slice(0, 10),
            ],
            'id',
          ).map((city: HomepageCity) => (
            <a
              key={city.id}
              className={styles.seoLink}
              href={`${hotelsRoute(currentSite, params.locale)}/${city.countryPermalink}/${
                city.permalink
              }${search}`}
              target='_blank'
              rel='noreferrer'
            >
              {translateText(translations.hotels_in, locale, city.name)}
            </a>
          ))}
        </FoldableSection>
      ) : null}
      {!!homepage && homepage.hotels.countries.length > 0 ? (
        <FoldableSection className={styles.seoLinks} title={translations.hotels_top_countries}>
          {/* Section: Hotels in top countries */}
          {homepage.hotels.countries.filter(notUndefinedAndNull).map((country: HomepageCountry) => (
            <a
              key={country.id}
              className={styles.seoLink}
              href={`${hotelsRoute(currentSite, params.locale)}/${
                country.countryPermalink
              }${search}`}
              target='_blank'
              rel='noreferrer'
            >
              {translateText(translations.hotels_in, locale, country.name)}
            </a>
          ))}
        </FoldableSection>
      ) : null}
      {/* Footer */}
      <Footer
        className={styles.footer}
        currentSite={currentSite}
        locale={locale}
        translations={translations}
        apiBaseUrl={apiBaseUrl}
        clientId={clientId}
        clientSessionId={clientSessionId}
        pageViewId={pageViewId}
      />
      <Drawer
        anchor={isRtl ? 'right' : 'left'}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        <Menu
          currentSite={currentSite}
          locale={locale}
          translations={translations}
          currency={currency}
          domainBasedApiBaseUrl={domainBasedApiBaseUrl}
          user={user}
          shopCashUser={shopCashUser}
          exchangeRate={exchangeRate}
          isOpen={isMenuOpen}
          source='home'
          apiBaseUrl={apiBaseUrl}
          clientId={clientId}
          clientSessionId={clientSessionId}
          pageViewId={pageViewId}
        />
      </Drawer>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: 'Wego',
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.7',
            bestRating: '5',
            ratingCount: '327060',
          },
        })}
      </script>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'WebSite',
          name: 'Wego',
          alternateName: 'Wego',
          url: `${window.location.origin}/${!!params.locale ? params.locale : ''}`,
        })}
      </script>
    </div>
  );
};

export default Home;
