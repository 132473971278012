import { CoreConfigActionTypes } from '../actions/types';

const initialState: Array<CoreConfig> = [];

export default function (
  state: Array<CoreConfig> = initialState,
  action: { type: CoreConfigActionTypes; payload: Array<CoreConfig> },
): Array<CoreConfig> {
  switch (action.type) {
    case CoreConfigActionTypes.ChangeCoreConfig:
      return action.payload;
    default:
      return state;
  }
}
