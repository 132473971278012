import { isDevelopmentEnvironment } from '@alexis/helpers/environment';
import { getSiteConfigByHostname } from '@alexis/helpers/site';

const { origin, hostname } = window.location;

let domainBasedApiBaseUrl = `${origin}/kong`;

if (isDevelopmentEnvironment(hostname)) {
  const currentSite: CurrentSite = getSiteConfigByHostname(hostname);

  domainBasedApiBaseUrl = `https://${currentSite.countryCode.toLowerCase()}-beta.wegostaging.com/kong`;
}

const initialState: string = domainBasedApiBaseUrl;

export default function (state: string = initialState): string {
  return state;
}
