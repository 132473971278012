import { appendSearchParams } from '@alexis/helpers/searchParams';
import axios from 'axios';
import type { CancelToken } from 'axios';

import { PartnerPromoResponse } from '@wegoTypes/PartnerPromo/partnerPromo';

export const getPartnerPromos = async (
  apiBaseUrl: string,
  locale: string,
  siteCode: string,
  deviceType: string,
  appType: string,
  cancelToken: CancelToken,
): Promise<PartnerPromoResponse> => {
  const url = appendSearchParams(
    `${apiBaseUrl}/server-driven-configs/v1/marketing/carousels/search`,
    { locale, siteCode, deviceType, appType },
  );

  const response = await axios.get<PartnerPromoResponse>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};
