import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';

const hostname = window.location.hostname;
const initialState: string =
  isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)
    ? 'https://srv.wegostaging.com'
    : 'https://srv.wego.com';

export default function (state: string = initialState): string {
  return state;
}
