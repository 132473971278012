import clsx from '@alexis/helpers/clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import Image from '@components/Image';

import { getTranslationsState } from '@redux/selectors';

import styles from './MiniBlogCard.module.scss';

interface MiniBlogCardProps {
  className?: string;
  story: Story;
}

const MiniBlogCard: React.FC<MiniBlogCardProps> = ({ className, story }): JSX.Element => {
  const translations = useSelector(getTranslationsState);

  return (
    <a
      href={story.url}
      className={clsx(styles.container, className)}
      target='_blank'
      rel='noreferrer'
    >
      {/* Section: Image */}
      <Image
        className={styles.image}
        isLazy
        imageUrl={!!story.featured_media_url ? story.featured_media_url : ''}
        noImageUrl='https://assets.wego.com/image/upload/w_540,h_360,c_fill,f_auto,fl_lossy,q_auto:low/v21012019/NTO/top10hotels_placeholder_image.jpg'
      />

      {/* Section: Title */}
      <div className={styles.title}>{story.title}</div>

      {/* Section: Footer */}
      <div className={styles.footer}>
        <div className={styles.wegoPlaneLogo} />
        <div className={styles.wegoTravelBlog}>{translations.wego_travel_blog}</div>
      </div>
    </a>
  );
};

export default MiniBlogCard;
