import { WebEngageActionTypes } from '../actions/types';

export interface WebEngageStore {
  webEngageAnonymousId: string;
}

const initialState: WebEngageStore = {
  webEngageAnonymousId: '',
};

export default function (
  state: WebEngageStore = initialState,
  action: { type: WebEngageActionTypes; payload: string },
): WebEngageStore {
  switch (action.type) {
    case WebEngageActionTypes.ChangeAnonymousId:
      return { ...state, webEngageAnonymousId: action.payload };
    default:
      return state;
  }
}
