import clsx from '@alexis/helpers/clsx';
import { DropdownProps } from 'props/dropdown';
import React from 'react';

import styles from '../../styles/components/form/Dropdown.module.scss';
import { DropdownDirection } from '../../types/dropdownDirection';

const Dropdown: React.FC<DropdownProps> = ({
  className,
  isOpen,
  direction,
  style,
  children,
  dataPw,
}): JSX.Element => {
  return (
    <div
      data-pw={dataPw}
      className={clsx(
        styles.container,
        isOpen && styles.open,
        direction === DropdownDirection.Top ? styles.top : styles.bottom,
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export default Dropdown;
