import clsx from '@alexis/helpers/clsx';
import React, { useState } from 'react';

import styles from '../styles/components/FoldableSection.module.scss';

const FoldableSection: React.FC<FoldableSectionProps> = ({
  className,
  style,
  title,
  children,
  defaultIsExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(!!defaultIsExpanded);

  return (
    <div
      className={clsx(styles.container, !!isExpanded && styles.expanded, className)}
      style={style}
    >
      <div className={styles.title} onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        <div className={clsx(styles.chevron, styles.bottom)}></div>
      </div>
      <nav className={styles.childrenContainer}>{children}</nav>
    </div>
  );
};

export default FoldableSection;
