import type { ShopCashUserProps } from 'props/shopCashUserProps';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getWegoIdToken } from '../apis/authentication';
import { getShopCashProfile, shopCashSignIn } from '../apis/shopCash';
import { isWegoShopcashEnabled } from '../helpers/shopCash';
import { changeShopCashUser, resetShopCashUser } from '../redux/actions/shopCashActions';

const ShopCashUser: React.FC<ShopCashUserProps> = ({
  currentSite,
  domainBasedApiBaseUrl,
  locale,
  user,
}): null => {
  const dispatch = useDispatch();

  const getShopCashUser = async () => {
    try {
      const idToken = await getWegoIdToken(domainBasedApiBaseUrl);
      const shopCashTokens = await shopCashSignIn(idToken, currentSite.countryCode, locale);
      const shopCashProfile = await getShopCashProfile(shopCashTokens.data.accessToken);
      const hasLedgerBalances =
        shopCashProfile?.data.ledger.availableBalance ||
        shopCashProfile?.data.ledger.pendingBalance;

      if (hasLedgerBalances) {
        dispatch(
          changeShopCashUser({
            accessToken: shopCashTokens.data.accessToken,
            ledger: shopCashProfile.data.ledger,
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isWegoShopcashEnabled(currentSite.countryCode)) {
      if (user) {
        getShopCashUser();
      } else {
        dispatch(resetShopCashUser());
      }
    }
  }, [user, currentSite]);

  return null;
};

export default ShopCashUser;
