import { getInitialLocale, isRtl } from '@helpers/locale';

import { IsRtlActionTypes } from '../actions/types';

const initialState: boolean = isRtl(getInitialLocale());

export default function (
  state: boolean = initialState,
  action: { type: IsRtlActionTypes; payload: boolean },
): boolean {
  switch (action.type) {
    case IsRtlActionTypes.ChangeIsRtl:
      return action.payload;
    default:
      return state;
  }
}
