export const FACEBOOK_APP_ID = '1486888941534126';

export const GOOGLE_CLIENT_ID =
  '551606652723-41jq78qqpst3dgr4jcekpsdee1n4intu.apps.googleusercontent.com';

export const WEGO_CLIENT_ID = 'd03c13d00944731e07d1d06cac015242eb3cc52616d77b466fa9de96c19ebc1d';

export const WEGO_QUICKET_API_URL = 'https://api.quicket.io/api/v1';
export const WEGO_QUICKET_API_APP_ID = 'f6498934ee6b7d8e6ac3f66f2f3-wego-com';
export const WEGO_QUICKET_API_KEY = '6ec7bc44566b57a403280175b4b-wego-com';
