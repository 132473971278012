import { appendSearchParams } from '@alexis/helpers/searchParams';
import axios from 'axios';
import type { CancelToken } from 'axios';

import { getGenzoIdentifierId } from '@helpers/genzoIdentifierId';

export const getTripDetails = async (
  apiBaseUrl: string,
  tripId: string,
  locale: string,
  currencyCode: string,
  clientId: string | undefined,
  paymentMethodIds: Array<number>,
  cancelToken: CancelToken,
): Promise<FlightFareResult> => {
  const url = appendSearchParams(`${apiBaseUrl}/v3/metasearch/flights/trips/${tripId}`, {
    locale,
    currencyCode,
    clientId,
    paymentMethodIds,
  });

  const response = await axios.get<FlightFareResult>(url, {
    cancelToken,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      ...(!!getGenzoIdentifierId() && {
        'X-Wego-Genzo-Identifier-Id': getGenzoIdentifierId()!,
      }),
    },
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getFlightAmenities = async (
  apiBaseUrl: string,
  tripId: string,
  locale: string,
  cancelToken: CancelToken,
): Promise<FlightAmenities> => {
  const url = appendSearchParams(`${apiBaseUrl}/v3/metasearch/flights/amenities/${tripId}`, {
    locale,
  });

  const response = await axios.get<FlightAmenities>(url, {
    ...(!!getGenzoIdentifierId() && {
      headers: { 'X-Wego-Genzo-Identifier-Id': getGenzoIdentifierId() },
    }),
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getFlightSearch = async (
  apiBaseUrl: string,
  searchId: string,
  locale: string,
  cancelToken: CancelToken,
): Promise<CompactFlightSearch> => {
  const url = appendSearchParams(`${apiBaseUrl}/v3/metasearch/flights/searches/${searchId}`, {
    locale,
  });

  const response = await axios.get<any>(url, {
    ...(!!getGenzoIdentifierId() && {
      headers: { 'X-Wego-Genzo-Identifier-Id': getGenzoIdentifierId() },
    }),
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getFlightFlexSearchCities = async (
  apiBaseUrl: string,
  language: string,
  departureCityCode: string,
  currencyCode: string,
  tripType: string,
  perPage: number,
  cancelToken: CancelToken,
): Promise<FlexSearchCities> => {
  const url = appendSearchParams(`${apiBaseUrl}/flexsearch/flights/cities`, {
    language,
    departure_city_code: departureCityCode,
    currency_code: currencyCode,
    trip_type: tripType,
    per_page: perPage,
  });

  const response = await axios.get<FlexSearchCities>(url, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getSupportedPaymentTypes = async (
  apiBaseUrl: string,
  locale: string,
  siteCode: string,
  cancelToken: CancelToken,
): Promise<Array<PaymentMethod>> => {
  const url = appendSearchParams(`${apiBaseUrl}/flights/payment_methods`, {
    locale,
    site_code: siteCode,
  });

  const response = await axios.get<Array<PaymentMethod>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};
