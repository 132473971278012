export const enableBodyScroll = (): void => {
  document.body.style.overflow = '';
  // document.documentElement.style.overflow = '';
};

export const disableBodyScroll = (): void => {
  document.body.style.overflow = 'hidden';
  // document.documentElement.style.overflow = 'hidden';
};

// based on http://en.wikipedia.org/wiki/Smoothstep
const smoothStep = (start: number, end: number, point: number): number => {
  if (point <= start) {
    return 0;
  }

  if (point >= end) {
    return 1;
  }

  const x = (point - start) / (end - start); // interpolation

  return x * x * (3 - 2 * x);
};

export const smoothScrollHorizontal = (
  element: HTMLElement,
  target: number,
  duration: number,
): Promise<void> => {
  return new Promise(function (resolve, reject) {
    const roundedTarget = Math.round(target);
    const roundedDuration = Math.round(duration);

    if (roundedDuration < 0) {
      reject('bad duration');
    }

    if (roundedDuration === 0) {
      element.scrollLeft = roundedTarget;
      resolve();
    }

    const startTime = Date.now();
    const endTime = startTime + roundedDuration;

    const startLeft = element.scrollLeft;
    const distance = roundedTarget - startLeft;

    // This is to keep track of where the element's scrollLeft is
    // supposed to be, based on what we're doing
    let previousLeft = element.scrollLeft;

    // This is like a think function from a game loop
    const scrollFrame = () => {
      try {
        window.requestAnimationFrame(() => {});
      } catch (e) {}

      if (element.scrollLeft != previousLeft) {
        // reject("interrupted");
        return;
      }

      // set the scrollLeft for this frame
      const now = Date.now();
      const point = smoothStep(startTime, endTime, now);
      const frameLeft = Math.round(startLeft + distance * point);
      element.scrollLeft = frameLeft;

      // check if we're done!
      if (now >= endTime) {
        resolve();
      }

      // If we were supposed to scroll but didn't, then we
      // probably hit the limit, so consider it done; not
      // interrupted.
      if (element.scrollLeft === previousLeft && element.scrollLeft !== frameLeft) {
        resolve();
      }
      previousLeft = element.scrollLeft;

      // schedule next frame for execution
      setTimeout(scrollFrame, 0);
    };

    // boostrap the animation process
    setTimeout(scrollFrame, 0);
  });
};
