export enum HotelSortType {
  AllReviewScore = 'ALL_REVIEW_SCORE',
  Discount = 'DISCOUNT',
  Landmark = 'LANDMARK',
  NearestToAirport = 'NEAREST_TO_AIRPORT',
  NearestToCityCentre = 'NEAREST_TO_CITY_CENTRE',
  NearestToYou = 'NEAREST_TO_YOU',
  NearestToTargetLocation = 'NEAREST_TO_TARGET_LOCATION',
  Price = 'PRICE',
  Recommended = 'RECOMMENDED',
  Saving = 'SAVING',
  Star = 'STAR',
}
