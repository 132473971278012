import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { isSpringSeasonCampaignActive, isRamadanCampaignActive } from '@helpers/campaign';
import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import { getCurrentSiteState } from '@redux/selectors';

import styles from './HeroImage.module.scss';

const HERO_IMAGE_CAMPAIGN = {
  DEFAULT: {
    url: 'https://assets.wego.com/image/upload/v1583134909/web/mobile_hero_images/home_',
    count: 5,
  },
  RAMADHAN: {
    url: 'https://assets.wego.com/image/upload/v1740652824/web/campaigns/ramadan/hero-image_mobile_',
    count: 2,
  },
  SPRING: {
    url: 'https://assets.wego.com/image/upload/v1740659145/web/campaigns/spring-season/hero-image_mobile_',
    count: 3,
  },
};

const HeroImage = () => {
  const [homeImageUrl, setHomeImageUrl] = useState<string>('');
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);

  useEffect(() => {
    let heroImageUrl = HERO_IMAGE_CAMPAIGN.DEFAULT.url;
    let heroImageCount = HERO_IMAGE_CAMPAIGN.DEFAULT.count;

    if (isRamadanCampaignActive(currentSite.countryCode)) {
      heroImageUrl = HERO_IMAGE_CAMPAIGN.RAMADHAN.url;
      heroImageCount = HERO_IMAGE_CAMPAIGN.RAMADHAN.count;
    } else if (isSpringSeasonCampaignActive(currentSite.countryCode)) {
      heroImageUrl = HERO_IMAGE_CAMPAIGN.SPRING.url;
      heroImageCount = HERO_IMAGE_CAMPAIGN.SPRING.count;
    }

    const homeImageBaseUrl: string = cloudinaryImageTransformations(
      heroImageUrl,
      'c_fill',
      'fl_lossy',
      'q_auto:low',
      'f_auto',
      'w_768',
    );
    const homeImageIdKey: string = 'homeImageId';
    const homeImageId: number = localStorageGet<number>(homeImageIdKey) ?? 0;

    setHomeImageUrl(`${homeImageBaseUrl}${homeImageId}.jpg`);

    const updateHomeImageForNextLoad = (homeImageId: number, heroImageCount: number): void => {
      const incrementHomeImageId: number = homeImageId + 1;

      localStorageSave(
        homeImageIdKey,
        incrementHomeImageId < heroImageCount ? incrementHomeImageId : 0,
      );
    };

    updateHomeImageForNextLoad(homeImageId, heroImageCount);
  }, [currentSite.countryCode]);

  return (
    <div className={styles.container}>
      <img className={styles.heroImage} loading='eager' src={homeImageUrl} alt='Wego hero image' />

      <img
        className={styles.logo}
        loading='eager'
        src='https://assets.wego.com/image/upload/q_auto:low,f_auto,w_256,h_116/v1472795189/wego_logos/logo-wego-white.png'
        alt='Wego logo'
      />
    </div>
  );
};

export default HeroImage;
