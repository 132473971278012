import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import axios from 'axios';

const getShopCashBaseUrl = () => {
  const hostname = window.location.hostname;
  return isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)
    ? 'https://api.shopcashstaging.com/sc/shopcash'
    : 'https://api.shopcash.com/sc/shopcash';
};

export const shopCashSignIn = async (
  idToken: string,
  siteCode: string,
  locale: string,
): Promise<ShopCashWegoAuthResponse> => {
  const shopCashBaseUrl = getShopCashBaseUrl();
  const url = `${shopCashBaseUrl}/v1/auth/wego`;

  const response = await axios.post<ShopCashWegoAuthResponse>(
    url,
    {
      data: {
        siteCode: siteCode,
        language: locale,
        appType: 'MOBILE_WEB_APP',
      },
    },
    { headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + idToken } },
  );

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
};

export const getShopCashProfile = async (accessToken: string): Promise<ShopCashAccountResponse> => {
  const shopCashBaseUrl = getShopCashBaseUrl();
  const url = `${shopCashBaseUrl}/v1/account`;

  const response = await axios.get<ShopCashAccountResponse>(url, {
    headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + accessToken },
  });

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
};

export const getPayouts = async (
  accessToken: string,
  locale = 'en',
  page = 1,
  perPage = 30,
): Promise<ShopcashPayoutsResponse> => {
  const shopcashBaseUrl = getShopCashBaseUrl();
  const url = `${shopcashBaseUrl}/v1/account/payouts?language=${locale}&page=${page}&perPage=${perPage}`;

  const response = await axios.get<ShopcashPayoutsResponse>(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });

  if (response.status === 200) {
    return response.data;
  }

  return { data: [], meta: {} };
};
