import clsx from '@alexis/helpers/clsx';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { isSiteCodePartOfMENA } from '@alexis/helpers/site';
import { translateText } from '@alexis/helpers/translation';
import React, { useState, useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { walletOverviewPaths, walletWithdrawPaths } from '@helpers/path';
import {
  airportsRouteTranslation,
  airlinesRouteTranslation,
  schedulesRouteTranslation,
  hotelsRouteTranslation,
} from '@helpers/routeTranslation';

import styles from '@styles/components/home/Footer.module.scss';

import FoldableSection from '../FoldableSection';

interface FooterProps {
  apiBaseUrl: string;
  clientId: string | undefined;
  clientSessionId: string | undefined;
  pageViewId: string | undefined;
  currentSite: CurrentSite;
  locale: string;
  translations: any;
  className?: string;
}

const Footer: React.FC<FooterProps> = ({
  className,
  currentSite,
  locale,
  translations,
  apiBaseUrl,
  clientId,
  clientSessionId,
  pageViewId,
}): JSX.Element => {
  const { pathname } = useLocation();
  const [toBeAppendedLocale, setToBeAppendedLocale] = useState<string>('');
  const [playStoreUrl, setPlayStoreUrl] = useState<string>(
    'https://wegotravel.onelink.me/pGV9/6e3f482e',
  );
  const [toBeAppendedHoteliersLocale, setToBeAppendedHoteliersLocale] = useState<string>('');
  const [facebookId, setFacebookId] = useState<string>('wego');
  const [twitterId, setTwitterId] = useState<string>('wego');
  const [instagramId, setInstagramId] = useState<string>('wego_travel');

  useEffect(() => {
    if (locale === 'ar') {
      setToBeAppendedLocale('/ar');
    }

    if (['zh-cn', 'zh-tw', 'id'].includes(locale)) {
      setToBeAppendedHoteliersLocale(`-${locale}`);
    }
  }, [locale, currentSite]);

  useEffect(() => {
    if (/^MY|ID$/.test(currentSite.countryCode)) {
      setPlayStoreUrl('http://app.mi.com/detail/82456');
    }

    if (currentSite.countryCode === 'CN') {
      setPlayStoreUrl('https://id.9apps.com/android-apps/Wego-Flights-and-Hotels');
    }

    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      setFacebookId('Wego.Arabia');
      setTwitterId('WegoArabia');
      setInstagramId('wegoarabia');
    }

    if (currentSite.countryCode === 'IN') {
      setFacebookId('wego.india');
      setTwitterId('wegoindia');
      setInstagramId('wegoindia');
    }

    if (currentSite.countryCode === 'ID') {
      setFacebookId('wego.indonesia');
      setTwitterId('WegoID');
      setInstagramId('wegoindonesia');
    }

    if (currentSite.countryCode === 'MY') {
      setFacebookId('Wego.comMalaysia');
      setInstagramId('wegomalaysia');
    }

    if (currentSite.countryCode === 'TW') {
      setFacebookId('wego.chinese');
      setInstagramId('wegotaiwan');
    }
  }, [currentSite]);

  const handleFooterLinksClicked = (object: string) => {
    if (!!clientId && !!clientSessionId) {
      let pageName = 'home';

      if (!!matchPath(pathname, walletWithdrawPaths)) {
        pageName = 'wallet-withdraw';
      } else if (!!matchPath(pathname, walletOverviewPaths)) {
        pageName = 'wallet-overview';
      }

      const eventData = {
        id: pageViewId,
        page: pageName,
        category: 'Footer',
        object,
        action: 'clicked',
        value: '',
      };
      genzoTrackActionEvent(apiBaseUrl, clientId, clientSessionId, undefined, eventData);
    }
  };

  const handleOnWegoProClick = () => {
    if (!!clientId && !!clientSessionId) {
      const eventData = {
        id: pageViewId,
        category: 'footer',
        object: 'wegopro_businesstravel',
        action: 'clicked',
        value: '',
      };

      genzoTrackActionEvent(apiBaseUrl, clientId, clientSessionId, undefined, eventData);
    }
  };

  return (
    <footer className={clsx(styles.container, className)}>
      {/* Section: Company */}
      <FoldableSection
        className={styles.seoLinks}
        title={translations.company}
        defaultIsExpanded={true}
      >
        <a
          className={styles.seoLink}
          href={`https://company.wego.com${toBeAppendedLocale}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('about_wego')}
        >
          {translations.about}
        </a>
        <a
          className={styles.seoLink}
          href='https://company.wego.com/newsroom'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('press')}
        >
          {translations.press}
        </a>
        <a
          className={styles.seoLink}
          href={`https://company.wego.com/careers${toBeAppendedLocale}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('careers')}
        >
          {translations.careers}
        </a>
        <a
          className={styles.seoLink}
          href={`https://company.wego.com/support${
            currentSite.countryCode === 'ID' ? '-id' : toBeAppendedLocale
          }`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('contact_us')}
        >
          {translations.contact_us}
        </a>
      </FoldableSection>

      {/* Section: Learn More */}
      <FoldableSection
        className={styles.seoLinks}
        title={translations.learn_more}
        defaultIsExpanded={true}
      >
        <a
          className={clsx(styles.seoLink, styles.wegoPro)}
          href='https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=bottom-nav-bar'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleOnWegoProClick()}
        >
          <strong>WegoPro</strong>
          {translations.business_travel}
          <div className={styles.newLabel}>{locale === 'ar' ? translations.menue_new : 'New'}</div>
        </a>
        <a
          className={styles.seoLink}
          href='https://wegotravel.onelink.me/pGV9/6e3f482e'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('wego_appios')}
        >
          {translations.wego_app_ios}
        </a>
        <a
          className={styles.seoLink}
          href={playStoreUrl}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('wego_appandroid')}
        >
          {translations.wego_app_android}
        </a>
        <a
          className={styles.seoLink}
          href='https://company.wego.com/book-on-wego'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('book_on_wego')}
        >
          {translations.book_on_wego}
        </a>
        <a
          className={styles.seoLink}
          href='https://company.wego.com/affiliate-program'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('affiliates')}
        >
          {translations.affiliates}
        </a>
        <a
          className={styles.seoLink}
          href='https://www.wego.com/advertise'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('advertise')}
        >
          {translations.advertise}
        </a>
        <a
          className={styles.seoLink}
          href={`https://company.wego.com/hoteliers${toBeAppendedHoteliersLocale}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('hoteliers')}
        >
          {translations.hoteliers}
        </a>
        <a
          className={styles.seoLink}
          href='https://company.wego.com/data-privacy-policy/'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('data_privacy_policy')}
        >
          {translations.lbl_data_privacy_policy}
        </a>
        <a
          className={styles.seoLink}
          href='https://www.wego.com/terms'
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('terms')}
        >
          {translations.terms}
        </a>
        <div id='cookie-script-footer-link'></div>
      </FoldableSection>

      {/* Section: Explore */}
      <FoldableSection
        className={styles.seoLinks}
        title={translations.explore}
        defaultIsExpanded={true}
      >
        {/* TODO [>x] add localePath */}
        <a
          className={styles.seoLink}
          href={`/${airportsRouteTranslation(locale)}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('airport_directory')}
        >
          {translations.airport_directory}
        </a>
        <a
          className={styles.seoLink}
          href={`/${airlinesRouteTranslation(locale)}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('airlines_directory')}
        >
          {translations.airlines_directory}
        </a>
        <a
          className={styles.seoLink}
          href={`/${schedulesRouteTranslation(locale)}`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('flight_schedules')}
        >
          {translations.flight_schedules}
        </a>
        <a
          className={styles.seoLink}
          href={`/${hotelsRouteTranslation(locale)}/chains`}
          target='_blank'
          rel='noreferrer'
          onClick={() => handleFooterLinksClicked('hotel_chains')}
        >
          {translations.hotel_chains}
        </a>
      </FoldableSection>

      <div className={styles.paragraph}>
        <span>
          Copyright{' '}
          {translateText(translations.all_rights_footer, locale, new Date().getFullYear())} <br />
          Site Operator: Wego Pte. Ltd. Travel License No. 03513
        </span>
      </div>

      <div className={styles.socialMediaContainer}>
        <a
          className={clsx(styles.socialMedia, styles.facebook)}
          href={`https://www.facebook.com/${facebookId}`}
          target='_blank'
          rel='noreferrer'
        ></a>
        <a
          className={clsx(styles.socialMedia, styles.twitter)}
          href={`https://twitter.com/${twitterId}`}
          target='_blank'
          rel='noreferrer'
        ></a>
        <a
          className={clsx(styles.socialMedia, styles.instagram)}
          href={`https://www.instagram.com/${instagramId}`}
          target='_blank'
          rel='noreferrer'
        ></a>
        <a
          className={clsx(styles.socialMedia, styles.linkedin)}
          href={`https://www.linkedin.com/company/wego-com`}
          target='_blank'
          rel='noreferrer'
        ></a>
        {isSiteCodePartOfMENA(currentSite.countryCode) ? (
          <a
            className={clsx(styles.socialMedia, styles.youtube)}
            href={`https://www.youtube.com/user/WegoArabia`}
            target='_blank'
            rel='noreferrer'
          ></a>
        ) : null}
        {isSiteCodePartOfMENA(currentSite.countryCode) ? (
          <a
            className={clsx(styles.socialMedia, styles.snapchat)}
            href={`https://www.snapchat.com/add/wegoarabia`}
            target='_blank'
            rel='noreferrer'
          ></a>
        ) : null}
      </div>
    </footer>
  );
};

export default Footer;
