import { getCookie } from '@alexis/helpers/cookie';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import { isStagingEnvironment } from '@alexis/helpers/environment';
import { translateText } from '@alexis/helpers/translation';
import type { User } from '@alexis/types/user';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import ExchangeRate from '@components/ExchangeRate';
import IsRtl from '@components/IsRtl';
import ShopCashUser from '@components/ShopCashUser';
import Translations from '@components/Translations';

import { getHrefLangs } from '@helpers/seo';

import useRtl from '@hooks/useRtl';

import {
  bookingRoutesPaths,
  flightRoutesPaths,
  homeRoutesPaths,
  hotelRoutesPaths,
  preferencesRoutesPaths,
} from '../helpers/path';
import { injectSiftSnippet } from '../helpers/siftFraudDetection';
import domainBasedApiBaseUrlReducer from '../redux/reducers/domainBasedApiBaseUrl';
import shopCashUserReducer from '../redux/reducers/shopCashUser';
import userReducer from '../redux/reducers/user';
import {
  getApiBaseUrlState,
  getCurrencyState,
  getCurrentSiteState,
  getDomainBasedApiBaseUrlState,
  getExchangeRateState,
  getIsRtlState,
  getLocaleState,
  getShopCashUserState,
  getTranslationsState,
  getUserState,
} from '../redux/selectors';
import LocaleRoutes from './LocaleRoutes';

const Routes: React.FC<RoutesProps> = ({ onInjectReducers }): JSX.Element => {
  onInjectReducers({
    domainBasedApiBaseUrl: domainBasedApiBaseUrlReducer,
    user: userReducer,
    shopCashUser: shopCashUserReducer,
  });

  const apiBaseUrl: string = useSelector(getApiBaseUrlState);
  const currency: Currency = useSelector(getCurrencyState);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const domainBasedApiBaseUrl: string = useSelector(getDomainBasedApiBaseUrlState);
  const exchangeRate: ExchangeRate = useSelector(getExchangeRateState);
  const isRtl: boolean = useSelector(getIsRtlState);
  const locale: string = useSelector(getLocaleState);
  const translations: { [key: string]: string } = useSelector(getTranslationsState);
  const user: User | null = useSelector(getUserState);
  const shopCashUser: ShopCashUser | null = useSelector(getShopCashUserState);

  const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');

  const paths: Array<string> = [
    ...homeRoutesPaths,
    ...flightRoutesPaths,
    ...hotelRoutesPaths,
    ...bookingRoutesPaths,
    ...preferencesRoutesPaths,
  ];

  useRtl(locale, isRtl);

  // inject sift fraud detection script
  useEffect(() => {
    if (!!wegoAnalyticsClientSessionIdCookie) {
      const userHash = !!user ? user.userHash : '';
      injectSiftSnippet(userHash, wegoAnalyticsClientSessionIdCookie);
    }
  }, [user]);

  const posName = (hostname: string): string => {
    const hostNameWithoutWWW = hostname.replace('www.', '');

    return hostNameWithoutWWW.replace(/^./, hostNameWithoutWWW[0].toUpperCase());
  };

  return (
    <>
      <Translations locale={locale} />
      <IsRtl locale={locale} />
      <ExchangeRate />
      <ShopCashUser
        domainBasedApiBaseUrl={domainBasedApiBaseUrl}
        currentSite={currentSite}
        locale={locale}
        user={user}
      />
      <Switch>
        <Route
          exact
          strict
          path={paths}
          render={({ location, match }) => {
            const params = match.params;

            let search = location.search;
            const searchParams = new URLSearchParams(search);
            const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

            let title = translateText(
              translations.home_title,
              locale,
              posName(window.location.hostname),
            );
            let metaDescription = translateText(
              translations.home_des,
              locale,
              posName(window.location.hostname),
            );

            if (!!params.flights) {
              title = translateText(
                translations.flight_title,
                locale,
                posName(window.location.hostname),
              );
              metaDescription = translateText(
                translations.flight_des,
                locale,
                posName(window.location.hostname),
              );
            }

            if (!!params.hotels) {
              title = translateText(
                translations.hotel_title,
                locale,
                posName(window.location.hostname),
              );
              metaDescription = translateText(
                translations.hotel_des,
                locale,
                posName(window.location.hostname),
              );
            }

            return (
              <>
                <Helmet>
                  <title>{title}</title>
                  <meta name='description' content={metaDescription} />
                  <link
                    rel='canonical'
                    href={`${window.location.origin}${window.location.pathname}`}
                  />

                  {getHrefLangs(locale).map(({ hreflang, url }) => {
                    return <link key={hreflang} rel='alternate' hrefLang={hreflang} href={url} />;
                  })}

                  {isStagingEnvironment(window.location.hostname) ? (
                    <meta name='robots' content='noindex' />
                  ) : null}

                  {hasBowOnlyParamAsTrue ? <meta name='robots' content='none' /> : null}
                </Helmet>

                <LocaleRoutes
                  apiBaseUrl={apiBaseUrl}
                  currency={currency}
                  currentSite={currentSite}
                  domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                  exchangeRate={exchangeRate}
                  isRtl={isRtl}
                  locale={locale}
                  onInjectReducers={onInjectReducers}
                  translations={translations}
                  user={user}
                  shopCashUser={shopCashUser}
                />
              </>
            );
          }}
        />
      </Switch>
    </>
  );
};

export default Routes;
