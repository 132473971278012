import clsx from '@alexis/helpers/clsx';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import { appendSearchParams } from '@alexis/helpers/searchParams';
import cuid from 'cuid';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { cloudinaryImageTransformations } from '@helpers/imageTransformations';

import ChevronDown from '@icons/chevron_down.svg';

import {
  getCurrentSiteState,
  getLocaleState,
  getTranslationsState,
  getUserState,
} from '@redux/selectors';

import styles from './ServicesMenu.module.scss';

interface ServicesMenuProps {
  clientId: string | undefined;
  clientSessionId: string | undefined;
  pageViewId: string;
}

const ServicesMenu: React.FC<ServicesMenuProps> = ({ clientId, clientSessionId, pageViewId }) => {
  const { search } = useLocation();
  const translations = useSelector(getTranslationsState);
  const currentSite = useSelector(getCurrentSiteState);
  const locale = useSelector(getLocaleState);
  const user = useSelector(getUserState);
  const genzoActionIdOfWegoPro = cuid();
  const genzoActionIdOfTrains = cuid();
  const isPOSIndia = currentSite.countryCode === 'IN';

  const handleOnWegoProClick = (): void => {
    const eventData = {
      id: pageViewId,
      category: 'products',
      object: 'section',
      action: '1',
      value: 'wegopro_businesstravel',
    };

    genzoTrackActionEvent(
      API_BASE_URL,
      clientId!,
      clientSessionId!,
      user?.userHash,
      eventData,
      genzoActionIdOfWegoPro,
    );
  };

  const handleOnTrainClick = (): void => {
    const eventData = {
      id: pageViewId,
      category: 'products',
      object: 'section',
      action: '2',
      value: 'trains',
    };

    genzoTrackActionEvent(
      API_BASE_URL,
      clientId!,
      clientSessionId!,
      user?.userHash,
      eventData,
      genzoActionIdOfTrains,
    );
  };

  return (
    <div className={clsx(styles.container)}>
      <a
        className={clsx(isPOSIndia && styles.twoPerRow)}
        href={`https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=homepage&wego_click_id=${genzoActionIdOfWegoPro}`}
        target='_blank'
        rel='noreferrer'
        onClick={handleOnWegoProClick}
      >
        <>
          <div className={styles.newLabel}>
            {((locale === 'ar' ? translations.menue_new : 'New') || '').toLowerCase()}
          </div>
          <div className={styles.imageAndLabelContainer}>
            <img
              src={cloudinaryImageTransformations(
                'https://assets.wego.com/image/upload/v1633919554/web/mobile/homepage/grid-icons/wegopro.png',
                'w_96',
                'h_96',
                'c_fill',
                'f_auto',
                'fl_lossy',
                'q_auto:low',
              )}
            />
            <div className={styles.content}>
              <span className={styles.title}>WegoPro</span>
              &nbsp;{translations.business_travel}
            </div>
          </div>
          <ChevronDown className={styles.chevronIcon} />
        </>
      </a>

      {isPOSIndia ? (
        <a
          className={styles.twoPerRow}
          href={appendSearchParams(`https://trains.wego.com${search}`, {
            wego_click_id: genzoActionIdOfTrains,
          })}
          target='_blank'
          rel='noreferrer nofollow'
          onClick={handleOnTrainClick}
        >
          <>
            <div className={styles.imageAndLabelContainer}>
              <img
                src={cloudinaryImageTransformations(
                  'https://assets.wego.com/image/upload/v1633919554/web/mobile/homepage/grid-icons/trains.png',
                  'w_96',
                  'h_96',
                  'c_fill',
                  'f_auto',
                  'fl_lossy',
                  'q_auto:low',
                )}
              />
              <div className={styles.content}>{translations.wego_trains}</div>
            </div>
            <ChevronDown className={styles.chevronIcon} />
          </>
        </a>
      ) : null}
    </div>
  );
};

export default ServicesMenu;
