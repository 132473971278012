import webEngageScript from '!!raw-loader!./webEngageScript';
import type { User } from '@alexis/types/user';
import type { WebEngage } from '@alexis/types/webEngage/webEngage';

declare global {
  interface Window {
    webengage: WebEngage;
  }
}

export function loadWebEngageScript(
  licenseCode: string,
  registerServiceWorker: boolean,
): WebEngage {
  const elementId: string = '_webengage_script_tag';
  const document$: Document = document;
  const existingScriptElement = document$.getElementById(elementId);

  if (!existingScriptElement) {
    const scriptElement = document$.createElement('script');
    scriptElement.id = elementId;
    scriptElement.text = webEngageScript;
    document$.head.appendChild(scriptElement);

    window.webengage.init(licenseCode);
    window.webengage.options('webpush.registerServiceWorker', registerServiceWorker);

    window.webengage.onReady(() => {
      const subscribed = window.webengage.webpush.isSubscribed();

      if (!subscribed) {
        document.addEventListener(
          'registerServiceWorker',
          () => {
            window.webengage.webpush.prompt();
          },
          { once: true },
        );
      }
    });
  }
  return window.webengage;
}

export function webEngageSetUserAttribute(
  licenseCode: string,
  registerServiceWorker: boolean,
  attribute: string,
  value: string,
): void {
  const webEngage = loadWebEngageScript(licenseCode, registerServiceWorker);

  webEngage.user.setAttribute(attribute, value);
}

export function webEngageTrackLogin(
  licenseCode: string,
  registerServiceWorker: boolean,
  user: User,
): void {
  const webEngage = loadWebEngageScript(licenseCode, registerServiceWorker);

  webEngage.user.login(user.userHash);
  webEngage.user.setAttribute('we_email', user.email);
  webEngage.user.setAttribute('user_status', 'active');
  webEngage.user.setAttribute('we_first_name', user.displayName);
}

export function webEngageTrackLogout(licenseCode: string, registerServiceWorker: boolean): void {
  const webEngage = loadWebEngageScript(licenseCode, registerServiceWorker);

  webEngage.user.logout();
}

export function webEngageTrackEvent(
  licenseCode: string,
  registerServiceWorker: boolean,
  eventName: string,
  data: any,
): void {
  const webEngage = loadWebEngageScript(licenseCode, registerServiceWorker);

  webEngage.track(eventName, data);
}
