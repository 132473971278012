declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

const loadGTMClientLibrary = (gtmId: string, gtmAuth: string, gtmPreview: string): Array<any> => {
  const elementId: string = 'gtmScript';
  const document$: Document = document;
  const existingScriptElement = document$.getElementById(elementId);

  if (!existingScriptElement) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

    const scriptTagName = 'script';

    const scriptElement = document$.createElement(scriptTagName);
    scriptElement.id = elementId;
    scriptElement.async = true;
    scriptElement.dataset.cookieconsent = 'ignore';
    scriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x`;

    const firstScriptElement: HTMLScriptElement = document$.getElementsByTagName(scriptTagName)[0];

    if (!!firstScriptElement) {
      firstScriptElement.parentNode!.insertBefore(scriptElement, firstScriptElement);
    } else {
      document$.head.appendChild(scriptElement);
    }
  }
  return window.dataLayer;
};

export const gtmTrack = (gtmId: string, gtmAuth: string, gtmPreview: string, data: any): void => {
  const dataLayer = loadGTMClientLibrary(gtmId, gtmAuth, gtmPreview);

  dataLayer.push(data);
};
