import clsx from '@alexis/helpers/clsx';
import { dateApiFormat } from '@alexis/helpers/date';
import { translateText } from '@alexis/helpers/translation';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { convertGuestRoomsToSearchParam } from '../../helpers/hotel';
import {
  cloudflareImageTransformations,
  cloudinaryImageTransformations,
  isCloudinarySrc,
} from '../../helpers/imageTransformations';
import { hotelsRoute } from '../../helpers/routeTranslation';
import styles from '../../styles/components/home/RecommendedHotelFilter.module.scss';
import type { RecommendedHotelFilterProps } from '../../types/props/recommendedHotelFilterProps';
import Image from '../Image';

// TODO [>v] pluralization for view_hotels ?
const RecommendedHotelFilter: React.FC<RecommendedHotelFilterProps> = ({
  className,
  currentSite,
  locale,
  translations,
  hotelRecentSearch,
  recommendedHotelFilter,
}) => {
  const [filtersSearchParam, setFiltersSearchParam] = useState<string>('');

  const { search } = useLocation();
  const params = useParams<{ locale?: string }>();

  useEffect(() => {
    setFiltersSearchParam(
      Object.entries(recommendedHotelFilter.filter)
        .map<string>((value) => {
          let key: string = '';

          switch (value[0]) {
            case 'accommodationTypes':
              key = 'accommodation_types';
              break;
            case 'guestScore':
              key = 'guest_score';
              break;
            default:
              key = value[0];
          }

          return `${key}=${value[1].join(',')}`;
        })
        .join('&'),
    );
  }, [recommendedHotelFilter]);

  const guestsSearchParam: string = `guests=${convertGuestRoomsToSearchParam(
    hotelRecentSearch!.guestRooms,
  )}`;

  const getImageTransformationsUrl = useCallback<(url: string) => string>((url: string) => {
    if (isCloudinarySrc(url)) {
      return cloudinaryImageTransformations(
        url,
        'w_540',
        'h_360',
        'c_fill',
        'f_auto',
        'fl_lossy',
        'q_auto',
        'g_auto',
      );
    } else {
      return cloudflareImageTransformations(url, { format: 'auto', height: 368, quality: 90 });
    }
  }, []);

  return (
    <Link
      className={clsx(styles.container, className)}
      to={{
        pathname: `${hotelsRoute(
          currentSite,
          params.locale,
        )}/searches/${hotelRecentSearch.destinationLocation!.cityCode?.toLowerCase()}/${dateApiFormat(
          new Date(hotelRecentSearch.checkInDateMilliseconds!),
        )}/${dateApiFormat(new Date(hotelRecentSearch.checkOutDateMilliseconds!))}`,
        search: `${
          !!search
            ? `${search}&${guestsSearchParam}&${filtersSearchParam}`
            : `?${guestsSearchParam}&${filtersSearchParam}`
        }`,
        state: { from: '/' },
      }}
    >
      <Image
        className={styles.image}
        isLazy
        imageUrl={getImageTransformationsUrl(recommendedHotelFilter.imageUrl)}
        noImageUrl='https://assets.wego.com/image/upload/w_540,h_360,c_fill,f_auto,fl_lossy,q_auto:low/v21012019/NTO/top10hotels_placeholder_image.jpg'
      />

      <div className={styles.title}>{recommendedHotelFilter.title}</div>
      <div className={styles.linkLabel}>
        {translateText(translations.view_hotels, locale, recommendedHotelFilter.hotelCount)}
      </div>
    </Link>
  );
};

export default RecommendedHotelFilter;
