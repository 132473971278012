import { HotelSortType } from '@wegoTypes/Hotel/hotelSortType';

export const PAYMENT_METHOD_LOGOS: { [key: string]: string } = Object.freeze({
  airtelmoney: 'airtelMoney',
  amex: 'amex',
  applepay: 'applePay',
  bnpl_3_installments: 'tabby',
  bnpl_4_installments: 'tabby',
  emi: 'emi',
  freecharge: 'freecharge',
  jiomoney: 'jioMoney',
  knet: 'knet',
  masc: 'masterCard',
  madc: 'mada',
  mobikwik: 'mobiKwik',
  netbanking: 'netBanking',
  olamoney: 'olaMoney',
  phonepe: 'phonePe',
  rupay: 'ruPay',
  upi: 'upi',
  visc: 'visa',
});

export const HOTEL_SORT_TYPE: Record<HotelSortType, string> = {
  [HotelSortType.Recommended]: 'popularity',
  [HotelSortType.Price]: 'price',
  [HotelSortType.Discount]: 'discount',
  [HotelSortType.Saving]: 'saving',
  [HotelSortType.AllReviewScore]: 'all_review_score',
  [HotelSortType.NearestToYou]: 'distance_to_you',
  [HotelSortType.NearestToCityCentre]: 'distance_to_city_center',
  [HotelSortType.NearestToAirport]: 'distance_to_nearest_airport',
  [HotelSortType.NearestToTargetLocation]: 'distance_to_location',
  [HotelSortType.Star]: 'stars',
  [HotelSortType.Landmark]: 'landmark',
};

// Session storage keys
export const HOTEL_BOOKING_PAYMENT_GATEWAY = 'hotelBookingPaymentGateway';
// end Session storage keys

export const DISPLAY_3_LATEST_REVIEWS = 'w_hmeta613_latest_reviews_in_srp';

export const GENAI_GOODTOKNOW = 'w_hmeta671_genai_goodtoknow';

export const NEW_RATING_SCALE = 'w_hmeta753_rescaling_ratings';

// Campaign Constants (Yas Island)
export const HOTELS_IN_CAMPAIGN = [
  19779, 759674, 2877384, 677191, 2877384, 794699, 900599, 19793, 1399845,
];
export const SUPPORTED_LOCALES = ['ar', 'en'];
export const SUPPORTED_POS = ['SA', 'AE', 'KW', 'OM', 'BH', 'QA'];
export const BUNDLED_RATE_WORDS = ['theme', 'park', 'warner', 'bros', 'waterworld', 'ferrari'];

export const NEW_RECENT_SEARCHES = 'mw_hmeta173_recent_searches';
