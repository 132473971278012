import { ShopCashActionTypes } from './types';

export const changeShopCashUser = (
  shopCashUser: ShopCashUser,
): { type: ShopCashActionTypes; payload: ShopCashUser } => ({
  type: ShopCashActionTypes.ChangeShopCashUser,
  payload: shopCashUser,
});

export const resetShopCashUser = (): { type: ShopCashActionTypes; payload: null } => ({
  type: ShopCashActionTypes.ResetShopCashUser,
  payload: null,
});
