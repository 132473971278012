import clsx from '@alexis/helpers/clsx';
import { currenciesConfig } from '@alexis/helpers/currency';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TickIcon from '@icons/tick.svg';

import { changeCurrency } from '@redux/actions/currencyActions';
import { getCurrencyState, getTranslationsState } from '@redux/selectors';

import styles from '@styles/components/Menu.module.scss';

import { DropdownDirection } from '../types/dropdownDirection';
import Dropdown from './form/Dropdown';

type CurrencyDropdownProps = { isOpen: boolean; onClose: () => void };

export default function CurrencyDropdown({ isOpen, onClose }: CurrencyDropdownProps) {
  const currency = useSelector(getCurrencyState);
  const translations = useSelector(getTranslationsState);

  const dispatch = useDispatch();

  const handleCurrencyClick = useCallback<
    (event: React.MouseEvent<HTMLDivElement>, currency: Currency) => void
  >((event: React.MouseEvent<HTMLDivElement>, currency: Currency) => {
    event.stopPropagation();

    dispatch(changeCurrency(currency));
    onClose();
  }, []);

  return (
    <Dropdown className={styles.dropdown} isOpen={isOpen} direction={DropdownDirection.Top}>
      {currenciesConfig.map((currencyObject) => (
        <div
          key={currencyObject.code}
          data-testid={`currency-option-${currencyObject.code}`}
          className={clsx(styles.option, currency.code === currencyObject.code && styles.selected)}
          onClick={(e) => handleCurrencyClick(e, currencyObject)}
          data-selected={currency.code === currencyObject.code}
        >
          <div>
            <div className={styles.code}>{currencyObject.code}</div>
            <div className={styles.name}>
              {translations[currencyObject.translationKey]} ({currencyObject.symbol})
            </div>
          </div>
          <TickIcon className={styles.icon} />
        </div>
      ))}
    </Dropdown>
  );
}
