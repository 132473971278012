import { appendSearchParams } from '@alexis/helpers/searchParams';
import axios, { CancelToken } from 'axios';

/**
 * It makes a GET request to the Connect API, and returns the response data
 * @param {string} apiBaseUrl - The base URL of the API.
 * @param {string} clientId - The client ID of the app.
 * @param {Platform} platform - The platform of the session,
 * @param {string} siteCode - The current site code.
 * @param {string} language - The language of the user.
 * @param {AppType} appType - AppType
 * @param {DeviceType} deviceType - DeviceType
 * @param {string} currency - The currency code of the user.
 * @param {IncludeOption} include - IncludeOption
 * @param {CancelToken} cancelToken - CancelToken
 * @returns A promise that resolves to a ConnectAPIResponse object.
 */
export const getExperimentDataWithConnectAPI = async (
  apiBaseUrl: string,
  clientId: string,
  platform: Platform,
  siteCode: string,
  language: string,
  appType: AppType,
  deviceType: DeviceType,
  currency: string,
  include: IncludeOption,
  cancelToken: CancelToken,
): Promise<ConnectAPIResponse> => {
  const response = await axios.get(
    appendSearchParams(`${apiBaseUrl}/user-centric/v1/connect`, {
      platform,
      siteCode,
      language,
      appType,
      deviceType,
      currency,
      include,
      project: 'WEGO',
      pageGroup: 'HOME',
    }),
    {
      headers: {
        'X-WEGO-CLIENT-ID': clientId,
      },
      cancelToken,
      withCredentials: true,
    },
  );

  if (response.status === 200) {
    return response.data;
  }

  throw new Error('A/B testing API failed (status !== 200) - mWeb');
};
