import { FlightLocation } from 'flightLocation';
import { FlightSearch } from 'flightSearch';

import { SuggestedNearbyLocationState } from '@components/flights/FlightSearchForm/types';

import { CabinClass } from '../../types/cabinClass';
import { TripType } from '../../types/tripType';
import { FlightSearchActionTypes } from './types';

export const changeTripType = (
  tripType: TripType,
): { type: FlightSearchActionTypes; payload: TripType } => ({
  type: FlightSearchActionTypes.ChangeTripType,
  payload: tripType,
});

export const changeDepartureLocation = (
  location: FlightLocation | undefined,
): { type: FlightSearchActionTypes; payload: FlightLocation | undefined } => ({
  type: FlightSearchActionTypes.ChangeDepartureLocation,
  payload: location,
});

export const changeArrivalLocation = (
  location: FlightLocation | undefined,
): { type: FlightSearchActionTypes; payload: FlightLocation | undefined } => ({
  type: FlightSearchActionTypes.ChangeArrivalLocation,
  payload: location,
});

export const changeDepartureDate = (
  date: Date,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.ChangeDepartureDate,
  payload: date.getTime(),
});

export const changeReturnDate = (
  date?: Date,
): { type: FlightSearchActionTypes; payload?: number } => ({
  type: FlightSearchActionTypes.ChangeReturnDate,
  payload: date ? date.getTime() : undefined,
});

export const changeAdultCount = (
  count: number,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.ChangeAdultCount,
  payload: count,
});

export const changeChildrenCount = (
  count: number,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.ChangeChildrenCount,
  payload: count,
});

export const changeInfantCount = (
  count: number,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.ChangeInfantCount,
  payload: count,
});

export const changeCabinClass = (
  cabinClass: CabinClass,
): { type: FlightSearchActionTypes; payload: CabinClass } => ({
  type: FlightSearchActionTypes.ChangeCabinClass,
  payload: cabinClass,
});

export const changePaymentMethods = (
  paymentMethodIds: Array<number>,
): { type: FlightSearchActionTypes; payload: Array<number> } => ({
  type: FlightSearchActionTypes.ChangePaymentMethods,
  payload: paymentMethodIds,
});

export const addPaymentMethod = (
  paymentMethodId: number,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.AddPaymentMethod,
  payload: paymentMethodId,
});

export const removePaymentMethod = (
  paymentMethodId: number,
): { type: FlightSearchActionTypes; payload: number } => ({
  type: FlightSearchActionTypes.RemovePaymentMethod,
  payload: paymentMethodId,
});

export const changeFlightSearch = (
  flightSearch: FlightSearch,
): { type: FlightSearchActionTypes; payload: FlightSearch } => ({
  type: FlightSearchActionTypes.ChangeFlightSearch,
  payload: flightSearch,
});

export const changeNearbyDepartureLocations = (
  nearbyLocations: Array<SuggestedNearbyLocationState>,
): { type: FlightSearchActionTypes; payload: Array<SuggestedNearbyLocationState> } => ({
  type: FlightSearchActionTypes.ChangeNearbyDepartureLocations,
  payload: nearbyLocations,
});

export const changeNearbyArrivalLocations = (
  nearbyLocations: Array<SuggestedNearbyLocationState>,
): { type: FlightSearchActionTypes; payload: Array<SuggestedNearbyLocationState> } => ({
  type: FlightSearchActionTypes.ChangeNearbyArrivalLocations,
  payload: nearbyLocations,
});

export const changePerLegSearchSelectedDepartLegId = (
  perLegSearchSelectedDepartLegId: string | number | undefined,
): { type: FlightSearchActionTypes; payload: string | number | undefined } => ({
  type: FlightSearchActionTypes.ChangePerLegSearchSelectedDepartLegId,
  payload: perLegSearchSelectedDepartLegId,
});

export const changePerLegSearchSelectedTripViewModelId = (
  perLegSearchSelectedTripViewModelId: string | number | undefined,
): { type: FlightSearchActionTypes; payload: string | number | undefined } => ({
  type: FlightSearchActionTypes.ChangePerLegSearchSelectedTripViewModelId,
  payload: perLegSearchSelectedTripViewModelId,
});

export const changePerLegSearchDepartLegScrollPosition = (
  scrollPosition: number | undefined,
): { type: FlightSearchActionTypes; payload: number | undefined } => ({
  type: FlightSearchActionTypes.ChangePerLegSearchDepartLegScrollPosition,
  payload: scrollPosition,
});

export const changePerLegSearchReturnLegScrollPosition = (
  scrollPosition: number | undefined,
): { type: FlightSearchActionTypes; payload: number | undefined } => ({
  type: FlightSearchActionTypes.ChangePerLegSearchReturnLegScrollPosition,
  payload: scrollPosition,
});
