import { createStore, combineReducers, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';

import apiBaseUrl from './reducers/apiBaseUrl';
import coreConfig from './reducers/coreConfig';
import currency from './reducers/currency';
import currentSite from './reducers/currentSite';
import exchangeRate from './reducers/exchangeRate';
import isRtl from './reducers/isRtl';
import locale from './reducers/locale';
import translations from './reducers/translations';
import webEngage from './reducers/webEngage';

// Define the Reducers that will always be present in the application
export const baseReducers: { [key: string]: any } = {
  currentSite,
  coreConfig,
  webEngage,
  locale,
  translations,
  isRtl,
  currency,
  exchangeRate,
  apiBaseUrl,
};

let reducers = { ...baseReducers };

export const injectReducers = (asyncReducers: { [key: string]: any }): void => {
  const newReducers = { ...reducers };

  Object.keys(asyncReducers).map((key) => {
    if (!newReducers[key]) {
      newReducers[key] = asyncReducers[key];
    }
  });

  if (Object.entries(reducers).length !== Object.entries(newReducers).length) {
    store.replaceReducer(combineReducers(newReducers));
    reducers = newReducers;
  }
};

const store: Store = createStore(combineReducers(reducers), applyMiddleware(thunk));

export const getStore = (): Store => {
  return store;
};
