import { toISOStringWithTimezone } from '@alexis/helpers/date';
import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import axios from 'axios';
import cuid from 'cuid';

import { PartnerPromoGenzoData } from '@wegoTypes/PartnerPromo/partnerPromo';

const GENZO_TRACKING_QUEUE_LOCALSTORAGE_KEY = 'genzoTrackingQueue';
const GENZO_IDENTIFIER_ID = 'genzoIdentifierId';
const RETRIES_DELAY = 3000;

let genzoTrackingQueue: Array<{ url: string; data: any }> = [];

export const genzoTrack = async (url: string, data: any, retries: number = 3): Promise<void> => {
  if (navigator.onLine) {
    try {
      const response = await axios.post(url, data, { withCredentials: true });
      const genzoIdentifierId = response?.headers['x-wego-genzo-identifier-id'];

      if (!!genzoIdentifierId) {
        localStorageSave(GENZO_IDENTIFIER_ID, genzoIdentifierId);
      }

      if (response.status !== 200 && response.status !== 204) {
        throw new Error(response.statusText);
      }
    } catch (error) {
      if (!!error.response && error.response.status === 422) {
        return;
      }

      const retriesLeft = --retries;

      if (retriesLeft !== 0) {
        setTimeout(() => {
          genzoTrack(url, data, retriesLeft);
        }, RETRIES_DELAY);
      } else {
        genzoTrackingQueue.push({ url, data });
        document.dispatchEvent(new CustomEvent('genzoTrackingQueueChanged'));
      }
    }
  } else {
    genzoTrackingQueue.push({ url, data });
    document.dispatchEvent(new CustomEvent('genzoTrackingQueueChanged'));
  }
};

const triggerMissedGenzoTracking = (): void => {
  while (genzoTrackingQueue.length > 0) {
    const event = genzoTrackingQueue.shift();
    localStorageSave(GENZO_TRACKING_QUEUE_LOCALSTORAGE_KEY, genzoTrackingQueue);
    genzoTrack(event!.url, event!.data);
  }
};

export const initializeGenzo = (): void => {
  genzoTrackingQueue =
    localStorageGet<Array<{ url: string; data: any }>>(GENZO_TRACKING_QUEUE_LOCALSTORAGE_KEY) || [];

  document.addEventListener('genzoTrackingQueueChanged', () =>
    localStorageSave(GENZO_TRACKING_QUEUE_LOCALSTORAGE_KEY, genzoTrackingQueue),
  );

  window.addEventListener('online', triggerMissedGenzoTracking);

  triggerMissedGenzoTracking();
};

export const genzoTrackPageView = async (apiBaseUrl: string, data: any): Promise<void> => {
  await genzoTrack(`${apiBaseUrl}/genzo/v3/pages/view`, data);
};

export const genzoTrackActionEvent = async (
  apiBaseUrl: string,
  clientId: string,
  clientSessionId: string,
  userHash: string | undefined,
  eventData: any,
  id?: string,
): Promise<void> => {
  const client: any = {
    id: clientId,
    session_id: clientSessionId,
    ...(!!userHash && { user_hash: userHash }),
  };

  const data = {
    id: id || cuid(),
    client: client,
    event: eventData,
    created_at: toISOStringWithTimezone(new Date()),
  };

  await genzoTrack(`${apiBaseUrl}/genzo/v3/events/action`, data);
};

export const genzoTrackCarouselClick = async ({
  apiBaseUrl,
  clientId,
  clientSessionId,
  userHash,
  carouselData,
  id,
  pageViewId,
}: {
  apiBaseUrl: string;
  clientId: string;
  clientSessionId: string;
  userHash?: string;
  carouselData: PartnerPromoGenzoData;
  id: string;
  pageViewId: string;
}): Promise<void> => {
  const client = {
    id: clientId,
    session_id: clientSessionId,
    app_type: 'MOBILE_WEB_APP',
    device_type: 'MOBILE',
    ...(!!userHash && { user_hash: userHash }),
  };

  const data = {
    id,
    client: client,
    carousel: carouselData,
    page_view_id: pageViewId,
    created_at: toISOStringWithTimezone(new Date()),
  };

  await genzoTrack(`${apiBaseUrl}/genzo/v3/carousels/click`, data);
};

/**
 *
 * @param apiBaseUrl "https://srv.wego.com / https://srv.wegostaging.com"
 * @param appType "MOBILE_WEB_APP / WEB_APP"
 * @param clientId "wego_analytics_client_id"
 * @param sessionId "wego_analytics_client_session_id"
 * @param locale
 * @param countryCode "currentSite.countryCode"
 * @param userHash
 */
export const genzoTrackNewSession = async (
  apiBaseUrl: string,
  appType: string,
  clientId: string,
  sessionId: string,
  locale: string,
  countryCode: string,
  userHash?: string,
  additionalData?: any,
): Promise<void> => {
  const client: any = {
    id: clientId,
    session_id: sessionId,
    app_type: appType,
  };

  if (!!userHash) {
    client.user_hash = userHash;
  }

  const data = {
    id: cuid(),
    client: client,
    page: {
      locale: locale,
      site_code: countryCode,
      url: window.location.href,
      referrer_url: document.referrer,
    },
    created_at: toISOStringWithTimezone(new Date()),
    ...(!!additionalData && additionalData),
  };

  await genzoTrack(`${apiBaseUrl}/genzo/v3/sessions/event`, data);
};

export const genzoTrackImpression = async (
  actionType: string,
  apiBaseUrl: string,
  clientId: string,
  clientSessionId: string,
  eventId: string | null,
  objects: Array<any>,
  pageName: string,
  pageViewId: string,
  searchId: string,
  sortOrder: string | null,
  userHash: string | undefined,
): Promise<void> => {
  const client: any = {
    id: clientId,
    session_id: clientSessionId,
    user_agent: window.navigator.userAgent,
    ...(!!userHash && { user_hash: userHash }),
  };

  const impression = {
    page: pageName,
    trigger: actionType,
    ...(!!sortOrder && { sort_order: sortOrder }),
  };

  const data = {
    created_at: toISOStringWithTimezone(new Date()),
    id: cuid(),
    client: client,
    page_view_id: pageViewId,
    search_id: searchId,
    impression: impression,
    objects: objects,
    ...(!!eventId && { event_id: eventId }),
  };

  await genzoTrack(`${apiBaseUrl}/genzo/v3/impressions/log`, data);
};
