import clsx from '@alexis/helpers/clsx';
import { translateNumber } from '@alexis/helpers/translation';
import * as H from 'history';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Image from '@components/Image';
import Price from '@components/Price';

import { NEW_RATING_SCALE } from '@constants/hotels';

import {
  cloudflareImageTransformations,
  cloudinaryImageTransformations,
  isCloudinarySrc,
} from '@helpers/imageTransformations';
import { convertHotelPriceToPrice } from '@helpers/price';
import {
  getReviewRatingTypeColorClassName,
  getReviewRatingTypeColorClassNameOldScale,
  getReviewRatingTypeDisplay,
  getReviewRatingTypeDisplayOldScale,
  getReviewScoreDisplay,
} from '@helpers/review';

import StarIcon from '@icons/star_rounded.svg';

import {
  getCoreConfigState,
  getCurrencyState,
  getExchangeRateState,
  getLocaleState,
  getTranslationsState,
} from '@redux/selectors';

import { HotelMetaSearchHotelReview } from '@wegoTypes/Hotel/hotelMetaSearchHotelReview';
import { MiniHotel } from '@wegoTypes/miniHotel';

import styles from './MiniHotelCard.module.scss';

export interface MiniHotelCardProps {
  priceOptions: PriceOptions;
  hotel: MiniHotel;
  to:
    | H.LocationDescriptor<H.LocationState>
    | ((location: H.Location<H.LocationState>) => H.LocationDescriptor<H.LocationState>);
  target?: '_blank' | '_self' | '_parent' | '_top';
  className?: string;
  onCardClick?: () => void;
}

const MiniHotelCard: React.FC<MiniHotelCardProps> = ({
  hotel,
  to,
  target,
  priceOptions,
  className,
  onCardClick,
}): JSX.Element => {
  const coreConfigs = useSelector(getCoreConfigState);
  const currency = useSelector(getCurrencyState);
  const exchangeRate: ExchangeRate = useSelector(getExchangeRateState);
  const locale: string = useSelector(getLocaleState);
  const translations = useSelector(getTranslationsState);

  const isNewRatingScale =
    coreConfigs.find((coreConfig) => coreConfig.key === NEW_RATING_SCALE)?.value === '1';

  const review: HotelMetaSearchHotelReview | undefined = hotel.reviews[0];
  const imageUrl = useMemo(() => {
    if (!!hotel.images[0]) {
      if (isCloudinarySrc(hotel.images[0].url)) {
        return cloudinaryImageTransformations(
          hotel.images[0].url,
          'w_540',
          'h_360',
          'c_fill',
          'f_auto',
          'fl_lossy',
          'q_auto:low',
          'g_auto',
        );
      }
      return cloudflareImageTransformations(hotel.images[0].url, {
        format: 'auto',
        height: 360,
        quality: 50,
        width: 540,
      });
    }
    return '';
  }, [hotel.images]);

  return (
    <Link
      className={clsx(styles.container, className)}
      to={to}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      onClick={onCardClick ? onCardClick : void 0}
    >
      <Image
        className={styles.image}
        imageUrl={imageUrl}
        isLazy={true}
        noImageUrl='https://assets.wego.com/image/upload/w_540,h_360,c_fill,f_auto,fl_lossy,q_auto:low/v21012019/NTO/top10hotels_placeholder_image.jpg'
      />

      <div className={styles.content}>
        <div className={styles.name}>{hotel.name}</div>

        {!!hotel.star || !!hotel.district ? (
          <div className={styles.starAndDistrict}>
            {!!hotel.star ? (
              <>
                {[...Array(hotel.star).keys()].map((value: number) => {
                  return <StarIcon key={value} className={styles.star} />;
                })}
              </>
            ) : null}

            {!!hotel.district?.name ? (
              <div className={styles.district}>
                {!!hotel.star ? <span>・</span> : null}
                {hotel.district.name}
              </div>
            ) : null}
          </div>
        ) : null}

        {!!review ? (
          <div className={styles.review}>
            <div
              className={clsx(
                styles.score,
                isNewRatingScale
                  ? getReviewRatingTypeColorClassName(review.score, styles)
                  : getReviewRatingTypeColorClassNameOldScale(review.score, styles),
              )}
            >
              {translateNumber(getReviewScoreDisplay(review.score), locale === 'fa')}
            </div>

            <div className={styles.reviewTypeAndCount}>
              {isNewRatingScale
                ? getReviewRatingTypeDisplay(
                    review?.score,
                    translations as { [key: string]: string },
                  )
                : getReviewRatingTypeDisplayOldScale(
                    review?.score,
                    translations as { [key: string]: string },
                  )}

              <div className={styles.reviewCount}>
                ・{review.count}&nbsp;
                {translations.hotel_details_reviews_tab}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noReview}>{translations.no_reviews}</div>
        )}

        <div className={styles.rate}>
          {!!hotel.rate ? (
            <>
              <Price
                className={styles.price}
                price={convertHotelPriceToPrice(
                  hotel.rate,
                  priceOptions.showTotalPrice,
                  priceOptions.isIncludingTaxOrFee,
                )}
                locale={locale}
                currency={currency}
                exchangeRate={exchangeRate}
                isInline
              />
              <div className={styles.priceType}>
                {priceOptions.showTotalPrice
                  ? translations.total_stay
                  : translations.hotel_per_night}
              </div>
            </>
          ) : (
            <div className={styles.getRates}>{translations.get_rates}</div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default MiniHotelCard;
