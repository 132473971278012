import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import type { User } from '@alexis/types/user';
import type { FlightSearch } from 'flightSearch';

import { HotelMetaSearch } from '@wegoTypes/Hotel/hotelMetaSearch';
import type { HotelSearch } from '@wegoTypes/Hotel/Search/hotelSearch';

import type { WebEngageStore } from './reducers/webEngage';

export const getCurrentSiteState = (store: { currentSite: CurrentSite }) => store.currentSite;

export const getCoreConfigState = (store: { coreConfig: Array<CoreConfig> }) => store.coreConfig;

export const getWebEngageState = (store: { webEngage: WebEngageStore }) => store.webEngage;

export const getLocaleState = (store: { locale: string }) => store.locale;

export const getTranslationsState = (store: { translations: { [key: string]: string } }) =>
  store.translations;

export const getIsRtlState = (store: { isRtl: boolean }) => store.isRtl;

export const getCurrencyState = (store: { currency: Currency }) => store.currency;

export const getExchangeRateState = (store: { exchangeRate: ExchangeRate }) => store.exchangeRate;

export const getApiBaseUrlState = (store: { apiBaseUrl: string }) => store.apiBaseUrl;

export const getDomainBasedApiBaseUrlState = (store: { domainBasedApiBaseUrl: string }) =>
  store.domainBasedApiBaseUrl;

export const getUserState = (store: { user: User }) => store.user;

export const getInterstitialProviderMapState = (store: {
  interstitialProviderMap: InterstitialProviderMap;
}) => store.interstitialProviderMap;

export const getPriceOptionsState = (store: { priceOptions: PriceOptions }) => store.priceOptions;

export const getFlightSearchState = (store: { flightSearch: FlightSearch }) => store.flightSearch;

export const getFlightRecentSearchesState = (store: {
  flightRecentSearches: Array<FlightSearch>;
}) => store.flightRecentSearches;

export const getFlightMetaSearchState = (store: { flightMetaSearch: FlightMetaSearch }) =>
  store.flightMetaSearch;

export const getHotelSearchState = (store: { hotelSearch: HotelSearch }) => store.hotelSearch;

export const getHotelRecentSearchesState = (store: { hotelRecentSearches: Array<HotelSearch> }) =>
  store.hotelRecentSearches;

export const getHotelMetaSearchState = (store: { hotelMetaSearch: HotelMetaSearch }) =>
  store.hotelMetaSearch;

export const getShopCashUserState = (store: { shopCashUser: ShopCashUser }) => store.shopCashUser;
