import { HomePageType } from '@wegoTypes/homePageType';

const isNonEnglishAndArabicLocale = (locale: string): boolean => {
  return !['en', 'ar'].includes(locale);
};

const buildParametersByHomePageType = (
  homePageType: HomePageType,
  locale: string,
  wegoSourceSearchParam?: string | null,
  wegoCampaignSearchParam?: string | null,
): string => {
  const params = [
    `?af_web_dp=https%3A%2F%2Fwww.wego.com%2F%3Fwg_source%3Dwego_com_${locale}%26wg_medium%3Dstick_nav_bar`,
    'af_xp=custom',
    'af_dp=wego%3A%2F%2F%3Fcategory%3Dhome',
  ].join('&');
  let pidValue = '';
  let cValue = '';

  switch (homePageType) {
    case HomePageType.Flights:
      pidValue = !!wegoSourceSearchParam
        ? wegoSourceSearchParam
        : `wego_com_flights_${locale}_homepage_use_app`;
      cValue = !!wegoCampaignSearchParam
        ? wegoCampaignSearchParam
        : `wego_com_flights_${locale}_homepage_use_app`;

      return `${params}&pid=${pidValue}&c=${cValue}&af_adset=wego_com_flights_${locale}_homepage_use_app`;

    case HomePageType.Hotels:
      pidValue = !!wegoSourceSearchParam
        ? wegoSourceSearchParam
        : `wego_com_hotels_${locale}_homepage_use_app`;
      cValue = !!wegoCampaignSearchParam
        ? wegoCampaignSearchParam
        : `wego_com_hotels_${locale}_homepage_use_app`;

      return `${params}&pid=${pidValue}&c=${cValue}&af_adset=wego_com_hotels_${locale}_homepage_use_app`;

    case HomePageType.Root:
      pidValue = !!wegoSourceSearchParam
        ? wegoSourceSearchParam
        : `wego_com_${locale}_homepage_use_app`;
      cValue = !!wegoCampaignSearchParam
        ? wegoCampaignSearchParam
        : `wego_com_${locale}_homepage_use_app`;

      return `${params}&pid=${pidValue}&c=${cValue}&af_adset=wego_com_${locale}_homepage_use_app`;
  }

  return '';
};

export const getLinkbyPageAndLocale = (
  homePageType: HomePageType,
  locale: string,
  wegoSourceSearchParam?: string | null,
  wegoCampaignSearchParam?: string | null,
): string => {
  let path = 'https://wegotravel.onelink.me/pGV9';

  switch (homePageType) {
    case HomePageType.Flights:
      return isNonEnglishAndArabicLocale(locale)
        ? 'https://wegotravel.onelink.me/pGV9/esjp1h13'
        : `${path}${buildParametersByHomePageType(
            HomePageType.Flights,
            locale,
            wegoSourceSearchParam,
            wegoCampaignSearchParam,
          )}`;

    case HomePageType.Hotels:
      return isNonEnglishAndArabicLocale(locale)
        ? 'https://wegotravel.onelink.me/pGV9/un6h3l3j'
        : `${path}${buildParametersByHomePageType(
            HomePageType.Hotels,
            locale,
            wegoSourceSearchParam,
            wegoCampaignSearchParam,
          )}`;

    case HomePageType.Root:
      return isNonEnglishAndArabicLocale(locale)
        ? 'https://wegotravel.onelink.me/pGV9/m7ghw4vt'
        : `${path}${buildParametersByHomePageType(
            HomePageType.Root,
            locale,
            wegoSourceSearchParam,
            wegoCampaignSearchParam,
          )}`;
  }

  return '';
};
