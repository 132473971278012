import type { Currency } from '@alexis/helpers/currency/currencyTypes';

import { CurrencyActionTypes } from './types';

export const changeCurrency = (
  currency: Currency,
): { type: CurrencyActionTypes; payload: Currency } => ({
  type: CurrencyActionTypes.ChangeCurrency,
  payload: currency,
});
