import clsx from '@alexis/helpers/clsx';
import React from 'react';

import { getLinkbyPageAndLocale } from '@helpers/webToApp';

import { useSearchParams } from '@hooks/useSearchParams';

import { HomePageType } from '@wegoTypes/homePageType';

import styles from './UseAppButton.module.scss';

interface UseAppButtonProps {
  homePageType: HomePageType;
  isUseAppGreen?: boolean;
  isUseAppWhite?: boolean;
  locale: string;
  translations: { [key: string]: string };
}

const UseAppButton: React.FC<UseAppButtonProps> = ({
  homePageType,
  isUseAppGreen,
  locale,
  translations,
  isUseAppWhite,
}) => {
  const searchParams = useSearchParams();
  const hasBowOnlyParamAsTrue = searchParams.get('bow_only') === 'true';

  if (hasBowOnlyParamAsTrue) return null;

  const handleOnButtonClick = (
    homePageType: HomePageType,
    locale: string,
    searchParams: URLSearchParams,
  ): void => {
    const wegoSourceSearchParam = searchParams.get('wg_source');
    const wegoCampaignSearchParam = searchParams.get('wg_campaign');

    const link = getLinkbyPageAndLocale(
      homePageType,
      locale,
      wegoSourceSearchParam,
      wegoCampaignSearchParam,
    );

    if (!!link) window.open(link, '_blank');
  };

  return (
    <button
      className={clsx(
        styles.button,
        isUseAppGreen && styles.buttonGreen,
        isUseAppWhite && styles.whiteButtonWithGreenBorder,
      )}
      onClick={() => handleOnButtonClick(homePageType, locale, searchParams)}
    >
      {translations.use_app}
    </button>
  );
};

export default UseAppButton;
