import type { CurrentSite } from '@alexis/types/currentSite';
import type { Locale } from '@alexis/types/locale';

const siteConfigMap: SiteConfigMap = {
  // Format:
  // 'domain': [
  //   'POS Country Name',
  //   'Country Code',
  //   'Default Currency Code',
  //   'Default Language Code',
  //   'Supported Language Codes',
  //   'Default City Code'
  // ]

  // Please sort by alphabetical order if adding new POS
  // #A
  'dz.wego.com': ['Algeria', 'DZ', 'DZD', 'fr', 'ar,fr,en', 'ALG'],
  'ao.wego.com': ['Angola', 'AO', 'AOA', 'pt', 'en,fr,pt', 'LAD'],
  'ar.wego.com': ['Argentina', 'AR', 'ARS', 'es', 'en,es', 'BUE'],
  'au.wego.com': ['Australia', 'AU', 'AUD', 'en', 'en', 'SYD'],
  // #B
  'bh.wego.com': ['Bahrain', 'BH', 'BHD', 'ar', 'ar,en', 'BAH'],
  'www.wego.com.bd': ['Bangladesh', 'BD', 'BDT', 'en', 'en', 'DAC'],
  'bw.wego.com': ['Botswana', 'BW', 'BWP', 'en', 'en', 'GBE'],
  'www.wegoviajar.com.br': ['Brazil', 'BR', 'BRL', 'pt', 'en,pt', 'SAO'],
  // #C
  'cm.wego.com': ['Cameroon', 'CM', 'XAF', 'fr', 'en,fr', 'DLA'],
  'www.wegotravel.ca': ['Canada', 'CA', 'CAD', 'en', 'en,fr', 'YTO'],
  'www.wego.cl': ['Chile', 'CL', 'CLP', 'es', 'en,es', 'SCL'],
  'www.wego.cn': ['China', 'CN', 'CNY', 'zh-cn', 'en,zh-cn', 'SHA'],
  'www.wego.com.co': ['Colombia', 'CO', 'COP', 'es', 'en,es', 'BOG'],
  'cd.wego.com': ['Congo', 'CD', 'CDF', 'fr', 'en,fr', 'FIH'],
  'ci.wego.com': ["Cote d'Ivoire", 'CI', 'XOF', 'fr', 'en,fr', 'ABJ'],
  // #E
  'eg.wego.com': ['Egypt', 'EG', 'EGP', 'ar', 'ar,en', 'CAI'],
  'et.wego.com': ['Ethiopia', 'ET', 'ETB', 'en', 'en,ar,fr', 'ADD'],
  // #F
  'www.wego.fr': ['France', 'FR', 'EUR', 'fr', 'en,fr,ar', 'PAR'],
  // #G
  'ga.wego.com': ['Gabon', 'GA', 'XAF', 'fr', 'en,fr', 'LBV'],
  'www.wegoreise.de': ['Germany', 'DE', 'EUR', 'de', 'en,de,ar', 'FRA'],
  'gh.wego.com': ['Ghana', 'GH', 'GHS', 'en', 'en', 'ACC'],
  // #H
  'www.wego.hk': ['Hong Kong', 'HK', 'HKD', 'zh-hk', 'en,zh-hk', 'HKG'],
  // #I
  'www.wego.co.in': ['India', 'IN', 'INR', 'en', 'en', 'DEL'],
  'www.wego.co.id': ['Indonesia', 'ID', 'IDR', 'id', 'en,id', 'JKT'],
  // 'ir.wego.com': ['Iran', 'IR', 'IRR', 'fa', 'fa,en', 'THR'],
  // 'www.wego.ir': ['Iran', 'IR', 'IRR', 'fa', 'fa,en', 'THR'],
  'iq.wego.com': ['Iraq', 'IQ', 'USD', 'ar', 'en,ar', 'BGW'],
  'www.wego.ie': ['Ireland', 'IE', 'EUR', 'en', 'en', 'DUB'],
  'www.wegoviaggio.it': ['Italy', 'IT', 'EUR', 'it', 'en,it', 'ROM'],
  // #J
  'www.wegotravel.jp': ['Japan', 'JP', 'JPY', 'ja', 'en,ja', 'TYO'],
  'jo.wego.com': ['Jordan', 'JO', 'JOD', 'ar', 'ar,en', 'AMM'],
  // #K
  'ke.wego.com': ['Kenya', 'KE', 'KES', 'en', 'en', 'NBO'],
  'kw.wego.com': ['Kuwait', 'KW', 'KWD', 'ar', 'ar,en', 'KWI'],
  // #L
  'lb.wego.com': ['Lebanon', 'LB', 'USD', 'ar', 'ar,en', 'BEY'],
  'www.wego.ly': ['Libya', 'LY', 'LYD', 'ar', 'ar,en', 'TIP'],
  // #M
  'mo.wego.com': ['Macau', 'MO', 'MOP', 'zh-hk', 'en,zh-hk', 'MFM'],
  'www.wego.com.my': ['Malaysia', 'MY', 'MYR', 'en', 'en,ms,zh-cn', 'KUL'],
  'ml.wego.com': ['Mali', 'ML', 'XOF', 'fr', 'en,fr', 'BKO'],
  'mu.wego.com': ['Mauritius', 'MU', 'MUR', 'en', 'en,fr', 'MRU'],
  'www.wego.mx': ['Mexico', 'MX', 'MXN', 'es', 'en,es', 'MEX'],
  'www.wegotravel.ma': ['Morocco', 'MA', 'MAD', 'fr', 'ar,fr,en', 'CAS'],
  'mz.wego.com': ['Mozambique', 'MZ', 'MZN', 'pt', 'en,pt', 'MPM'],
  // #N
  'na.wego.com': ['Namibia', 'NA', 'NAD', 'en', 'en,de', 'WDH'],
  'www.wegoreizen.nl': ['Netherlands', 'NL', 'EUR', 'nl', 'en,nl', 'AMS'],
  'www.wegotravel.co.nz': ['New Zealand', 'NZ', 'NZD', 'en', 'en', 'AKL'],
  'www.wego.com.ng': ['Nigeria', 'NG', 'NGN', 'en', 'en', 'LOS'],
  // #O
  'om.wego.com': ['Oman', 'OM', 'OMR', 'ar', 'ar,en', 'MCT'],
  // #P
  'www.wego.pk': ['Pakistan', 'PK', 'PKR', 'en', 'en', 'KHI'],
  'www.wego.ps': ['Palestine', 'PS', 'USD', 'ar', 'en,ar', '89280'],
  'www.wego.com.ph': ['Philippines', 'PH', 'PHP', 'en', 'en', 'MNL'],
  'www.wegoprodroz.pl': ['Poland', 'PL', 'PLN', 'pl', 'en,pl', 'WAW'],
  'www.wego.com.pt': ['Portugal', 'PT', 'EUR', 'pt', 'en,pt', 'LIS'],
  // #Q
  'www.wego.qa': ['Qatar', 'QA', 'QAR', 'ar', 'ar,en', 'DOH'],
  // #R
  // 'www.wego.com.ru': ['Russia', 'RU', 'RUB', 'ru', 'en,ru', 'MOW'],
  'rw.wego.com': ['Rwanda', 'RW', 'RWF', 'en', 'en,fr', 'KGL'],
  // #S
  'sa.wego.com': ['Saudi Arabia', 'SA', 'SAR', 'ar', 'ar,en', 'JED'],
  'sn.wego.com': ['Senegal', 'SN', 'XOF', 'fr', 'en,fr', 'DKR'],
  'www.wego.com.sg': ['Singapore', 'SG', 'SGD', 'en', 'en,ms,zh-cn', 'SIN'],
  'www.wego.co.za': ['South Africa', 'ZA', 'ZAR', 'en', 'en', 'JNB'],
  'www.wego.co.kr': ['South Korea', 'KR', 'KRW', 'ko', 'en,ko', 'SEL'],
  'www.wego.com.es': ['Spain', 'ES', 'EUR', 'es', 'en,es', 'MAD'],
  'www.wego.lk': ['Sri Lanka', 'LK', 'LKR', 'en', 'en', 'CMB'],
  'www.wego.sd': ['Sudan', 'SD', 'SDG', 'ar', 'ar,en', 'KRT'],
  'www.wegoresa.se': ['Sweden', 'SE', 'SEK', 'sv', 'en,sv', 'STO'],
  'www.wegotravel.ch': ['Switzerland', 'CH', 'CHF', 'de', 'de,en,fr,it', 'ZRH'],
  // 'sy.wego.com': ['Syria', 'SY', 'SYP', 'ar', 'en,ar', 'DAM'],
  // #T
  'www.wego.tw': ['Taiwan', 'TW', 'TWD', 'zh-tw', 'en,zh-tw', 'TPE'],
  'tz.wego.com': ['Tanzania', 'TZ', 'TZS', 'en', 'en,ar', 'DAR'],
  'th.wego.com': ['Thailand', 'TH', 'THB', 'th', 'en,th', 'BKK'],
  'tn.wego.com': ['Tunisia', 'TN', 'TND', 'fr', 'ar,fr,en', 'TUN'],
  'www.wego.com.tr': ['Turkey', 'TR', 'TRY', 'tr', 'en,tr,ar', 'IST'],
  // #U
  'www.wego.ae': ['UAE', 'AE', 'AED', 'ar', 'en,ar', 'DXB'],
  'ug.wego.com': ['Uganda', 'UG', 'UGX', 'en', 'en', 'EBB'],
  'www.wegotravel.co.uk': ['United Kingdom', 'GB', 'GBP', 'en', 'en,ar', 'LON'],
  'www.wego.com': ['United States', 'US', 'USD', 'en', 'en,ru', 'NYC'],
  // #V
  'www.wego.vn': ['Vietnam', 'VN', 'VND', 'vi', 'en,vi', 'SGN'],
  // #Z
  'zm.wego.com': ['Zambia', 'ZM', 'ZMW', 'en', 'en', 'LUN'],
  'zw.wego.com': ['Zimbabwe', 'ZW', 'USD', 'en', 'en', 'HRE'],
  // Please sort by alphabetical order if adding new POS
};

const formatCurrentSite = (hostname: string, currentSite: Array<string>): CurrentSite => {
  return {
    host: hostname,
    countryName: currentSite[0],
    countryCode: currentSite[1],
    currencyCode: currentSite[2],
    defaultLocale: currentSite[3],
    supportedLocales: currentSite[4].split(','),
  };
};

export const getSiteConfigByHostname = (hostname: string): CurrentSite => {
  const productionDomainList = Object.keys(siteConfigMap);
  let currentSiteConfig;

  if (productionDomainList.includes(hostname)) {
    currentSiteConfig = siteConfigMap[hostname];
  } else if (/\wegodev\.localhost$/.test(hostname) || /\wegostaging\.com$/.test(hostname)) {
    const countryCode: string = hostname.substring(0, 2).toUpperCase();

    for (const key in siteConfigMap) {
      const siteConfig: Array<string> = siteConfigMap[key];

      if (countryCode === siteConfig[1]) {
        currentSiteConfig = siteConfig;
      }
    }
  }
  return formatCurrentSite(hostname, currentSiteConfig || siteConfigMap['www.wego.com']);
};

export const sitesConfig: Array<CurrentSite> = Object.entries(siteConfigMap).map<CurrentSite>(
  (siteConfig) => formatCurrentSite(siteConfig[0], siteConfig[1]),
);

// Please sort by alphabetical order if adding new POS
const menaSitesRegex = /^(?:AE|BH|DZ|EG|IQ|JO|KW|LB|LY|MA|OM|PS|QA|SA|SD|SY|TN)$/i;

export const isSiteCodePartOfMENA = (siteCode: string): boolean => {
  return menaSitesRegex.test(siteCode);
};

// locales in alphabetical order based on code.
export const locales: Array<Locale> = [
  { code: 'ar', name: 'العربية' },
  { code: 'de', name: 'Deutsch' },
  { code: 'en', name: 'English' },
  { code: 'es', name: 'Español' },
  { code: 'fa', name: 'فارسی' },
  { code: 'fr', name: 'Français' },
  { code: 'id', name: 'Bahasa Indonesia' },
  { code: 'it', name: 'Italiano' },
  { code: 'ja', name: '日本語' },
  { code: 'ko', name: '한국어' },
  { code: 'ms', name: 'Bahasa Melayu' },
  { code: 'nl', name: 'Nederlands' },
  { code: 'pl', name: 'Polski' },
  { code: 'pt', name: 'Português' },
  { code: 'ru', name: 'Русский' },
  { code: 'sv', name: 'Svenska' },
  { code: 'th', name: 'ไทย' },
  { code: 'tr', name: 'Türkçe' },
  { code: 'vi', name: 'Tiếng Việt' },
  { code: 'zh-cn', name: '简体中文' },
  { code: 'zh-hk', name: '繁體中文' },
  { code: 'zh-tw', name: '繁體中文' },
];

export const getLocaleByUlangParam = (ulangParamValue: string): Locale | undefined => {
  return locales.find((locale) => locale.code === ulangParamValue.toLowerCase())!;
};
