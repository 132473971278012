import { datadogRum } from '@datadog/browser-rum';
import { noop } from 'lodash-es';
import React, { createContext, useCallback, useContext, useMemo, useRef } from 'react';

type DatadogRecord = {
  startTime: number;
  endTime?: number;
  observer?: MutationObserver;
  trackedValue?: string;
};

type StartRecordingActionParams = {
  recordKey: string;
  observer?: MutationObserver;
  trackedValue?: string;
};

type StopRecordingActionParams = {
  actionName: string;
  recordKey: string;
  customData?: Record<string, any>;
};

type RecordChangeActionParams = {
  dd: string;
  type: 'filter' | 'sort';
};

type RecordMountActionParams = { dd: string; actionName: string };

type DatadogProviderValue = {
  startRecordingAction: (prams: StartRecordingActionParams) => void;
  stopRecordingAction: (params: StopRecordingActionParams) => void;
  recordMountAction: (params: RecordMountActionParams) => void;
  recordChangeAction: (params: RecordChangeActionParams) => void;
};

const DatadogContext = createContext<DatadogProviderValue>({
  startRecordingAction: noop,
  stopRecordingAction: noop,
  recordMountAction: noop,
  recordChangeAction: noop,
});

const DatadogProvider = ({ children }: { children?: React.ReactNode }) => {
  const records = useRef<Record<string, DatadogRecord | undefined>>({});

  const startRecordingAction = useCallback(
    ({ recordKey, observer, trackedValue }: StartRecordingActionParams) => {
      // disconnect previous observer
      records.current[recordKey]?.observer?.disconnect();

      records.current[recordKey] = {
        startTime: Date.now(),
        observer,
        trackedValue,
      };
    },
    [],
  );

  const stopRecordingAction = useCallback(
    ({ actionName, recordKey, customData }: StopRecordingActionParams) => {
      const record = records.current[recordKey];

      if (!record || record.endTime) return;

      record.endTime = Date.now();

      const value = record.endTime - record.startTime;

      // console.log('Recorded action:', actionName, value);

      datadogRum.addAction(actionName, { value, ...customData });

      record.observer?.disconnect();
    },
    [],
  );

  const recordMountAction = useCallback(
    ({ dd, actionName }: { dd: string; actionName: string }) => {
      const recordKey = `${dd}:${actionName}`;

      const mutationCallback: MutationCallback = (mutationsList) => {
        const record = records.current[recordKey];

        if (!record) return;

        for (let mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const expectedNode = document.querySelector(`[data-dd="${dd}"]`);

            if (expectedNode && !records.current[recordKey]?.endTime) {
              stopRecordingAction({ recordKey, actionName });
            }
          }
        }
      };

      const observer = new MutationObserver(mutationCallback);

      startRecordingAction({ recordKey, observer });

      records.current[recordKey] = {
        startTime: Date.now(),
        observer,
      };

      const containerNode = document.body;

      observer.observe(containerNode, { childList: true, subtree: true });
    },
    [],
  );

  const recordChangeAction = useCallback(({ dd, type }: RecordChangeActionParams) => {
    const actionName = `change ${type}`;
    const recordKey = `${dd}:${actionName}`;
    records.current[recordKey]?.observer?.disconnect();
    records.current[recordKey] = undefined;

    const containerNode = document.body;

    const mutationCallback: MutationCallback = (mutationsList) => {
      const record = records.current[recordKey];

      if (!record) return;

      if (record.endTime) {
        record.observer?.disconnect();
        return;
      }

      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const expectedNode = document.querySelector(`[data-dd="${dd}"]`);

          if (!expectedNode) {
            return;
          }

          const typeValue = expectedNode.getAttribute(`data-dd-${type}`);

          if (typeValue === record.trackedValue) {
            return;
          }

          stopRecordingAction({
            recordKey,
            actionName,
          });
        }
      }
    };

    const observer = new MutationObserver(mutationCallback);

    startRecordingAction({
      recordKey,
      observer,
      trackedValue:
        document.querySelector(`[data-dd="${dd}"]`)?.getAttribute(`data-dd-${type}`) || undefined,
    });

    observer.observe(containerNode, {
      childList: true,
      subtree: true,
    });
  }, []);

  const value = useMemo(
    () => ({
      startRecordingAction,
      stopRecordingAction,
      recordMountAction,
      recordChangeAction,
    }),
    [recordChangeAction, startRecordingAction, stopRecordingAction],
  );

  return <DatadogContext.Provider value={value}>{children}</DatadogContext.Provider>;
};

export default DatadogProvider;

export const useDatadog = () => useContext(DatadogContext);
