export const getFlightCashback = (brandedFare: BrandedFare): FlightCashback | undefined => {
  const cashbackTag = brandedFare.tags.find((tag) => hasFlightCashback(tag));
  return cashbackTag?.attributes;
};

export const getCashbackFromFlightFares = (
  flightFares: FlightFare[],
): FlightCashback | undefined => {
  let cashback;

  flightFares.find((fare) =>
    fare.tags.find((tag) => {
      if (hasFlightCashback(tag)) {
        cashback = tag.attributes;
        return true;
      }
      return false;
    }),
  );

  return cashback;
};

export const hasFlightCashback = (tag: { code: string; id: number }): boolean => {
  return tag.code === 'CASHBACK' || tag.id === 6;
};

export const isWegoShopcashEnabled = (siteCode: string): boolean => {
  return ['EG', 'AE'].includes(siteCode);
};
