import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import { sessionStorageGet, sessionStorageSave } from '@alexis/helpers/sessionStorage';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getExperimentDataWithConnectAPI } from '@apis/userCentricService';

import { changeCoreConfigs } from '@redux/actions/coreConfigActions';
import {
  getApiBaseUrlState,
  getCurrencyState,
  getCurrentSiteState,
  getLocaleState,
} from '@redux/selectors';

const CORE_CONFIG_SESSION_KEY = 'core_config_session';

/**
 *
 * @param wegoAnalyticsClientId wego client id
 */
const useCoreConfig = (wegoAnalyticsClientId: string | undefined) => {
  const apiBaseUrl: string = useSelector(getApiBaseUrlState);
  const locale: string = useSelector(getLocaleState);
  const currentSite: CurrentSite = useSelector(getCurrentSiteState);
  const currency: Currency = useSelector(getCurrencyState);

  const dispatch = useDispatch();

  async function getCoreConfig(clientId: string, cancelToken: CancelToken) {
    try {
      const response = await getExperimentDataWithConnectAPI(
        apiBaseUrl,
        clientId,
        'MWEB',
        currentSite.countryCode,
        locale,
        'MOBILE_WEB_APP',
        'MOBILE',
        currency.code,
        'coreConfigs',
        cancelToken,
      );

      const coreConfigs = response.data.coreConfigs;
      dispatch(changeCoreConfigs(coreConfigs));
      sessionStorageSave(CORE_CONFIG_SESSION_KEY, coreConfigs);
    } catch (error: unknown) {
      throw new Error('A/B testing API failed - mWeb');
    }
  }

  useEffect(() => {
    const { token }: CancelTokenSource = axios.CancelToken.source();
    const currentCoreConfigSession: Array<CoreConfig> | null =
      sessionStorageGet(CORE_CONFIG_SESSION_KEY);
    if (!!currentCoreConfigSession) {
      dispatch(changeCoreConfigs(currentCoreConfigSession));
    } else if (wegoAnalyticsClientId) {
      getCoreConfig(wegoAnalyticsClientId, token);
    }
  }, [wegoAnalyticsClientId]);
};

export default useCoreConfig;
