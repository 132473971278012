import { appendSearchParams } from '@alexis/helpers/searchParams';
import axios from 'axios';
import type { CancelToken } from 'axios';
import type { FlightLocation } from 'flightLocation';

import type { NearbyLocationsResponse } from '@wegoTypes/apis/Flight/nearbyLocationResponse';
import type { FlightPopularLocation } from '@wegoTypes/flightPopularLocation';

export const getFlightPopularLocations = async (
  apiBaseUrl: string,
  currentSite: CurrentSite,
  locale: string,
  isDeparture: boolean,
  cancelToken: CancelToken,
  perPageCount?: number,
): Promise<Array<FlightPopularLocation>> => {
  const searchParams = {
    locale,
    site_code: currentSite.countryCode,
    ...(isDeparture && { city_type: 'departure' }),
    ...(perPageCount && { per_page: perPageCount }),
  };

  const url = appendSearchParams(`${apiBaseUrl}/places/cities/popular/flights`, searchParams);

  const response = await axios.get<Array<FlightPopularLocation>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getLocation = async (
  apiBaseUrl: string,
  locale: string,
  code: string,
  cancelToken: CancelToken,
): Promise<FlightLocation> => {
  const isCity = code.at(0) === 'c';

  const searchParams = {
    locale,
    ...(isCity ? { 'city_codes[]': code.slice(1) } : { 'airport_codes[]': code }),
  };

  const url = appendSearchParams(`${apiBaseUrl}/places/v1/locations`, searchParams);

  const response = await axios.post<{
    cities: Array<FlightLocation>;
    airports: Array<FlightLocation>;
  }>(url, {}, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    return response.data[isCity ? 'cities' : 'airports'][0];
  }

  throw new Error(response.statusText);
};

export const getNearestAirports = async (
  apiBaseUrl: string,
  locale: string,
  latitude: number,
  longitude: number,
  cancelToken: CancelToken,
): Promise<Array<FlightLocation>> => {
  const url = `${apiBaseUrl}/places/search/nearest?locale=${locale}&latitude=${latitude}&longitude=${longitude}`;

  const response = await axios.get<Array<FlightLocation>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getLocations = async (
  apiBaseUrl: string,
  currentSite: CurrentSite,
  locale: string,
  query: string,
  cancelToken: CancelToken,
): Promise<Array<FlightLocation>> => {
  const url = `${apiBaseUrl}/places/search?site_code=${currentSite.countryCode}&locale=${locale}&query=${query}&min_airports=1`;

  const response = await axios.get<Array<FlightLocation>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export async function getFlightsAutocomplete(
  apiBaseUrl: string,
  currentSiteCode: string,
  locale: string,
  query: string,
  searchFor: 'origin' | 'destination',
  cancelToken: CancelToken,
): Promise<Array<FlightLocation>> {
  const url = `${apiBaseUrl}/autocomplete/flights/v1/search?site_code=${currentSiteCode}&search_for=${searchFor}&locale=${locale}&query=${query}&min_airports=1`;

  const response = await axios.get<Array<FlightLocation>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  return [];
}

export const getNearbyAirports = async (
  apiBaseUrl: string,
  siteCode: string,
  locale: string,
  departureCode: string | undefined,
  arrivalCode: string | undefined,
  clientId: string | undefined,
  sessionId: string | undefined,
  cancelToken: CancelToken,
): Promise<NearbyLocationsResponse> => {
  const url = appendSearchParams(`${apiBaseUrl}/v3/metasearch/flights/nearbyLocations`, {
    locale,
    siteCode,
    departureCode,
    arrivalCode,
  });

  const response = await axios.get<NearbyLocationsResponse>(url, {
    cancelToken,
    withCredentials: true,
    headers: {
      'X-Wego-Client-Id': clientId || '',
      'X-Wego-Session-Id': sessionId || '',
    },
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getLocationDetails = async (
  apiBaseUrl: string,
  locale: string,
  codes: string[],
  cancelToken: CancelToken,
): Promise<Array<FlightLocation>> => {
  let cityCodes: string[] = [];
  let airportCodes: string[] = [];
  codes.forEach((code) => {
    const isCity = code.at(0) === 'c';
    if (isCity) {
      cityCodes.push(code.slice(1));
    } else {
      airportCodes.push(code);
    }
  });
  const searchParams = {
    locale,
    city_codes: cityCodes,
    airport_codes: airportCodes,
  };

  let url = appendSearchParams(`${apiBaseUrl}/places/v1/locations`, searchParams);

  const response = await axios.post<{
    cities: Array<FlightLocation>;
    airports: Array<FlightLocation>;
  }>(url, {}, { cancelToken, withCredentials: true });

  if (response.status === 200) {
    const airports = response.data.airports ?? [];
    const cities = response.data.cities ?? [];
    return airports.concat(cities);
  }

  throw new Error(response.statusText);
};
