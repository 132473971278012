import { localStorageGet, localStorageSave, localStorageClear } from '@alexis/helpers/localStorage';
import type { User } from '@alexis/types/user';

import { UserActionTypes } from '../actions/types';

const userLocalStorageKey = 'user';

const user = localStorageGet<User>(userLocalStorageKey);

const initialState: User | null = user;

export default function (
  state: User | null = initialState,
  action: { type: UserActionTypes; payload: User },
): User | null {
  switch (action.type) {
    case UserActionTypes.ChangeUser: {
      const currentCurrency = action.payload;
      localStorageSave(userLocalStorageKey, currentCurrency);
      return currentCurrency;
    }
    case UserActionTypes.ResetUser: {
      localStorageClear(userLocalStorageKey);
      return null;
    }
    default:
      return state;
  }
}
