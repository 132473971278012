import clsx from '@alexis/helpers/clsx';
import React from 'react';

import HeadsetIcon from '@icons/headset.svg';
import MultipleCardIcon from '@icons/multiple_card.svg';
import PercentVoucherIcon from '@icons/percent_voucher.svg';

import Image from '../../Image';
import styles from './Propositions.module.scss';

type PropositionsProp = {
  isBowOnly: boolean;
  translations: { [key: string]: string };
};

const Propositions: React.FC<PropositionsProp> = ({ translations, isBowOnly }) => {
  return (
    <div className={styles.propositions}>
      <div className={clsx(styles.proposition, !isBowOnly && styles.itemsCenter)}>
        <PropositionIcon
          BowOnlyIcon={PercentVoucherIcon}
          isBowOnly={isBowOnly}
          imageUrl='https://zen.wego.com/cdn-cgi/image/width=300,height=300/web/illustrations/look-no-further.png'
        />

        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_flight_deals_title}
          defaultText={translations.onboarding1}
          contentType='heading'
        />
        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_flight_deals_description}
          defaultText={translations.search_deals}
          contentType='description'
        />
      </div>
      <div className={clsx(styles.proposition, !isBowOnly && styles.itemsCenter)}>
        <PropositionIcon
          BowOnlyIcon={PercentVoucherIcon}
          isBowOnly={isBowOnly}
          imageUrl='https://zen.wego.com/cdn-cgi/image/width=300,height=300/web/illustrations/shop-with-confidence.png'
        />

        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_hotel_deals_title}
          defaultText={translations.shop_with_confidence}
          contentType='heading'
        />
        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_hotel_deals}
          defaultText={translations.no_hidden_fees}
          contentType='description'
        />
      </div>
      <div className={clsx(styles.proposition, !isBowOnly && styles.itemsCenter)}>
        <PropositionIcon
          BowOnlyIcon={MultipleCardIcon}
          isBowOnly={isBowOnly}
          imageUrl='https://zen.wego.com/cdn-cgi/image/width=300,height=300/web/illustrations/pay-the-way-you-want.png'
        />

        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_payments_title}
          defaultText={translations.pay_the_way_you_want}
          contentType='heading'
        />
        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_payments_description}
          defaultText={translations.your_preferred_methods}
          contentType='description'
        />
      </div>
      <div className={clsx(styles.proposition, !isBowOnly && styles.itemsCenter)}>
        <PropositionIcon
          BowOnlyIcon={HeadsetIcon}
          isBowOnly={isBowOnly}
          imageUrl='https://zen.wego.com/cdn-cgi/image/width=300,height=300/web/illustrations/instant-booking.png'
        />

        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_customer_service_title}
          defaultText={translations.instant_booking}
          contentType='heading'
        />
        <PropositionContent
          isBowOnly={isBowOnly}
          bowOnlyText={translations.usp_customer_service_description}
          defaultText={translations.for_selected_sellers}
          contentType='description'
        />
      </div>
    </div>
  );
};

export default Propositions;

type PropositionIconProp = {
  BowOnlyIcon: React.FC<React.SVGAttributes<SVGElement>>;
  isBowOnly: boolean;
  imageUrl: string;
};

type PropositionTextProp = {
  isBowOnly: boolean;
  bowOnlyText: string;
  defaultText: string;
  contentType: 'heading' | 'description';
};

const PropositionContent: React.FC<PropositionTextProp> = ({
  isBowOnly,
  defaultText,
  bowOnlyText,
  contentType,
}) => {
  const isHeading = contentType === 'heading';

  return (
    <div
      className={clsx(
        isHeading ? styles.heading : styles.subHeading,
        !isBowOnly && styles.textCenter,
      )}
    >
      {isBowOnly ? bowOnlyText : defaultText}
    </div>
  );
};
export const PropositionIcon: React.FC<PropositionIconProp> = ({
  BowOnlyIcon,
  isBowOnly,
  imageUrl,
}) => {
  return isBowOnly ? (
    <div className={styles.bowOnlyContainer}>
      <BowOnlyIcon />
    </div>
  ) : (
    <Image className={styles.image} isLazy imageUrl={imageUrl} />
  );
};
