import { localStorageClear, localStorageGet } from '@alexis/helpers/localStorage';
import { AuthContext } from '@vendor/react-oauth2-code-pkce';
import { isNil } from 'lodash-es';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';

import { NEW_AUTH } from '@constants/localStorage';

import { safeJsonParse } from '@helpers/safeJsonParse';

import { useSearchParams } from '@hooks/useSearchParams';

import { getCoreConfigState } from '@redux/selectors';

export const useIsAuthNew = () => {
  const coreConfigs = useSelector(getCoreConfigState);
  const auth = useContext(AuthContext);
  const searchParams = useSearchParams();

  const isAuthNewParams = searchParams.get('newAuth');
  const isAuthNew =
    coreConfigs?.find((coreConfig) => coreConfig.key === 'mweb_universal_login')?.value === '1';
  // TODO: To be remove once mweb_login_callback_route experiment is done
  const isUsingNewLoginCallbackRoute =
    coreConfigs?.find((coreConfig) => coreConfig.key === 'mweb_login_callback_route')?.value ===
    '1';
  const currentRoute = window.location.pathname;

  // used to detect if the user has used new login
  const usedNewLogin: boolean = localStorageGet(NEW_AUTH) === true;

  const login = useCallback(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('re-authenticate');

    // Extract the locale from currentRoute if present
    const redirectPath = () => {
      if (isUsingNewLoginCallbackRoute) {
        const pathWithoutLocale = currentRoute.replace(/^\/[a-z]{2}(?:\/|$)/, '/');
        return pathWithoutLocale.includes('/login-callback')
          ? pathWithoutLocale + url.search
          : '/login-callback' + pathWithoutLocale + url.search;
      }

      return currentRoute + url.search;
    };

    auth.logIn(undefined, {
      redirect_path: redirectPath(),
    });
  }, [auth.logIn, currentRoute]);

  const logout = useCallback(() => {
    localStorageClear(NEW_AUTH);
    auth.logOut(undefined, undefined, {
      post_logout_redirect_path: currentRoute + window.location.search,
    });
  }, [auth.logOut, currentRoute]);

  // coreConfigsLoaded, this is temporary only used for A/B testing
  return {
    isAuthNew: isNil(isAuthNewParams) ? isAuthNew : safeJsonParse(isAuthNewParams, false),
    auth,
    login,
    logout,
    coreConfigsLoaded: coreConfigs?.length > 0,
    usedNewLogin,
  };
};
