export const notUndefinedAndNull = <T>(value: T | undefined | null): value is T => {
  return value !== undefined && value !== null;
};

export const convertListToHashMap = (list: Array<any>, key: string): any => {
  return list.reduce((previousMap: any, item: any) => {
    previousMap[item[key]] = item;
    return previousMap;
  }, {});
};

export const uniqBy = <T>(list: Array<T>, key: string): Array<T> => {
  return list.reduce((accumulator: Array<any>, currentValue: any) => {
    if (!accumulator.some((accumulatorValue: any) => currentValue[key] === accumulatorValue[key])) {
      accumulator.push(currentValue);
    }
    return accumulator;
  }, []);
};

export const isArrayIncludesAnyValue = (array: string[], values: string[]) =>
  values.some((v) => array.includes(v));
