import { getLocalDate, numberOfNights } from '@alexis/helpers/date';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';

import { LegDetails } from '@components/flightBooking/BookingHistoryDetails/FlightBookingDetailsResponse';

import { HOTEL_SORT_TYPE } from '@constants/hotels';
import { PROD_TS_CODES, STAGING_TS_CODES } from '@constants/tsCodes';

import { HotelLandmark } from '@wegoTypes/Hotel/hotelLandmarks';
import type { HotelLocation } from '@wegoTypes/Hotel/hotelLocation';
import { HotelMetaSearchHotelReview } from '@wegoTypes/Hotel/hotelMetaSearchHotelReview';
import { HotelSummary } from '@wegoTypes/Hotel/hotelSummary';
import { HotelViewModel } from '@wegoTypes/Hotel/HotelViewModel';
import { RateViewModel } from '@wegoTypes/Hotel/rateViewModel';
import { HotelSearchResultSearch } from '@wegoTypes/Hotel/Search/hotelSearchResultSearch';
import { SimilarHotel } from '@wegoTypes/Hotel/similarHotel';
import { MetasearchHotelRate } from '@wegoTypes/Metasearch/Hotels/metasearchHotelRate';
import { MetasearchHotelRatePrice } from '@wegoTypes/Metasearch/Hotels/metasearchHotelRatePrice';
import { MetasearchHotelRateProvider } from '@wegoTypes/Metasearch/Hotels/metasearchHotelRateProvider';
import { MiniHotel } from '@wegoTypes/miniHotel';

import { HotelSortType } from '../types/Hotel/hotelSortType';
import { LocationType } from '../types/locationType';
import { convertHotelPriceToPrice } from './price';

export function isAirbnb(propertyTypeId: number): boolean {
  return propertyTypeId === 39;
}

export function sortHotelRates<
  T extends { price: MetasearchHotelRatePrice; provider: MetasearchHotelRateProvider },
>(rates: Array<T>, sortOrder: 'asc' | 'desc', isIncludingTaxOrFee: boolean): Array<T> {
  return [...rates].sort((aRate: T, bRate: T) => {
    const amountA = convertHotelPriceToPrice(aRate.price, false, isIncludingTaxOrFee);
    const amountB = convertHotelPriceToPrice(bRate.price, false, isIncludingTaxOrFee);
    const totalRate = amountA.amount - amountB.amount;
    if (totalRate === 0) {
      if (aRate.provider.directBooking && !bRate.provider.directBooking) {
        return -1;
      } else if (!aRate.provider.directBooking && bRate.provider.directBooking) {
        return 1;
      } else {
        return bRate.price.ecpc - aRate.price.ecpc;
      }
    }
    return sortOrder === 'asc' ? amountA.amount - amountB.amount : amountB.amount - amountA.amount;
  });
}

export function sortHotelViewModelBasedOnScore(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: 'asc' | 'desc' = 'desc',
): Array<HotelViewModel> {
  // Sorting based on score is always in descending because the higher the score the better the hotel.
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      if (sortOrder === 'asc') {
        return aHotelViewModel.score - bHotelViewModel.score;
      }
      return bHotelViewModel.score - aHotelViewModel.score;
    },
  );
}

export function sortHotelViewModelBasedOnPrice(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: 'asc' | 'desc',
  isIncludingTaxOrFee: boolean,
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      if (isIncludingTaxOrFee) {
        const aHotelRateWithTax = aHotelRate.price.taxInclusive
          ? aHotelRate.price.amount
          : aHotelRate.price.amount + aHotelRate.price.taxAmount;
        const bHotelRateWithTax = bHotelRate.price.taxInclusive
          ? bHotelRate.price.amount
          : bHotelRate.price.amount + bHotelRate.price.taxAmount;

        const totalHotelRateWithTax =
          sortOrder === 'asc'
            ? aHotelRateWithTax - bHotelRateWithTax
            : bHotelRateWithTax - aHotelRateWithTax;
        // Target BoW to first place if there is equal price
        if (totalHotelRateWithTax === 0 && aHotelRate.provider.directBooking) {
          return -1;
        }

        if (totalHotelRateWithTax === 0 && !bHotelRate.provider.directBooking) {
          return 0;
        }

        return totalHotelRateWithTax;
      } else {
        const aHotelRateWithoutTax = aHotelRate.price.taxInclusive
          ? aHotelRate.price.amount - aHotelRate.price.taxAmount
          : aHotelRate.price.amount;
        const bHotelRateWithoutTax = bHotelRate.price.taxInclusive
          ? bHotelRate.price.amount - bHotelRate.price.taxAmount
          : bHotelRate.price.amount;

        const totalHotelRateWithoutTax =
          sortOrder === 'asc'
            ? aHotelRateWithoutTax - bHotelRateWithoutTax
            : bHotelRateWithoutTax - aHotelRateWithoutTax;
        // Target BoW to first place if there is equal price
        if (totalHotelRateWithoutTax === 0 && aHotelRate.provider.directBooking) {
          return -1;
        }

        if (totalHotelRateWithoutTax === 0 && !bHotelRate.provider.directBooking) {
          return 0;
        }

        return totalHotelRateWithoutTax;
      }
    },
  );
}

export function sortHotelViewModelBasedOnDiscount(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: 'asc' | 'desc' = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      const aHotelRateDiscountPercentage =
        (aHotelRate.usualPrice?.discountToUsualAmount || 0) * 100;
      const bHotelRateDiscountPercentage =
        (bHotelRate.usualPrice?.discountToUsualAmount || 0) * 100;

      if (sortOrder === 'asc') {
        return aHotelRateDiscountPercentage - bHotelRateDiscountPercentage;
      }
      return bHotelRateDiscountPercentage - aHotelRateDiscountPercentage;
    },
  );
}

export function sortHotelViewModelBasedOnSaving(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: 'asc' | 'desc' = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelRate: RateViewModel = aHotelViewModel.filteredRates[0];
      const bHotelRate: RateViewModel = bHotelViewModel.filteredRates[0];

      const aHotelRateSavings = aHotelRate.usualPrice
        ? aHotelRate.usualPrice.usualAmountUsd -
          (aHotelRate.price.taxInclusive
            ? aHotelRate.price.amountUsd
            : aHotelRate.price.amountUsd + aHotelRate.price.taxAmountUsd)
        : 0;
      const bHotelRateSavings = bHotelRate.usualPrice
        ? bHotelRate.usualPrice.usualAmountUsd -
          (bHotelRate.price.taxInclusive
            ? bHotelRate.price.amountUsd
            : bHotelRate.price.amountUsd + bHotelRate.price.taxAmountUsd)
        : 0;

      if (sortOrder === 'asc') {
        return aHotelRateSavings - bHotelRateSavings;
      }
      return bHotelRateSavings - aHotelRateSavings;
    },
  );
}

export function sortHotelViewModelBasedOnReviewScore(
  hotelViewModels: Array<HotelViewModel>,
  reviewerGroup: 'ALL' | 'BUSINESS' | 'COUPLE' | 'FAMILY' | 'SOLO',
  sortOrder: 'asc' | 'desc' = 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelAllReviewerGroup: HotelMetaSearchHotelReview | undefined =
        aHotelViewModel.hotel.reviews.find((review) => review.reviewerGroup === reviewerGroup);
      const bHotelAllReviewerGroup: HotelMetaSearchHotelReview | undefined =
        bHotelViewModel.hotel.reviews.find((review) => review.reviewerGroup === reviewerGroup);

      if (!aHotelAllReviewerGroup && !!bHotelAllReviewerGroup) {
        return 1;
      } else if (!!aHotelAllReviewerGroup && !bHotelAllReviewerGroup) {
        return -1;
      } else if (!aHotelAllReviewerGroup && !bHotelAllReviewerGroup) {
        return 0;
      } else {
        if (sortOrder === 'asc') {
          return aHotelAllReviewerGroup!.score - bHotelAllReviewerGroup!.score;
        }
        return bHotelAllReviewerGroup!.score - aHotelAllReviewerGroup!.score;
      }
    },
  );
}

export function sortHotelBasedOnLocation(
  hotelViewModels: Array<HotelViewModel>,
  locationLat: number,
  locationLong: number,
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceToLocation = getDistanceInKmBetweenTwoPoints(
        locationLat,
        locationLong,
        aHotelViewModel.hotel.latitude,
        aHotelViewModel.hotel.longitude,
      );
      const bHotelDistanceToLocation = getDistanceInKmBetweenTwoPoints(
        locationLat,
        locationLong,
        bHotelViewModel.hotel.latitude,
        bHotelViewModel.hotel.longitude,
      );

      if (aHotelDistanceToLocation === 0 && bHotelDistanceToLocation > 0) {
        return 1;
      } else if (aHotelDistanceToLocation > 0 && bHotelDistanceToLocation === 0) {
        return -1;
      } else if (aHotelDistanceToLocation === 0 && bHotelDistanceToLocation === 0) {
        return 0;
      } else {
        return aHotelDistanceToLocation - bHotelDistanceToLocation;
      }
    },
  );
}

export function sortHotelViewModelBasedOnNearestTo(
  hotelViewModels: Array<HotelViewModel>,
  type: 'distanceToCityCentre' | 'distanceToNearestAirport' | 'distanceToSearchLocation',
  sortOrder: 'asc' | 'desc' = 'asc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceTo = aHotelViewModel.hotel[type];
      const bHotelDistanceTo = bHotelViewModel.hotel[type];

      if (aHotelDistanceTo === undefined) return 1;
      if (bHotelDistanceTo === undefined) return -1;

      return sortOrder === 'asc'
        ? aHotelDistanceTo - bHotelDistanceTo
        : bHotelDistanceTo - aHotelDistanceTo;
    },
  );
}

export function sortHotelViewModelBasedOnStar(
  hotelViewModels: Array<HotelViewModel>,
  sortOrder: 'asc' | 'desc',
): Array<HotelViewModel> {
  return [...hotelViewModels].sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelStar = aHotelViewModel.hotel.star;
      const bHotelStar = bHotelViewModel.hotel.star;

      if (aHotelStar === undefined) return 1;
      if (bHotelStar === undefined) return -1;
      return sortOrder === 'asc' ? aHotelStar - bHotelStar : bHotelStar - aHotelStar;
    },
  );
}

export function sortAndMapHotelViewModelNearestToLandmark(
  hotelViewModels: Array<HotelViewModel>,
  landmark?: HotelLandmark,
): Array<HotelViewModel> {
  if (!landmark) return hotelViewModels;

  const hotelViewModelWithDistance = hotelViewModels.map((hotelViewModel) => {
    const distanceToLandmark = getDistanceInKmBetweenTwoPoints(
      landmark.latitude,
      landmark.longitude,
      hotelViewModel.hotel.latitude,
      hotelViewModel.hotel.longitude,
    );
    return {
      ...hotelViewModel,
      landmark: {
        distance: distanceToLandmark,
        name: landmark.name,
      },
    };
  });

  const result = hotelViewModelWithDistance.sort(
    (aHotelViewModel: HotelViewModel, bHotelViewModel: HotelViewModel) => {
      const aHotelDistanceToLandmark = aHotelViewModel.landmark?.distance;
      const bHotelDistanceToLandmark = bHotelViewModel.landmark?.distance;

      if (aHotelDistanceToLandmark === undefined && bHotelDistanceToLandmark !== undefined) {
        return 1;
      } else if (
        aHotelDistanceToLandmark !== undefined &&
        !bHotelDistanceToLandmark === undefined
      ) {
        return -1;
      } else if (aHotelDistanceToLandmark === undefined && bHotelDistanceToLandmark === undefined) {
        return 0;
      } else {
        return aHotelDistanceToLandmark! - bHotelDistanceToLandmark!;
      }
    },
  );
  return result;
}

// https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
export function getDistanceInKmBetweenTwoPoints(
  latitudeA: number,
  longitudeA: number,
  latitudeB: number,
  longitudeB: number,
): number {
  const p = 0.017453292519943295; // Math.PI / 180Í
  const c = Math.cos;
  const a =
    0.5 -
    c((latitudeB - latitudeA) * p) / 2 +
    (c(latitudeA * p) * c(latitudeB * p) * (1 - c((longitudeB - longitudeA) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
}

export function isRateWithinRange(
  rateViewModel: RateViewModel,
  minPriceUsd: number,
  maxPriceUsd: number,
  showTotalPrice: boolean,
  isIncludingTaxOrFee: boolean,
): boolean {
  const { taxInclusive, amountUsd, totalAmountUsd, taxAmountUsd, totalTaxAmountUsd } =
    rateViewModel.price;

  let toBeCalculatedAmountUsd = taxInclusive ? amountUsd - taxAmountUsd : amountUsd;
  let toBeCalculatedTaxAmountUsd = taxAmountUsd;

  if (showTotalPrice) {
    toBeCalculatedAmountUsd = taxInclusive ? totalAmountUsd - totalTaxAmountUsd : totalAmountUsd;
    toBeCalculatedTaxAmountUsd = totalTaxAmountUsd;
  }

  let calculatedAmountUsd = toBeCalculatedAmountUsd;

  if (isIncludingTaxOrFee) {
    calculatedAmountUsd = toBeCalculatedAmountUsd + toBeCalculatedTaxAmountUsd;
  }

  return calculatedAmountUsd >= minPriceUsd && calculatedAmountUsd <= maxPriceUsd;
}

export function filterHotelViewModelBasedOnFilters(
  hotelViewModels: Array<HotelViewModel>,
  hotelNameSearchQuery: string,
  priceOptions: PriceOptions,
  isTodayDeals: boolean,
  selectedUsdPrices: Array<number>,
  selectedReviewScores: Array<number>,
  selectedAmenityIds: Array<number>,
  selectedBookingOptions: Array<string>,
  selectedBrandIds: Array<number>,
  selectedChainIds: Array<number>,
  selectedDistrictIds: Array<number>,
  selectedPropertyTypeIds: Array<number>,
  selectedRateAmenityIds: Array<number>,
  selectedReviewerGroups: Array<string>,
  selectedStarIds: Array<number>,
  selectedRateProviderCodes: Array<string>,
): Array<HotelViewModel> {
  return hotelViewModels.filter((hotelViewModel) => {
    const filteredRates = hotelViewModel.rates.filter((rate) => {
      const isTodayDealsFilter = isTodayDeals ? !!rate.usualPrice : true;

      const bookingOptionsFilter =
        selectedBookingOptions.length > 0
          ? selectedBookingOptions.some(
              (selectedBookingOption) =>
                (selectedBookingOption === 'wego' && rate.provider!.directBooking) ||
                (selectedBookingOption === 'hotels' && rate.provider!.isHotelWebsite) ||
                (selectedBookingOption === 'ota' && rate.provider.type === 'OTA'),
            )
          : true;

      const usdPricesFilter =
        selectedUsdPrices.length === 2
          ? isRateWithinRange(
              rate,
              selectedUsdPrices[0],
              selectedUsdPrices[1],
              priceOptions.showTotalPrice,
              priceOptions.isIncludingTaxOrFee,
            )
          : true;

      const rateAmenityIdsFilter =
        selectedRateAmenityIds.length > 0
          ? selectedRateAmenityIds.every((selectedRateAmenityId) =>
              rate.rateAmenityIds.includes(selectedRateAmenityId),
            )
          : true;

      const rateProviderCodesFilter =
        selectedRateProviderCodes.length > 0
          ? selectedRateProviderCodes.some(
              (selectedRateProviderCode) =>
                rate.provider.code.toLowerCase() === selectedRateProviderCode.toLowerCase(),
            )
          : true;

      return (
        isTodayDealsFilter &&
        bookingOptionsFilter &&
        usdPricesFilter &&
        rateAmenityIdsFilter &&
        rateProviderCodesFilter
      );
    });

    hotelViewModel.filteredRates = sortHotelRates(
      filteredRates,
      'asc',
      priceOptions.isIncludingTaxOrFee,
    );

    let reviewScoreFilter = true;
    if (
      selectedReviewScores.length === 2 &&
      !(selectedReviewScores[0] === -1 && selectedReviewScores[1] === 101)
    ) {
      const allReviewerGroup = hotelViewModel.hotel.reviews[0];

      if (selectedReviewScores[0] === 0) {
        reviewScoreFilter = !!allReviewerGroup
          ? allReviewerGroup.score >= selectedReviewScores[0] &&
            allReviewerGroup.score < selectedReviewScores[1]
          : isAirbnb(hotelViewModel.hotel.propertyTypeId)
          ? true
          : false;
      } else {
        reviewScoreFilter =
          !!allReviewerGroup &&
          allReviewerGroup.score > selectedReviewScores[0] &&
          allReviewerGroup.score < selectedReviewScores[1];
      }
    }

    const reviewerGroupsFilter =
      selectedReviewerGroups.length > 0
        ? selectedReviewerGroups.some((selectedReviewerGroup) => {
            const reviewerGroup = hotelViewModel.hotel.reviews.find(
              (review) => review.reviewerGroup === selectedReviewerGroup,
            );

            return !!reviewerGroup && reviewerGroup.score >= 80 && reviewerGroup.count >= 100;
          })
        : true;

    const hotelNameSearchQueryFilter =
      hotelNameSearchQuery.length > 0
        ? hotelViewModel.hotel.name.toLowerCase().includes(hotelNameSearchQuery.toLowerCase())
        : true;
    const starIdsFilter =
      selectedStarIds.length > 0
        ? selectedStarIds.some((selectedStarId) => hotelViewModel.hotel.star === selectedStarId)
        : true;
    const propertyTypeIdsFilter =
      selectedPropertyTypeIds.length > 0
        ? selectedPropertyTypeIds.some(
            (selectedPropertyTypeId) =>
              hotelViewModel.hotel.propertyTypeId === selectedPropertyTypeId,
          )
        : true;
    const districtIdsFilter =
      selectedDistrictIds.length > 0
        ? selectedDistrictIds.some(
            (selectedDistrictId) => hotelViewModel.hotel.districtId === selectedDistrictId,
          )
        : true;
    const amenityIdsFilter =
      selectedAmenityIds.length > 0
        ? selectedAmenityIds.every((selectedAmenityId) =>
            hotelViewModel.hotel.amenityIds.includes(selectedAmenityId),
          )
        : true;
    const chainIdsFilter =
      selectedChainIds.length > 0
        ? selectedChainIds.some(
            (selectedChainId) => hotelViewModel.hotel.chainId === selectedChainId,
          )
        : true;
    const brandIdsFilter =
      selectedBrandIds.length > 0
        ? selectedBrandIds.some(
            (selectedBrandId) => hotelViewModel.hotel.brandId === selectedBrandId,
          )
        : true;

    return (
      hotelNameSearchQueryFilter &&
      filteredRates.length > 0 &&
      starIdsFilter &&
      reviewScoreFilter &&
      reviewerGroupsFilter &&
      propertyTypeIdsFilter &&
      districtIdsFilter &&
      amenityIdsFilter &&
      chainIdsFilter &&
      brandIdsFilter
    );
  });
}

/**
 *
 * @param guestRooms
 * @returns 2:0-1:12:12-1
 */
export function convertGuestRoomsToSearchParam(guestRooms: Array<GuestRoom>): string {
  return guestRooms.reduce((accumulator: string, currentValue: GuestRoom) => {
    return `${accumulator.length > 0 ? `${accumulator}-` : ''}${currentValue.adultsCount}${
      currentValue.childrenAges.length > 0 ? `:${currentValue.childrenAges.join(':')}` : ''
    }`;
  }, '');
}

/**
 *
 * @param guestSearchParam 2:0-1:12:12-1
 * @returns
 */
export function convertGuestSearchParamToGuestRooms(guestSearchParam: string): Array<GuestRoom> {
  const guestRoomsString = guestSearchParam.split('-');

  return guestRoomsString.map<GuestRoom>((guestRoomString: string) => {
    const guestRoomValues = guestRoomString.split(':');

    const adultsCount = parseInt(guestRoomValues.shift() || '0');
    const childrenCount = guestRoomValues.length;
    const childrenAges = guestRoomValues.map((guestRoomValue) => parseInt(guestRoomValue));

    return { adultsCount, childrenCount, childrenAges };
  });
}

export function getGuestsCount(guestRooms: Array<GuestRoom>): number {
  return guestRooms.reduce(
    (accumulator: number, currentValue: GuestRoom) =>
      accumulator + currentValue.adultsCount + currentValue.childrenCount,
    0,
  );
}

export function getHotelSortTypeSearchParamValue(
  hotelSortType: HotelSortType,
): (typeof HOTEL_SORT_TYPE)[HotelSortType] {
  return HOTEL_SORT_TYPE[hotelSortType] || 'popularity';
}

export function getHotelSortType(sortTypeSearchParam: string): HotelSortType {
  const found = Object.entries(HOTEL_SORT_TYPE).find(
    ([key, value]) => value === sortTypeSearchParam.toLowerCase(),
  );
  return found ? (found[0] as HotelSortType) : HotelSortType.Recommended;
}

export function getHotelSortTypeEventValue(
  hotelSortType: HotelSortType,
  order: 'asc' | 'desc',
  sortId?: string,
): string {
  switch (hotelSortType) {
    case HotelSortType.Recommended:
      return 'recommended';
    case HotelSortType.Price:
      if (order === 'asc') {
        return 'price_lowest';
      }
      return 'price_highest';
    case HotelSortType.Discount:
      return 'price_best_discount';
    case HotelSortType.Saving:
      return 'price_biggest_savings';
    case HotelSortType.AllReviewScore:
      return 'best_reviews';
    case HotelSortType.NearestToYou:
      return 'nearest_to_user';
    case HotelSortType.NearestToCityCentre:
      return 'distance_city';
    case HotelSortType.NearestToAirport:
      return 'distance_airport';
    case HotelSortType.Star:
      if (order === 'asc') {
        return 'stars_1to5';
      }
      return 'stars_5to1';
    case HotelSortType.Landmark:
      return `landmark_${sortId}`;
    default:
      return 'recommended';
  }
}

export const convertHotelSearchResultSearchToHotelLocation = (
  hotelSearchResultSearch: HotelSearchResultSearch,
): HotelLocation => {
  return {
    airportCount: 0, // Unknown value

    cityCode: !!hotelSearchResultSearch.city ? hotelSearchResultSearch.city.code : '',
    cityId: 0, // Unknown value
    cityName: !!hotelSearchResultSearch.city ? hotelSearchResultSearch.city.name : '',
    cityPermalink: '', // Unknown value

    countryCode: hotelSearchResultSearch.country?.code,
    countryId: 0, // Unknown value
    countryName: hotelSearchResultSearch.country?.name,
    countryPermalink: '', // Unknown value

    distance: 0, // Unknown value
    hotelCount: 0, // Unknown value
    id:
      hotelSearchResultSearch.searchType.toLowerCase() === LocationType.Hotel
        ? hotelSearchResultSearch.hotel!.id
        : 0, // Unknown value if is not hotel location type
    lat: 0, // Unknown value
    long: 0, // Unknown value
    name:
      hotelSearchResultSearch.searchType.toLowerCase() === LocationType.City
        ? hotelSearchResultSearch.cityName
        : hotelSearchResultSearch.searchType.toLowerCase() === LocationType.District &&
          hotelSearchResultSearch.district
        ? hotelSearchResultSearch.district.name
        : hotelSearchResultSearch.searchType.toLowerCase() === LocationType.Region &&
          hotelSearchResultSearch.region
        ? hotelSearchResultSearch.region.name
        : hotelSearchResultSearch.searchType.toLowerCase() === LocationType.Hotel
        ? hotelSearchResultSearch.hotel!.name
        : '',
    permalink: '', // Unknown value

    type: hotelSearchResultSearch.searchType.toLowerCase() as LocationType,
    worldRegionCode: hotelSearchResultSearch.worldRegion?.code,
    worldRegionId: 0, // Unknown value
    worldRegionName: hotelSearchResultSearch.worldRegion?.name,
    worldRegionPermalink: '', // Unknown value

    code:
      hotelSearchResultSearch.searchType.toLowerCase() === LocationType.City
        ? hotelSearchResultSearch.cityCode
        : undefined,

    districtId: hotelSearchResultSearch.district?.id,
    districtName: hotelSearchResultSearch.district?.name,
    districtPermalink: undefined,

    regionId: hotelSearchResultSearch.region ? hotelSearchResultSearch.region.id : undefined, // have this property only with params &types[]=city
    regionName: hotelSearchResultSearch.region ? hotelSearchResultSearch.region.name : undefined, // have this property only with params &types[]=city
    regionPermalink: undefined, // have this property only with params &types[]=city
    stateId: undefined, // have this property only with params &types[]=city
    stateName: undefined, // have this property only with params &types[]=city
    statePermalink: undefined, // have this property only with params &types[]=city
  };
};

const now = new Date();
export const hotelRateProviderImageUrl = (providerCode: string): string => {
  const mm = (now.getMonth() + 1).toString().padStart(2, '0');
  const dd = now.getDate().toString().padStart(2, '0');
  const dailyVersion = `v${now.getFullYear()}${mm}${dd}`;

  return `//assets.wego.com/image/upload/c_fit,w_200,h_82,f_auto,q_auto/${dailyVersion}/providers/rectangular_logos/${providerCode}.png`;
};

export const convertHotelSummaryToMiniHotel = (hotelSummary: HotelSummary): MiniHotel => {
  const hotelRateSummaryPrice =
    !!hotelSummary.rates && hotelSummary.rates.length > 0 ? hotelSummary.rates[0].price : undefined;

  return {
    id: hotelSummary.id,
    name: hotelSummary.name,
    star: hotelSummary.star,
    images: hotelSummary.images ?? [],
    reviews: hotelSummary.reviews ?? [],
    district: hotelSummary.district,
    rate: !!hotelRateSummaryPrice
      ? {
          currencyCode: hotelRateSummaryPrice.currencyCode,
          amount: hotelRateSummaryPrice.amount,
          amountUsd: hotelRateSummaryPrice.amountUsd,
          taxInclusive: false,
          ecpc: 0,
          taxAmount: 0,
          taxAmountUsd: 0,
          totalAmount: 0,
          totalAmountUsd: 0,
          totalTaxAmount: 0,
          totalTaxAmountUsd: 0,
          localTaxAmount: 0,
          localTaxAmountUsd: 0,
          totalLocalTaxAmount: 0,
          totalLocalTaxAmountUsd: 0,
        }
      : undefined,
  };
};

export const convertSimilarHotelToMiniHotel = (similarHotel: SimilarHotel): MiniHotel => {
  return {
    id: similarHotel.id,
    name: similarHotel.name,
    star: similarHotel.star,
    images: similarHotel.images,
    reviews: similarHotel.reviews,
    district: {
      id: 0,
      name: similarHotel.districtName,
      permalink: undefined,
    },
    rate: !!similarHotel.bestRate ? similarHotel.bestRate.price : undefined,
  };
};

export function getTotalNightCount(
  hotelSearchCheckIn: string,
  hotelSearchCheckOut: string,
): number {
  const checkInDateMilliseconds = getLocalDate(hotelSearchCheckIn).getTime();
  const checkOutDateMilliseconds = getLocalDate(hotelSearchCheckOut).getTime();

  return numberOfNights(checkInDateMilliseconds, checkOutDateMilliseconds);
}

export const getGuestCount = (hotelSearchGuestRooms: Array<GuestRoom>): number => {
  if (hotelSearchGuestRooms.length > 0) {
    const guestCount = hotelSearchGuestRooms
      .map((guestRoom) => guestRoom.adultsCount + guestRoom.childrenCount)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    return guestCount;
  }

  return 0;
};

export function isValidDate(dateString?: string, allowYesterday?: boolean) {
  if (!dateString) return false;
  // Check the format using a regular expression YYYY-MM-DD
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(dateString)) {
    return false;
  }

  const [year, month, day] = dateString.split('-').map(Number);

  // Check if the date is valid
  const date = new Date(year, month - 1, day);
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return false;
  }

  // Check if the date is expired
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const allowedDate = new Date(currentDate);
  if (allowYesterday) {
    allowedDate.setDate(currentDate.getDate() - 1); // Yesterday
  }

  const inputDate = new Date(year, month - 1, day);
  inputDate.setHours(0, 0, 0, 0);

  if (inputDate < allowedDate) {
    return false;
  }

  return true;
}

export const getHotelUsualPrice = (
  rate: RateViewModel | MetasearchHotelRate,
  showTotalPrice: boolean,
  isIncludingTax: boolean,
): Price | undefined => {
  if (rate.usualPrice) {
    const {
      taxInclusive,
      amount,
      taxAmount,
      amountUsd,
      taxAmountUsd,
      totalTaxAmount,
      totalAmount,
      totalTaxAmountUsd,
      totalAmountUsd,
    } = rate.price;

    const { usualAmount, usualAmountUsd, usualTotalAmount, usualTotalAmountUsd } = rate.usualPrice;

    const usualPriceTaxAmount = usualAmount * (taxAmount / amount);
    const usualPriceTaxAmountUsd = usualAmountUsd * (taxAmountUsd / amountUsd);
    const usualPriceTotalTaxAmount = usualTotalAmount * (totalTaxAmount / totalAmount);
    const usualPriceTotalTaxAmountUsd = usualTotalAmountUsd * (totalTaxAmountUsd / totalAmountUsd);

    let calculatedAmount: number = 0;
    let calculatedAmountUsd: number = 0;

    let toBeCalculatedAmount = taxInclusive ? usualAmount - usualPriceTaxAmount : usualAmount;
    let toBeCalculatedAmountUsd = taxInclusive
      ? usualAmountUsd - usualPriceTaxAmountUsd
      : usualAmountUsd;
    let toBeCalculatedTaxAmount = usualPriceTaxAmount;
    let toBeCalculatedTaxAmountUsd = usualPriceTaxAmountUsd;

    if (showTotalPrice) {
      toBeCalculatedAmount = taxInclusive
        ? usualTotalAmount - usualPriceTotalTaxAmount
        : usualTotalAmount;
      toBeCalculatedAmountUsd = taxInclusive
        ? usualTotalAmountUsd - usualPriceTotalTaxAmountUsd
        : usualTotalAmountUsd;
      toBeCalculatedTaxAmount = usualPriceTotalTaxAmount;
      toBeCalculatedTaxAmountUsd = usualPriceTotalTaxAmountUsd;
    }

    if (isIncludingTax) {
      calculatedAmount = toBeCalculatedAmount + toBeCalculatedTaxAmount;
      calculatedAmountUsd = toBeCalculatedAmountUsd + toBeCalculatedTaxAmountUsd;
    } else {
      calculatedAmount = toBeCalculatedAmount;
      calculatedAmountUsd = toBeCalculatedAmountUsd;
    }

    return {
      amount: calculatedAmount,
      amountUsd: calculatedAmountUsd,
      currencyCode: rate.price.currencyCode,
    };
  }
  return undefined;
};

export const countryCodeToFlagEmoji = (countryCode: string): string => {
  if (!countryCode) return '';
  return countryCode
    .toUpperCase()
    .split('')
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
    .join('');
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
};

export const getHotelDealPromotionCheckoutDate = (leg: Leg[] | LegDetails[]): string => {
  const departureDate = leg[0].departureDateTime.split('T')[0];
  if (leg.length === 1) {
    // one-way flight
    const date = new Date(departureDate);
    date.setDate(date.getDate() + 2);
    return date.toISOString().split('T')[0];
  }

  const returnDate = leg[1].departureDateTime.split('T')[0];
  if (returnDate === departureDate) {
    const date = new Date(returnDate);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  }

  return returnDate;
};

export const getPartnerName = (tsCode: string | null) => {
  if (tsCode) {
    if (
      isDevelopmentEnvironment(window.location.hostname) ||
      isStagingEnvironment(window.location.hostname)
    ) {
      return STAGING_TS_CODES[tsCode];
    }
    return PROD_TS_CODES[tsCode];
  }
  return 'Google';
};
