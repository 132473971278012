import clsx from '@alexis/helpers/clsx';
import React from 'react';
import { Link } from 'react-router-dom';

import { useIsAuthNew } from '@hooks/useIsAuthNew';

import styles from '../../styles/components/shopcash/ShopcashWallet.module.scss';
import Price from '../Price';
import { ShopcashWalletProps } from './../../types/props/shopcash/shopCashWalletProps';

const ShopcashWallet: React.FC<ShopcashWalletProps> = ({
  user,
  shopCashUser,
  locale,
  currency,
  exchangeRate,
  translations,
}) => {
  const { login, isAuthNew } = useIsAuthNew();

  const LoginWrapper: React.FC = ({ children }) => {
    if (isAuthNew) {
      return (
        <div className='signin' onClick={() => login()}>
          {children}
        </div>
      );
    }

    return <Link to={{ pathname: '/login' }}>{children}</Link>;
  };

  return (
    <div className={clsx(styles.container)}>
      {!user ? (
        <div className={styles.shopcashWallet}>
          <i className={clsx(styles.icons, styles.loginOrSignUp)}></i>

          <LoginWrapper>{translations.sign_in}</LoginWrapper>
        </div>
      ) : (
        <div className={styles.shopcashWallet}>
          {!!shopCashUser?.ledgerUsd ? (
            <>
              <i className={clsx(styles.icons, styles.wallet)}></i>
              <Link to={{ pathname: '/preferences/wallet-overview' }}>
                <Price
                  locale={locale}
                  price={{
                    amount: shopCashUser.ledgerUsd.displayBalance,
                    amountUsd: shopCashUser.ledgerUsd.displayBalance,
                    currencyCode: 'USD',
                  }}
                  showDecimal={true}
                  currency={currency}
                  exchangeRate={exchangeRate}
                  className={styles.balanceAmount}
                />
              </Link>
            </>
          ) : (
            <>{user.displayName || user.email}</>
          )}
        </div>
      )}
    </div>
  );
};

export default ShopcashWallet;
