import clsx from '@alexis/helpers/clsx';
import React, { useEffect } from 'react';

import { enableBodyScroll, disableBodyScroll } from '../helpers/scroller';
import styles from '../styles/components/Drawer.module.scss';

const Drawer: React.FC<DrawerProps> = ({
  anchor,
  children,
  className,
  hasRoundedTopBorder,
  isOpen,
  onClose,
}): JSX.Element => {
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return () => {
      enableBodyScroll();
    };
  }, [isOpen]);

  const anchorClassName = (
    anchor:
      | 'bottom'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom-100'
      | 'newBottom-100'
      | 'bottom-60'
      | 'bottom-50',
  ): string => {
    switch (anchor) {
      case 'bottom':
        return styles.bottom;
      case 'left':
        return styles.left;
      case 'right':
        return styles.right;
      case 'top':
        return styles.top;
      case 'bottom-100':
        return styles.bottom100;
      case 'newBottom-100':
        return styles.newBottom100;
      case 'bottom-60':
        return styles.bottom60;
      case 'bottom-50':
        return styles.bottom50;
    }
  };

  return (
    <div className={clsx(styles.container, isOpen && styles.open)}>
      <div className={styles.overlay} onClick={onClose}></div>
      <div
        className={clsx(
          styles.drawer,
          hasRoundedTopBorder && styles.roundedBorder,
          anchorClassName(anchor),
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Drawer;
