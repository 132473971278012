import { getCountryConfig } from '@alexis/helpers/country';
import { dateDisplayFormat } from '@alexis/helpers/date';
import isIOS from '@alexis/helpers/device';
import { localStorageGet } from '@alexis/helpers/localStorage';
import { translateNumber } from '@alexis/helpers/translation';
import { Country } from '@alexis/types/country';
import { CurrentSite } from '@alexis/types/currentSite';
import { CancelToken } from 'axios';
import { FlightLocation } from 'flightLocation';
import { FlightSearch } from 'flightSearch';

import { getLocationDetails } from '@apis/flightPlaces';

import { SuggestedNearbyLocationState } from '@components/flights/FlightSearchForm/types';

import type {
  NearbyRoutesLegItem,
  NearbyRoutesPayloadItem,
} from '@wegoTypes/apis/Flight/nearbyLocationResponse';
import { FlightPopularLocation } from '@wegoTypes/flightPopularLocation';
import { Translations } from '@wegoTypes/translations';
import { TripType } from '@wegoTypes/tripType';

import { CHEAPEST_TAGS_ARRAY, NEW_NAME_MAX_LENGTH_COUNTRY_CODE } from '../constants/flight';
import { LocationType } from '../types/locationType';
import { PassengerIdType } from '../types/passengerIdType';
import { PaymentCard } from '../types/paymentCard';
import { Salutation } from '../types/salutation';
import { SortOrder } from '../types/sortOrder';
import { TripSortType } from '../types/tripSortType';
import { convertListToHashMap, isArrayIncludesAnyValue } from './array';

export const sortFlightFares = (fares: Array<FlightFare>): Array<FlightFare> => {
  return [...fares].sort((aFare, bFare) => {
    const aFlightFareAmountUsd = aFare.price.amountUsd;
    const bFlightFareAmountUsd = bFare.price.amountUsd;

    return aFlightFareAmountUsd - bFlightFareAmountUsd;
  });
};

export const sortTripViewModelBasedOnPrice = (
  tripViewModels: Array<TripViewModel>,
  sortOrderType: SortOrder = SortOrder.Ascending,
  isForReturnLeg?: boolean,
): Array<TripViewModel> => {
  return [...tripViewModels].sort(
    (aTripViewModel: TripViewModel, bTripViewModel: TripViewModel) => {
      const sortByPrice = getSortOrderOfTripViewModelsBasedOnPrice(
        aTripViewModel,
        bTripViewModel,
        sortOrderType,
      );

      if (sortByPrice !== 0) {
        return sortByPrice;
      }

      const sortByScore = getSortOrderOfTripViewModelsBasedOnScore(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Descending,
      );

      if (sortByScore !== 0) {
        return sortByScore;
      }

      const sortByDepartureTime = getSortOrderOfTripViewModelsBasedOnDepartureTime(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
        isForReturnLeg,
      );

      if (sortByDepartureTime !== 0) {
        return sortByDepartureTime;
      }

      return 0;
    },
  );
};

export const sortTripViewModelBasedOnDuration = (
  tripViewModels: Array<TripViewModel>,
  sortOrderType: SortOrder,
  isForReturnLeg?: boolean,
): Array<TripViewModel> => {
  return [...tripViewModels].sort(
    (aTripViewModel: TripViewModel, bTripViewModel: TripViewModel) => {
      const aTripViewModelLegs: Array<Leg> = [aTripViewModel.departureLegViewModel.leg];
      if (!!aTripViewModel.returnLegViewModel) {
        aTripViewModelLegs.push(aTripViewModel.returnLegViewModel.leg);
      }

      const bTripViewModelLegs: Array<Leg> = [bTripViewModel.departureLegViewModel.leg];
      if (!!bTripViewModel.returnLegViewModel) {
        bTripViewModelLegs.push(bTripViewModel.returnLegViewModel.leg);
      }

      const aTripTotalDurationMinutes = aTripViewModelLegs.reduce(
        (accumulator: number, leg: Leg) => {
          return accumulator + leg.durationMinutes;
        },
        0,
      );

      const bTripTotalDurationMinutes = bTripViewModelLegs.reduce(
        (accumulator: number, leg: Leg) => {
          return accumulator + leg.durationMinutes;
        },
        0,
      );

      const sortByDuration = getOrderForSortingTwoValues(
        aTripTotalDurationMinutes,
        bTripTotalDurationMinutes,
        sortOrderType,
      );

      if (sortByDuration !== 0) {
        return sortByDuration;
      }

      const sortByScore = getSortOrderOfTripViewModelsBasedOnScore(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Descending,
      );

      if (sortByScore !== 0) {
        return sortByScore;
      }

      const sortByPrice = getSortOrderOfTripViewModelsBasedOnPrice(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
      );

      if (sortByPrice !== 0) {
        return sortByPrice;
      }

      const sortByDepartureTime = getSortOrderOfTripViewModelsBasedOnDepartureTime(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
        isForReturnLeg,
      );

      if (sortByDepartureTime !== 0) {
        return sortByDepartureTime;
      }

      return 0;
    },
  );
};

export function sortTripViewModelsBasedOnFareScore(
  tripViewModels: TripViewModel[],
  sortOrderType: SortOrder,
  isOnlyForReturnLeg?: boolean,
) {
  return [...tripViewModels].sort(
    (aTripViewModel: TripViewModel, bTripViewModel: TripViewModel) => {
      const sortByScore = getSortOrderOfTripViewModelsBasedOnScore(
        aTripViewModel,
        bTripViewModel,
        sortOrderType,
      );

      if (sortByScore !== 0) {
        return sortByScore;
      }

      const sortByPrice = getSortOrderOfTripViewModelsBasedOnPrice(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
      );

      if (sortByPrice !== 0) {
        return sortByPrice;
      }

      const sortByDepartureTime = getSortOrderOfTripViewModelsBasedOnDepartureTime(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
        isOnlyForReturnLeg,
      );

      if (sortByDepartureTime !== 0) {
        return sortByDepartureTime;
      }

      return 0;
    },
  );
}

export function getSortOrderOfTripViewModelsBasedOnScore(
  aTripViewModel: TripViewModel,
  bTripViewModel: TripViewModel,
  sortOrderType: SortOrder,
) {
  const aTripTotalScore = getMinFareViewModelOfATripViewModel(aTripViewModel).fare.score;

  const bTripTotalScore = getMinFareViewModelOfATripViewModel(bTripViewModel).fare.score;

  return getOrderForSortingTwoValues(aTripTotalScore, bTripTotalScore, sortOrderType);
}

export function getSortOrderOfTripViewModelsBasedOnDepartureTime(
  aTripViewModel: TripViewModel,
  bTripViewModel: TripViewModel,
  sortOrderType: SortOrder,
  isForReturnLeg?: boolean,
) {
  const aTripViewModelDepartTime = isForReturnLeg
    ? aTripViewModel.returnLegViewModel?.leg.departureTimeMinutes ?? 0
    : aTripViewModel.departureLegViewModel.leg.departureTimeMinutes;
  const bTripViewModelDepartTime = isForReturnLeg
    ? bTripViewModel.returnLegViewModel?.leg.departureTimeMinutes ?? 0
    : bTripViewModel.departureLegViewModel.leg.departureTimeMinutes;

  return getOrderForSortingTwoValues(
    aTripViewModelDepartTime,
    bTripViewModelDepartTime,
    sortOrderType,
  );
}

export function getSortOrderOfTripViewModelsBasedOnPrice(
  aTripViewModel: TripViewModel,
  bTripViewModel: TripViewModel,
  sortOrderType: SortOrder,
) {
  const aTripViewModelFareWithFee =
    getMinFareViewModelOfATripViewModel(aTripViewModel).fare.price.amount;
  const bTripViewModelFareWithFee =
    getMinFareViewModelOfATripViewModel(bTripViewModel).fare.price.amount;

  return getOrderForSortingTwoValues(
    aTripViewModelFareWithFee,
    bTripViewModelFareWithFee,
    sortOrderType,
  );
}

export function getOrderForSortingTwoValues(
  aValue: number,
  bValue: number,
  sortOrderType: SortOrder,
) {
  if (aValue < bValue) {
    return sortOrderType === SortOrder.Ascending ? -1 : 1;
  }

  if (aValue > bValue) {
    return sortOrderType === SortOrder.Ascending ? 1 : -1;
  }

  return 0;
}

export const sortTripViewModelBasedOnScore = (
  tripViewModels: Array<TripViewModel>,
  sortOrderType: SortOrder,
  isNotRepeatHighlightTags?: boolean,
  isOnlyForReturnLeg?: boolean,
): Array<TripViewModel> => {
  const sortedTripViewModels = sortTripViewModelsBasedOnFareScore(
    tripViewModels,
    sortOrderType,
    isOnlyForReturnLeg,
  );

  if (sortedTripViewModels.length) {
    const bestTripViewModel: TripViewModel = {
      ...sortedTripViewModels[0],
      tripCardTags: ['best'],
    };

    sortedTripViewModels.splice(0, 1, bestTripViewModel);

    const cheapestHighlightedTripViewModels = highlightCheapestTripViewModels(
      sortedTripViewModels,
      isNotRepeatHighlightTags,
    );

    return highlightCheapestDirectTripViewModels(
      cheapestHighlightedTripViewModels,
      isNotRepeatHighlightTags,
      isOnlyForReturnLeg,
    );
  }

  return sortedTripViewModels;
};

function highlightCheapestTripViewModels(
  tripViewModels: Array<TripViewModel>,
  isNotRepeatHighlightTags?: boolean,
): Array<TripViewModel> {
  if (tripViewModels.length) {
    const { cheapestTripViewModel, index } = getCheapestTripViewModels(tripViewModels)[0];

    const insertIndexOfCheapestTripViewModel = index === 0 ? 0 : 1;

    tripViewModels.splice(index, 1);
    tripViewModels.splice(insertIndexOfCheapestTripViewModel, 0, cheapestTripViewModel);

    if (!isNotRepeatHighlightTags) {
      const cheapestPrice =
        getMinFareViewModelOfATripViewModel(cheapestTripViewModel).fare.price.amount;

      return tripViewModels.map((tripViewModel) => {
        const tripViewModelPrice =
          getMinFareViewModelOfATripViewModel(tripViewModel).fare.price.amount;

        if (tripViewModelPrice === cheapestPrice) {
          return {
            ...tripViewModel,
            tripCardTags: [...(tripViewModel?.tripCardTags || []), 'cheapest'],
          };
        }

        return tripViewModel;
      });
    }
  }

  return tripViewModels;
}

const getCheapestTripViewModels = (
  tripViewModels: Array<TripViewModel>,
): Array<{ cheapestTripViewModel: TripViewModel; index: number }> => {
  let cheapestTripViewModel;
  let cheapestTripViewModelIndex;

  for (let i = 0; i < tripViewModels.length; i++) {
    if (isCurrentTripViewModelCheaper(tripViewModels[i], cheapestTripViewModel)) {
      cheapestTripViewModel = tripViewModels[i];
      cheapestTripViewModelIndex = i;
    }
  }

  const cheapestTripViewModels: Array<{ cheapestTripViewModel: TripViewModel; index: number }> = [];

  if (cheapestTripViewModel && cheapestTripViewModelIndex !== undefined) {
    cheapestTripViewModels.push(
      getTripViewModelWithTagAndIndex(
        cheapestTripViewModel,
        cheapestTripViewModelIndex,
        'cheapest',
      ),
    );
  }

  return cheapestTripViewModels;
};

function isCurrentTripViewModelCheaper(
  currentTripViewModel: TripViewModel,
  cheapestTripViewModel: TripViewModel | undefined,
): boolean {
  const currentTripViewModelFare = getMinFareViewModelOfATripViewModel(currentTripViewModel);

  const currentTripViewModelPrice = currentTripViewModelFare.fare.price.amount;

  const cheapestTripViewModelFare = cheapestTripViewModel
    ? getMinFareViewModelOfATripViewModel(cheapestTripViewModel)
    : undefined;

  const cheapestTripViewModelPrice = cheapestTripViewModelFare?.fare.price.amount;

  if (
    !cheapestTripViewModel ||
    !cheapestTripViewModelPrice ||
    currentTripViewModelPrice < cheapestTripViewModelPrice ||
    (currentTripViewModelPrice === cheapestTripViewModelPrice &&
      currentTripViewModelFare.fare.score > cheapestTripViewModelFare.fare.score) ||
    (currentTripViewModelPrice === cheapestTripViewModelPrice &&
      currentTripViewModelFare.fare.score === cheapestTripViewModelFare.fare.score &&
      currentTripViewModel.departureLegViewModel.leg.departureTimeMinutes <
        cheapestTripViewModel.departureLegViewModel.leg.departureTimeMinutes)
  ) {
    return true;
  }
  return false;
}

function getTripViewModelWithTagAndIndex(
  tripViewModel: TripViewModel,
  index: number,
  tag: TripCardTag,
): {
  cheapestTripViewModel: TripViewModel;
  index: number;
} {
  return {
    cheapestTripViewModel: {
      ...tripViewModel,
      tripCardTags: [...(tripViewModel.tripCardTags || []), tag],
    },
    index: index,
  };
}

function highlightCheapestDirectTripViewModels(
  tripViewModels: Array<TripViewModel>,
  isNotRepeatHighlightTags?: boolean,
  isOnlyForReturnLeg?: boolean,
): Array<TripViewModel> {
  let { cheapestDirectTripViewModel, cheapestDirectTripViewModelIndex } =
    getCheapestDirectTripViewModel(tripViewModels, isOnlyForReturnLeg);

  if (cheapestDirectTripViewModel && cheapestDirectTripViewModelIndex !== undefined) {
    cheapestDirectTripViewModel = {
      ...cheapestDirectTripViewModel,
      tripCardTags: [...(cheapestDirectTripViewModel?.tripCardTags || []), 'cheapest_direct'],
    };

    const isFirstCardCheapest = tripViewModels[0].tripCardTags?.includes('cheapest');

    const insertIndexOfCheapestDirectTripViewModel =
      cheapestDirectTripViewModelIndex <= 1
        ? cheapestDirectTripViewModelIndex
        : isFirstCardCheapest
        ? 1
        : 2;

    tripViewModels.splice(cheapestDirectTripViewModelIndex, 1);
    tripViewModels.splice(insertIndexOfCheapestDirectTripViewModel, 0, cheapestDirectTripViewModel);

    if (!isNotRepeatHighlightTags) {
      const cheapestDirectPrice = getMinFareViewModelOfATripViewModel(cheapestDirectTripViewModel)
        .fare.price.amount;

      return tripViewModels.map((tripViewModel) => {
        const isTripViewModelDirect =
          (isOnlyForReturnLeg || tripViewModel.departureLegViewModel.leg.segments.length === 1) &&
          (!tripViewModel.returnLegViewModel ||
            tripViewModel.returnLegViewModel?.leg.segments.length === 1);

        if (isTripViewModelDirect) {
          const tripViewModelPrice =
            getMinFareViewModelOfATripViewModel(tripViewModel).fare.price.amount;

          if (tripViewModelPrice === cheapestDirectPrice) {
            return {
              ...tripViewModel,
              tripCardTags: [...(tripViewModel?.tripCardTags || []), 'cheapest_direct'],
            };
          }

          return tripViewModel;
        }

        return tripViewModel;
      });
    }
  }

  return tripViewModels;
}

function getCheapestDirectTripViewModel(
  tripViewModels: Array<TripViewModel>,
  isOnlyForReturnLeg?: boolean,
): {
  cheapestDirectTripViewModel: TripViewModel | undefined;
  cheapestDirectTripViewModelIndex: number | undefined;
} {
  let cheapestDirectTripViewModel;
  let cheapestDirectTripViewModelIndex;

  for (let i = 0; i < tripViewModels.length; i++) {
    if (
      (isOnlyForReturnLeg || tripViewModels[i].departureLegViewModel.leg.segments.length === 1) &&
      (!tripViewModels[i].returnLegViewModel ||
        tripViewModels[i].returnLegViewModel?.leg.segments.length === 1)
    ) {
      const currentTripViewModelFare = getMinFareViewModelOfATripViewModel(tripViewModels[i]);

      const currentTripViewModelPrice = currentTripViewModelFare.fare.price.amount;

      const cheapestDirectTripViewModelFare = cheapestDirectTripViewModel
        ? getMinFareViewModelOfATripViewModel(cheapestDirectTripViewModel)
        : undefined;

      const cheapestDirectTripViewModelPrice = cheapestDirectTripViewModelFare?.fare.price.amount;

      if (
        !cheapestDirectTripViewModel ||
        !cheapestDirectTripViewModelPrice ||
        currentTripViewModelPrice < cheapestDirectTripViewModelPrice ||
        (currentTripViewModelPrice === cheapestDirectTripViewModelPrice &&
          currentTripViewModelFare.fare.score > cheapestDirectTripViewModelFare.fare.score) ||
        (currentTripViewModelPrice === cheapestDirectTripViewModelPrice &&
          currentTripViewModelFare.fare.score === cheapestDirectTripViewModelFare.fare.score &&
          tripViewModels[i].departureLegViewModel.leg.departureTimeMinutes <
            cheapestDirectTripViewModel.departureLegViewModel.leg.departureTimeMinutes)
      ) {
        cheapestDirectTripViewModel = tripViewModels[i];
        cheapestDirectTripViewModelIndex = i;
      }
    }
  }

  return { cheapestDirectTripViewModel, cheapestDirectTripViewModelIndex };
}

export function getMinFareViewModelOfATripViewModel(tripViewModel: TripViewModel) {
  let filteredFareViewModels = tripViewModel.fareViewModels;
  filteredFareViewModels = filteredFareViewModels.sort(
    (fareViewModelA, fareViewModelB) =>
      fareViewModelA.fare.price.amountUsd - fareViewModelB.fare.price.amountUsd,
  );
  return filteredFareViewModels[0];
}

export const mergeDuplicateNameAirlines = (
  airlines: FlightMetaSearchFilter[],
): FlightMetaSearchMergedAirlinesFilter[] => {
  const arrayHashmap = airlines.reduce<FlightMetaSearchMergedAirlinesFilter[]>((acc, item) => {
    const existingItem = acc.find((i) => i.airline?.name === item.airline?.name);
    if (existingItem) {
      existingItem.name.push(item.name);
      if (item.price && existingItem.price) {
        existingItem.price = {
          ...existingItem.price,
          amount: Math.min(existingItem.price.amount, item.price.amount),
          totalAmount: Math.min(existingItem.price.totalAmount, item.price.totalAmount),
          amountUsd: Math.min(existingItem.price.amountUsd, item.price.amountUsd),
          totalAmountUsd: Math.min(existingItem.price.totalAmountUsd, item.price.totalAmountUsd),
        };
      }
    } else {
      acc.push({ ...item, name: [item.name] });
    }
    return acc;
  }, []);

  return arrayHashmap;
};

export const sortTripViewModelBasedOnOutboundTime = (
  tripViewModels: Array<TripViewModel>,
  sortOrderType: SortOrder,
): Array<TripViewModel> => {
  return [...tripViewModels].sort(
    (aTripViewModel: TripViewModel, bTripViewModel: TripViewModel) => {
      const aTripOutboundLeg = aTripViewModel.departureLegViewModel.leg;
      const bTripOutboundLeg = bTripViewModel.departureLegViewModel.leg;

      const aTripOutboundLegDepartureTimeMinutes = aTripOutboundLeg.departureTimeMinutes;
      const bTripOutboundLegDepartureTimeMinutes = bTripOutboundLeg.departureTimeMinutes;

      if (aTripOutboundLegDepartureTimeMinutes < bTripOutboundLegDepartureTimeMinutes) {
        return sortOrderType === SortOrder.Ascending ? -1 : 1;
      }

      if (aTripOutboundLegDepartureTimeMinutes > bTripOutboundLegDepartureTimeMinutes) {
        return sortOrderType === SortOrder.Ascending ? 1 : -1;
      }

      const sortByScore = getSortOrderOfTripViewModelsBasedOnScore(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Descending,
      );

      if (sortByScore !== 0) {
        return sortByScore;
      }

      const sortByPrice = getSortOrderOfTripViewModelsBasedOnPrice(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
      );

      if (sortByPrice !== 0) {
        return sortByPrice;
      }

      return 0;
    },
  );
};

export const sortTripViewModelBasedOnInboundTime = (
  tripViewModels: Array<TripViewModel>,
  sortOrderType: SortOrder,
): Array<TripViewModel> => {
  return [...tripViewModels].sort(
    (aTripViewModel: TripViewModel, bTripViewModel: TripViewModel) => {
      const isRoundTrip: boolean =
        !!aTripViewModel.returnLegViewModel && !!bTripViewModel.returnLegViewModel;
      const aTripCompareLeg: Leg = !!aTripViewModel.returnLegViewModel
        ? aTripViewModel.returnLegViewModel.leg
        : aTripViewModel.departureLegViewModel.leg;
      const bTripCompareLeg: Leg = !!bTripViewModel.returnLegViewModel
        ? bTripViewModel.returnLegViewModel.leg
        : bTripViewModel.departureLegViewModel.leg;

      const aTripCompareLegArrivalTimeMinutes = isRoundTrip
        ? aTripCompareLeg.departureTimeMinutes
        : aTripCompareLeg.arrivalTimeMinutes;
      const bTripCompareLegArrivalTimeMinutes = isRoundTrip
        ? bTripCompareLeg.departureTimeMinutes
        : bTripCompareLeg.arrivalTimeMinutes;

      if (aTripCompareLegArrivalTimeMinutes < bTripCompareLegArrivalTimeMinutes) {
        return sortOrderType === SortOrder.Ascending ? -1 : 1;
      }

      if (aTripCompareLegArrivalTimeMinutes > bTripCompareLegArrivalTimeMinutes) {
        return sortOrderType === SortOrder.Ascending ? 1 : -1;
      }

      const sortByScore = getSortOrderOfTripViewModelsBasedOnScore(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Descending,
      );

      if (sortByScore !== 0) {
        return sortByScore;
      }

      const sortByPrice = getSortOrderOfTripViewModelsBasedOnPrice(
        aTripViewModel,
        bTripViewModel,
        SortOrder.Ascending,
      );

      if (sortByPrice !== 0) {
        return sortByPrice;
      }

      return 0;
    },
  );
};

export const filterTripViewModelBasedOnFilters = (
  tripViewModels: Array<TripViewModel>,
  priceOptions: PriceOptions,
  selectedPaymentMethodIds: Array<number>,
  selectedStopCodes: Array<string>,
  isBookWithAirlines: boolean,
  isBookOnWego: boolean,
  selectedMinPriceUsd: number | undefined,
  selectedMaxPriceUsd: number | undefined,
  isNotOvernightFlight: boolean,
  isNotLongStopoverFlight: boolean,
  selectedDepartLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedDepartLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedMinDepartDuration: number | undefined,
  selectedMaxDepartDuration: number | undefined,
  selectedMinReturnDuration: number | undefined,
  selectedMaxReturnDuration: number | undefined,
  selectedMinStopoverDuration: number | undefined,
  selectedMaxStopoverDuration: number | undefined,
  isTripWithSameAirline: boolean,
  selectedAllianceCodes: Array<string>,
  selectedAirlineCodes: Array<string>,
  selectedOriginAirportCodes: Array<string>,
  selectedDestinationAirportCodes: Array<string>,
  isNoChangeOfAirportAtStopover: boolean,
  selectedStopoverAirportCodes: Array<string>,
  selectedFareProviderCodes: Array<string>,
): Array<TripViewModel> => {
  const selectedStopCodeNumbers: Array<number> = selectedStopCodes.map((selectedStopCode) =>
    selectedStopCode === 'DIRECT' ? 0 : selectedStopCode === 'ONE_STOP' ? 1 : 2,
  );

  return tripViewModels.filter((tripViewModel) => {
    const { departureLegViewModel, returnLegViewModel, fareViewModels } = tripViewModel;

    return filterTripViewModelCriteria(
      priceOptions,
      selectedPaymentMethodIds,
      isBookWithAirlines,
      isBookOnWego,
      selectedMinPriceUsd,
      selectedMaxPriceUsd,
      isNotOvernightFlight,
      isNotLongStopoverFlight,
      selectedDepartLegDepartureTimeBlocks,
      selectedDepartLegArrivalTimeBlocks,
      selectedReturnLegDepartureTimeBlocks,
      selectedReturnLegArrivalTimeBlocks,
      selectedMinDepartDuration,
      selectedMaxDepartDuration,
      selectedMinReturnDuration,
      selectedMaxReturnDuration,
      selectedMinStopoverDuration,
      selectedMaxStopoverDuration,
      isTripWithSameAirline,
      selectedAllianceCodes,
      selectedAirlineCodes,
      selectedOriginAirportCodes,
      selectedDestinationAirportCodes,
      isNoChangeOfAirportAtStopover,
      selectedStopoverAirportCodes,
      selectedFareProviderCodes,
      selectedStopCodeNumbers,
      departureLegViewModel,
      returnLegViewModel,
      fareViewModels.map((model) => ({
        fare: {
          price: model.fare.price,
          refundable: model.fare.refundable,
          paymentFees: model.fare.paymentFees,
        },
        provider: model.provider,
      })),
    );
  });
};

export const filterInlineAdTripViewModelBasedOnFilters = (
  tripViewModels: Array<InlineAdTripViewModel>,
  priceOptions: PriceOptions,
  selectedPaymentMethodIds: Array<number>,
  selectedStopCodes: Array<string>,
  isBookWithAirlines: boolean,
  isBookOnWego: boolean,
  selectedMinPriceUsd: number | undefined,
  selectedMaxPriceUsd: number | undefined,
  isNotOvernightFlight: boolean,
  isNotLongStopoverFlight: boolean,
  selectedDepartLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedDepartLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedMinDepartDuration: number | undefined,
  selectedMaxDepartDuration: number | undefined,
  selectedMinReturnDuration: number | undefined,
  selectedMaxReturnDuration: number | undefined,
  selectedMinStopoverDuration: number | undefined,
  selectedMaxStopoverDuration: number | undefined,
  isTripWithSameAirline: boolean,
  selectedAllianceCodes: Array<string>,
  selectedAirlineCodes: Array<string>,
  selectedOriginAirportCodes: Array<string>,
  selectedDestinationAirportCodes: Array<string>,
  isNoChangeOfAirportAtStopover: boolean,
  selectedStopoverAirportCodes: Array<string>,
  selectedFareProviderCodes: Array<string>,
): Array<InlineAdTripViewModel> => {
  const selectedStopCodeNumbers: Array<number> = selectedStopCodes.map((selectedStopCode) =>
    selectedStopCode === 'DIRECT' ? 0 : selectedStopCode === 'ONE_STOP' ? 1 : 2,
  );

  return tripViewModels.filter((tripViewModel) => {
    const { departureLegViewModel, returnLegViewModel, fareViewModels } = tripViewModel;

    return filterTripViewModelCriteria(
      priceOptions,
      selectedPaymentMethodIds,
      isBookWithAirlines,
      isBookOnWego,
      selectedMinPriceUsd,
      selectedMaxPriceUsd,
      isNotOvernightFlight,
      isNotLongStopoverFlight,
      selectedDepartLegDepartureTimeBlocks,
      selectedDepartLegArrivalTimeBlocks,
      selectedReturnLegDepartureTimeBlocks,
      selectedReturnLegArrivalTimeBlocks,
      selectedMinDepartDuration,
      selectedMaxDepartDuration,
      selectedMinReturnDuration,
      selectedMaxReturnDuration,
      selectedMinStopoverDuration,
      selectedMaxStopoverDuration,
      isTripWithSameAirline,
      selectedAllianceCodes,
      selectedAirlineCodes,
      selectedOriginAirportCodes,
      selectedDestinationAirportCodes,
      isNoChangeOfAirportAtStopover,
      selectedStopoverAirportCodes,
      selectedFareProviderCodes,
      selectedStopCodeNumbers,
      departureLegViewModel,
      returnLegViewModel,
      fareViewModels.map((model) => ({
        fare: {
          price: model.fare.price,
          refundable: model.fare.refundable,
          paymentFees: model.fare.paymentFees,
        },
        provider: model.provider,
      })),
    );
  });
};

function isFlightTimeWithinSelectedTimeBlock(
  flightTimeMinutes: number,
  timeBlock: FlightFilterTimeBlock,
) {
  switch (timeBlock) {
    case '0000-0600':
      return 0 <= flightTimeMinutes && flightTimeMinutes <= 360;
    case '0600-1200':
      return 360 <= flightTimeMinutes && flightTimeMinutes <= 720;
    case '1200-1800':
      return 720 <= flightTimeMinutes && flightTimeMinutes <= 1080;
    case '1800-2400':
      return (1080 <= flightTimeMinutes && flightTimeMinutes <= 1440) || flightTimeMinutes === 0;
  }
}

function isTripViewModelWithinSelectedTimeBlocksFilter(
  timeToBeCheckedInMinutes: number,
  selectedTimeBlocks: Array<FlightFilterTimeBlock>,
) {
  if (selectedTimeBlocks.length === 0) {
    return true;
  }

  return selectedTimeBlocks.some((timeBlock) => {
    return isFlightTimeWithinSelectedTimeBlock(timeToBeCheckedInMinutes, timeBlock);
  });
}

const filterTripViewModelCriteria = (
  priceOptions: PriceOptions,
  selectedPaymentMethodIds: Array<number>,
  isBookWithAirlines: boolean,
  isBookOnWego: boolean,
  selectedMinPriceUsd: number | undefined,
  selectedMaxPriceUsd: number | undefined,
  isNotOvernightFlight: boolean,
  isNotLongStopoverFlight: boolean,
  selectedDepartLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedDepartLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegDepartureTimeBlocks: FlightFilterTimeBlock[],
  selectedReturnLegArrivalTimeBlocks: FlightFilterTimeBlock[],
  selectedMinDepartDuration: number | undefined,
  selectedMaxDepartDuration: number | undefined,
  selectedMinReturnDuration: number | undefined,
  selectedMaxReturnDuration: number | undefined,
  selectedMinStopoverDuration: number | undefined,
  selectedMaxStopoverDuration: number | undefined,
  isTripWithSameAirline: boolean,
  selectedAllianceCodes: Array<string>,
  selectedAirlineCodes: Array<string>,
  selectedOriginAirportCodes: Array<string>,
  selectedDestinationAirportCodes: Array<string>,
  isNoChangeOfAirportAtStopover: boolean,
  selectedStopoverAirportCodes: Array<string>,
  selectedFareProviderCodes: Array<string>,
  selectedStopCodeNumbers: Array<number>,
  departureLegViewModel: LegViewModel,
  returnLegViewModel: LegViewModel | undefined,
  fareViewModels: Array<{
    fare: { price: FlightPrice; refundable: boolean; paymentFees: Array<FlightFarePaymentFee> };
    provider: FlightMetaSearchProvider;
  }>,
): boolean => {
  const departureLegStopoversCount = Math.min(departureLegViewModel.leg.stopoversCount, 2);
  const returnLegStopoversCount = !!returnLegViewModel
    ? Math.min(returnLegViewModel.leg.stopoversCount, 2)
    : -1;
  const highestCountOfBothLeg = Math.max(departureLegStopoversCount, returnLegStopoversCount);

  const stopCodesFilter =
    selectedStopCodeNumbers.length > 0
      ? selectedStopCodeNumbers.some(
          (selectedStopCodeNumber) => selectedStopCodeNumber === highestCountOfBothLeg,
        )
      : true;

  let priceFilter = true;
  if (selectedMinPriceUsd !== undefined && selectedMaxPriceUsd !== undefined) {
    const fare = fareViewModels[0].fare;

    if (fare) {
      const { amountUsd, totalAmountUsd } = fare.price;

      const calculatedAmountUsd = priceOptions.showTotalPrice ? totalAmountUsd : amountUsd;

      priceFilter =
        calculatedAmountUsd >= selectedMinPriceUsd && calculatedAmountUsd <= selectedMaxPriceUsd;
    }
  }

  const departLegDepartureTimeFilter = isTripViewModelWithinSelectedTimeBlocksFilter(
    departureLegViewModel.leg.departureTimeMinutes,
    selectedDepartLegDepartureTimeBlocks,
  );

  const departLegArrivalTimeFilter = isTripViewModelWithinSelectedTimeBlocksFilter(
    departureLegViewModel.leg.arrivalTimeMinutes,
    selectedDepartLegArrivalTimeBlocks,
  );

  let returnLegDepartureTimeFilter = true;
  if (!!returnLegViewModel) {
    returnLegDepartureTimeFilter = isTripViewModelWithinSelectedTimeBlocksFilter(
      returnLegViewModel.leg.departureTimeMinutes,
      selectedReturnLegDepartureTimeBlocks,
    );
  }

  let returnLegArrivalTimeFilter = true;
  if (!!returnLegViewModel) {
    returnLegArrivalTimeFilter = isTripViewModelWithinSelectedTimeBlocksFilter(
      returnLegViewModel.leg.arrivalTimeMinutes,
      selectedReturnLegArrivalTimeBlocks,
    );
  }

  let departDurationFilter = true;
  if (selectedMinDepartDuration !== undefined && selectedMaxDepartDuration !== undefined) {
    departDurationFilter =
      departureLegViewModel.leg.durationMinutes >= selectedMinDepartDuration &&
      departureLegViewModel.leg.durationMinutes <= selectedMaxDepartDuration;
  }

  let returnDurationFilter = true;
  if (
    !!returnLegViewModel &&
    selectedMinReturnDuration !== undefined &&
    selectedMaxReturnDuration !== undefined
  ) {
    returnDurationFilter =
      returnLegViewModel.leg.durationMinutes >= selectedMinReturnDuration &&
      returnLegViewModel.leg.durationMinutes <= selectedMaxReturnDuration;
  }

  let stopoverDuration = true;
  if (selectedMinStopoverDuration !== undefined && selectedMaxStopoverDuration !== undefined) {
    const highestStopoverDurationOfBothLeg = Math.max(
      departureLegViewModel.leg.stopoverDurationMinutes,
      !!returnLegViewModel ? returnLegViewModel.leg.stopoverDurationMinutes : 0,
    );

    stopoverDuration =
      highestStopoverDurationOfBothLeg >= selectedMinStopoverDuration &&
      highestStopoverDurationOfBothLeg <= selectedMaxStopoverDuration;
  }

  let tripWithSameAirlineFilter = true;
  if (isTripWithSameAirline) {
    const departureLegAirportCodes = departureLegViewModel.leg.airlineCodes;
    const returnLegAirportCodes = !!returnLegViewModel
      ? returnLegViewModel.leg.airlineCodes
      : undefined;

    tripWithSameAirlineFilter =
      departureLegAirportCodes.length === 1 &&
      (!!returnLegAirportCodes
        ? returnLegAirportCodes.length === 1 &&
          departureLegAirportCodes[0] === returnLegAirportCodes[0]
        : true);
  }

  let allianceCodesFilter =
    selectedAllianceCodes.length > 0
      ? selectedAllianceCodes.some((selectedAllianceCode) =>
          departureLegViewModel.leg.allianceCodes.includes(selectedAllianceCode),
        ) &&
        (!!returnLegViewModel
          ? selectedAllianceCodes.some((selectedAllianceCode) =>
              returnLegViewModel.leg.allianceCodes.includes(selectedAllianceCode),
            )
          : true)
      : true;

  let airlineCodesfilter =
    selectedAirlineCodes.length > 0
      ? selectedAirlineCodes.some((selectedAirlineCode) =>
          departureLegViewModel.leg.airlineCodes
            .map((code) => code.toLowerCase())
            .includes(selectedAirlineCode.toLowerCase()),
        ) &&
        (!!returnLegViewModel
          ? selectedAirlineCodes.some((selectedAirlineCode) =>
              returnLegViewModel.leg.airlineCodes
                .map((code) => code.toLowerCase())
                .includes(selectedAirlineCode.toLowerCase()),
            )
          : true)
      : true;

  const paymentMethodFilter =
    selectedPaymentMethodIds.length > 0
      ? selectedPaymentMethodIds.some((paymentMethod) =>
          fareViewModels.some((fareViewModel) =>
            fareViewModel.fare.paymentFees.some(
              (paymentFee) => paymentFee.paymentMethodId === paymentMethod,
            ),
          ),
        )
      : true;

  let originAirportCodes =
    selectedOriginAirportCodes.length > 0
      ? selectedOriginAirportCodes.some(
          (selectedOriginAirportCode) =>
            departureLegViewModel.leg.departureAirportCode.toLowerCase() ===
            selectedOriginAirportCode.toLowerCase(),
        ) &&
        (!!returnLegViewModel
          ? selectedOriginAirportCodes.some(
              (selectedOriginAirportCode) =>
                returnLegViewModel.leg.arrivalAirportCode.toLowerCase() ===
                selectedOriginAirportCode.toLowerCase(),
            )
          : true)
      : true;

  let destinationAirportCodes =
    selectedDestinationAirportCodes.length > 0
      ? selectedDestinationAirportCodes.some(
          (selectedDestinationAirportCode) =>
            departureLegViewModel.leg.arrivalAirportCode.toLowerCase() ===
            selectedDestinationAirportCode.toLowerCase(),
        ) &&
        (!!returnLegViewModel
          ? selectedDestinationAirportCodes.some(
              (selectedDestinationAirportCode) =>
                returnLegViewModel.leg.departureAirportCode.toLowerCase() ===
                selectedDestinationAirportCode.toLowerCase(),
            )
          : true)
      : true;

  let noChangeOfAirportAtStopoverFilter = true;
  if (isNoChangeOfAirportAtStopover) {
    let isNoChangeOfAirportAtStopoverForDepartureLeg = true;
    let isNoChangeOfAirportAtStopoverForReturnLeg = true;

    if (departureLegViewModel.segmentViewModels.length > 1) {
      for (let i = 0; i < departureLegViewModel.segmentViewModels.length - 1; i++) {
        const currentSegment = departureLegViewModel.segmentViewModels[i].segment;
        const nextSegment = departureLegViewModel.segmentViewModels[i + 1].segment;

        if (currentSegment.arrivalAirportCode !== nextSegment.departureAirportCode) {
          isNoChangeOfAirportAtStopoverForDepartureLeg = false;
          break;
        }
      }
    }

    if (!!returnLegViewModel && returnLegViewModel.segmentViewModels.length > 1) {
      for (let i = 0; i < returnLegViewModel.segmentViewModels.length - 1; i++) {
        const currentSegment = returnLegViewModel.segmentViewModels[i].segment;
        const nextSegment = returnLegViewModel.segmentViewModels[i + 1].segment;

        if (currentSegment.arrivalAirportCode !== nextSegment.departureAirportCode) {
          isNoChangeOfAirportAtStopoverForReturnLeg = false;
          break;
        }
      }
    }

    noChangeOfAirportAtStopoverFilter =
      isNoChangeOfAirportAtStopoverForDepartureLeg && isNoChangeOfAirportAtStopoverForReturnLeg;
  }

  const stopoverAirportCodesFilter =
    selectedStopoverAirportCodes.length > 0
      ? selectedStopoverAirportCodes.some((selectedStopoverAirportCode) =>
          departureLegViewModel.leg.stopoverAirportCodes.includes(selectedStopoverAirportCode),
        ) ||
        (!!returnLegViewModel
          ? selectedStopoverAirportCodes.some((selectedStopoverAirportCode) =>
              returnLegViewModel.leg.stopoverAirportCodes.includes(selectedStopoverAirportCode),
            )
          : false)
      : true;

  let matchProvidersAndProviderCodesFilter = true;

  if (selectedFareProviderCodes.length > 0 || isBookOnWego || isBookWithAirlines) {
    matchProvidersAndProviderCodesFilter = fareViewModels.some(({ provider, fare }) => {
      return matchFareFilterCriteria(
        provider,
        fare,
        selectedFareProviderCodes,
        isBookOnWego,
        isBookWithAirlines,
      );
    });
  }

  return (
    paymentMethodFilter &&
    stopCodesFilter &&
    matchProvidersAndProviderCodesFilter &&
    priceFilter &&
    (isNotOvernightFlight
      ? !departureLegViewModel.leg.overnight &&
        (!!returnLegViewModel ? !returnLegViewModel.leg.overnight : true)
      : true) &&
    (isNotLongStopoverFlight
      ? !departureLegViewModel.leg.longStopover &&
        (!!returnLegViewModel ? !returnLegViewModel.leg.longStopover : true)
      : true) &&
    departLegDepartureTimeFilter &&
    departLegArrivalTimeFilter &&
    returnLegDepartureTimeFilter &&
    returnLegArrivalTimeFilter &&
    departDurationFilter &&
    returnDurationFilter &&
    stopoverDuration &&
    tripWithSameAirlineFilter &&
    allianceCodesFilter &&
    airlineCodesfilter &&
    originAirportCodes &&
    destinationAirportCodes &&
    noChangeOfAirportAtStopoverFilter &&
    stopoverAirportCodesFilter
  );
};

export const getFlightPriceFilterMinimumDistance = (minPrice: number, maxPrice: number) => {
  const priceRange = maxPrice - minPrice;

  if (priceRange < 101) {
    return 1;
  }

  if (priceRange < 1001) {
    return 10;
  }

  return 20;
};

export const groupTripViewModelBasedOnOutboundAirlineCodeAndPriceMap = (
  tripViewModels: Array<TripViewModel>,
  selectedFareProviderCodes: Array<string>,
  isBookOnWego: boolean,
  isBookWithAirlines: boolean,
): { [key: string]: Array<TripViewModel> } => {
  const groupedTripViewModelMap: { [key: string]: Array<TripViewModel> } = tripViewModels.reduce(
    (accumulator: { [key: string]: Array<TripViewModel> }, tripViewModel: TripViewModel) => {
      const tripFare: FlightFare = sortFlightFares(
        tripViewModel.fareViewModels
          .filter((fareViewModel) => {
            const { provider, fare } = fareViewModel;
            return matchFareFilterCriteria(
              provider,
              fare,
              selectedFareProviderCodes,
              isBookOnWego,
              isBookWithAirlines,
            );
          })
          .map((fareViewModel) => fareViewModel.fare),
      )[0];

      const tripOutboundLeg = tripViewModel.departureLegViewModel.leg;

      const groupKey = `${tripOutboundLeg.airlineCodes[0]}-${tripFare.price.amount}`;

      if (!!accumulator[groupKey]) {
        const tripDurationMinutes = durationMinutes(tripViewModel);

        for (let i = 0; i < accumulator[groupKey].length; i++) {
          if (
            i === accumulator[groupKey].length - 1 ||
            tripDurationMinutes < durationMinutes(accumulator[groupKey][i])
          ) {
            accumulator[groupKey].splice(i, 0, tripViewModel);
            break;
          }
        }
      } else {
        accumulator[groupKey] = [tripViewModel];
      }

      return accumulator;
    },
    {},
  );

  return groupedTripViewModelMap;
};

export const matchFareFilterCriteria = (
  provider: FlightMetaSearchProvider,
  fare: { price: FlightPrice; refundable: boolean },
  selectedFareProviderCodes: Array<string>,
  isBookOnWego: boolean,
  isBookWithAirlines: boolean,
): boolean => {
  let matchesProvidersFilter = true;
  let matchesProviderCodesFilter = true;

  if (selectedFareProviderCodes.length > 0 || isBookOnWego || isBookWithAirlines) {
    // Match by providers
    if (isBookOnWego && isBookWithAirlines) {
      matchesProvidersFilter = provider?.wegoFare || provider?.type === 'airline';
    } else if (isBookOnWego) {
      matchesProvidersFilter = provider?.wegoFare;
    } else if (isBookWithAirlines) {
      matchesProvidersFilter = provider?.type === 'airline';
    }

    // Match by provider codes
    if (selectedFareProviderCodes.length > 0) {
      matchesProviderCodesFilter = selectedFareProviderCodes.includes(provider?.code);
    }
  }
  return matchesProvidersFilter && matchesProviderCodesFilter;
};

export const mergeMinMax = (arr: (NumberRange | undefined)[]): NumberRange => {
  const items = arr.filter((item) => Boolean(item)) as NumberRange[];

  return items.reduce(
    (acc, item) => {
      acc.max = Math.max(acc.max, item.max);
      acc.min = Math.min(acc.min, item.min);
      return acc;
    },
    { max: -Infinity, min: Infinity },
  );
};

export const getFlightLocationName = (location: FlightLocation | FlightPopularLocation): string => {
  const name = [location.name];

  if (location.countryName !== location.name) {
    name.push(location.countryName);
  }
  return name.join(', ');
};

export const getFlightLocationCityAndCountryName = (
  location: FlightLocation | FlightPopularLocation,
): string => {
  const name = [location.countryName];

  if (location.type === LocationType.Airport && location.countryName !== location.cityName) {
    name.unshift(location.cityName);
  }
  return name.join(', ');
};

export const flightLocationLabel = (location: FlightLocation): string => {
  return `${location.name} (${location.code})`;
};

const durationMinutes = (tripViewModel: TripViewModel): number => {
  const departureLegDurationMinutes = tripViewModel.departureLegViewModel.leg.durationMinutes;
  const returnLegDurationMinutes = tripViewModel.returnLegViewModel?.leg.durationMinutes ?? 0;
  return departureLegDurationMinutes + returnLegDurationMinutes;
};

export const convertFlightSearchResultSearchLegCityAndAirportToFlightLocation = (
  city: FlightSearchResultSearchLegCity,
  airport: FlightMetaSearchAirport | undefined,
): FlightLocation => {
  const locationType = !!airport ? LocationType.Airport : LocationType.City;

  return {
    airportCount: 0, // Unknown value

    cityCode: city.code,
    cityId: 0, // Unknown value
    cityName: city.name,
    cityPermalink: '', // Unknown value

    countryCode: city.countryCode,
    countryId: 0, // Unknown value
    countryName: city.countryName,
    countryPermalink: '', // Unknown value

    distance: 0, // Unknown value
    hotelCount: 0, // Unknown value
    id: 0, // Unknown value
    lat: 0, // Unknown value
    long: 0, // Unknown value
    name: locationType === LocationType.Airport ? airport!.name : city.name,
    permalink: '', // Unknown value

    type: locationType,
    worldRegionCode: city.worldRegionCode,
    worldRegionId: 0, // Unknown value
    worldRegionName: city.worldRegionName,
    worldRegionPermalink: '', // Unknown value

    code: locationType === LocationType.Airport ? airport!.code : city.code,

    airportCode: airport?.code, // Doesn't have this property if is with params &types[]=city
    airportId: undefined, // Doesn't have this property if is with params &types[]=city
    airportName: airport?.name, // Doesn't have this property if is with params &types[]=city
    airportPermalink: undefined, // Doesn't have this property if is with params &types[]=city
  };
};

export const getTripSortTypeSearchParamValue = (sortType: TripSortType): string => {
  switch (sortType) {
    case TripSortType.Price:
      return 'price';
    case TripSortType.Duration:
      return 'duration';
    case TripSortType.Score:
      return 'score';
    case TripSortType.OutboundTime:
      return 'outbound_departure_time';
    case TripSortType.InboundTime:
      return 'inbound_departure_time';
    default:
      return 'price';
  }
};

export function updateSortParamsForNormalSearch(
  searchParams: URLSearchParams,
  selectedSort: TripSortType,
  selectedOrder: SortOrder,
) {
  // Sort type search param
  searchParams.delete('sort');
  searchParams.append('sort', getTripSortTypeSearchParamValue(selectedSort));

  // Sort order type search param
  searchParams.delete('order');
  searchParams.append('order', selectedOrder === SortOrder.Ascending ? 'asc' : 'desc');

  return searchParams;
}

export const getTripSortType = (sortTypeSearchParam: string): TripSortType => {
  switch (sortTypeSearchParam.toLowerCase()) {
    case 'price':
      return TripSortType.Price;
    case 'duration':
      return TripSortType.Duration;
    case 'score':
      return TripSortType.Score;
    case 'outbound_departure_time':
      return TripSortType.OutboundTime;
    case 'inbound_departure_time':
      return TripSortType.InboundTime;
    default:
      return TripSortType.Price;
  }
};

export const formatCurrency = (currency: string, amount: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
};

export const getSalutation = (genderCode: number, paxType: PassengerType): Salutation => {
  let title = Salutation.Mr;
  if (genderCode === 0) {
    if (paxType === 'ADULT') {
      title = Salutation.Mr;
    } else {
      title = Salutation.Master;
    }
  } else {
    if (paxType === 'ADULT') {
      title = Salutation.Ms;
    } else {
      title = Salutation.Miss;
    }
  }
  return title;
};

export function getCabinClass(cabinClass: string, translations: { [key: string]: string }): string {
  switch (cabinClass) {
    case 'economy':
      return translations.economy;
    case 'premium_economy':
      return translations.form_cabin_premium_economy;
    case 'business':
      return translations.business_class;
    case 'first':
      return translations.first_class;
    default:
      return '';
  }
}

export function flightDateDisplay(date: Date, translations: any, locale: string): string {
  return dateDisplayFormat(
    date,
    translations.short_months,
    translations.short_weekdays,
    locale === 'fa',
  );
}

export function getPaymentCard(
  code:
    | 'AMERICAN_EXPRESS'
    | 'APPLE_PAY'
    | 'MADA'
    | 'MADA_CREDIT'
    | 'MADA_DEBIT'
    | 'MADA_DEFERRED_DEBIT'
    | 'MADA_PREPAID'
    | 'KNET'
    | 'MASTERCARD'
    | 'MASTERCARD_CREDIT'
    | 'MASTERCARD_DEBIT'
    | 'MASTERCARD_DEFERRED_DEBIT'
    | 'MASTERCARD_PREPAID'
    | 'VISA'
    | 'VISA_CREDIT'
    | 'VISA_DEBIT'
    | 'VISA_DEFERRED_DEBIT'
    | 'VISA_PREPAID'
    | 'bnpl_3_installments'
    | 'bnpl_4_installments'
    | 'xpay',
): PaymentCard | null {
  switch (code) {
    case 'VISA':
    case 'VISA_CREDIT':
    case 'VISA_DEBIT':
    case 'VISA_DEFERRED_DEBIT':
    case 'VISA_PREPAID':
      return PaymentCard.Visa;
    case 'MASTERCARD':
    case 'MASTERCARD_CREDIT':
    case 'MASTERCARD_DEBIT':
    case 'MASTERCARD_DEFERRED_DEBIT':
    case 'MASTERCARD_PREPAID':
      return PaymentCard.Mastercard;
    case 'MADA':
    case 'MADA_CREDIT':
    case 'MADA_DEBIT':
    case 'MADA_DEFERRED_DEBIT':
    case 'MADA_PREPAID':
      return PaymentCard.Mada;
    case 'KNET':
      return PaymentCard.Knet;
    case 'AMERICAN_EXPRESS':
      return PaymentCard.Amex;
    case 'bnpl_3_installments':
    case 'bnpl_4_installments':
      return PaymentCard.Tabby;
    case 'xpay':
      return PaymentCard.Xpay;
    default:
      return null;
  }
}

export function getPaymentCardName(paymentCard: PaymentCard | null): string {
  switch (paymentCard) {
    case PaymentCard.Visa:
      return 'visa';
    case PaymentCard.Mastercard:
      return 'masterCard';
    case PaymentCard.Mada:
      return 'mada';
    case PaymentCard.Knet:
      return 'knet';
    case PaymentCard.Amex:
      return 'amex';
    case PaymentCard.Tabby:
      return 'tabby';
    case PaymentCard.Xpay:
      return 'xpay';
    default:
      return 'invalidCard';
  }
}

export function getPaymentCode(
  paymentScheme: 'AMERICAN EXPRESS' | 'American Express' | 'Mastercard' | 'Visa' | 'visa',
): string {
  const scheme = paymentScheme.toUpperCase();
  switch (scheme) {
    case 'VISA':
      return 'visa';
    case 'MASTERCARD':
      return 'masterCard';
    case 'AMERICAN EXPRESS':
      return 'amex';
    default:
      return 'invalidCard';
  }
}

// TODO temporary function for testing on staging, production testing is done
export function getGoogleFlightSearchUrl(
  flightSearch: FlightSearch,
  fare: FlightFare,
  departSegments: Array<Segment>,
  returnSegments: Array<Segment>,
  POS: CurrentSite,
  currencyCode: string,
  locale: string,
): void {
  if (!!flightSearch) {
    const googleSearchQuery: any = {
      Adult: flightSearch.adultCount,
      Child: flightSearch.childrenCount,
      InfantLap: flightSearch.infantCount,
      PointOfSaleCountry: POS.countryCode,
      UserCurrency: currencyCode,
      DisplayedPrice: fare.price.totalAmount,
      DisplayedPriceCurrency: currencyCode,
      UserLanguage: locale,
      ReferralId: 'GOOG_GFS_712',
      TripType: flightSearch['tripType'] === 'ONE_WAY' ? 'OneWay' : 'RoundTrip',
      Slice1: Array.from({ length: departSegments.length }, (_, i) => i + 1),
      TsCode: '5aa90',
      wg_source: 'meta_distribution',
      wg_medium: 'gfs',
      wg_internal_campaign: 'fbl',
    };

    const allSegments = [...departSegments, ...returnSegments];

    allSegments.forEach((segment, index) => {
      googleSearchQuery[`Cabin${index + 1}`] = segment.cabin;
      googleSearchQuery[`Carrier${index + 1}`] = segment.airlineCode;
      googleSearchQuery[`FlightNumber${index + 1}`] = segment.designatorCode.replace(
        segment.airlineCode,
        '',
      );
      googleSearchQuery[`DepartureDate${index + 1}`] = segment.departureDateTime.split('.')[0];
      googleSearchQuery[`ArrivalDate${index + 1}`] = segment.arrivalDateTime.split('.')[0];
      googleSearchQuery[`Origin${index + 1}`] = segment.departureAirportCode;
      googleSearchQuery[`Destination${index + 1}`] = segment.arrivalAirportCode;
      googleSearchQuery[`BookingCode${index + 1}`] =
        segment.cabin === 'economy' ? 'Y' : segment.cabin === 'business' ? 'J' : 'F';
    });

    if (returnSegments.length > 0) {
      googleSearchQuery.Slice2 = Array.from(
        { length: returnSegments.length },
        (_, i) => i + 1 + departSegments.length,
      );
    }

    let queryParams = new URLSearchParams(googleSearchQuery);
    console.log(`${window.location.origin}/flights/handoff?${queryParams.toString()}&sig=qwerty`);
    console.log(googleSearchQuery, 'googleSearchQuery');
  } else {
    console.log('Cannot generate query...');
  }
}

export function convertDurationInMinutesToHoursAndMinutes(
  travelTimeInMinutes: number,
  locale: string,
  translations: Translations,
): string {
  let travelTimeHours =
    Math.floor(travelTimeInMinutes / 60) &&
    Math.floor(travelTimeInMinutes / 60)
      .toString()
      .padStart(2, '0');
  let travelTimeMinutes =
    travelTimeInMinutes % 60 &&
    Math.round(travelTimeInMinutes % 60)
      .toString()
      .padStart(2, '0');

  travelTimeHours = travelTimeHours && translateNumber(travelTimeHours, locale === 'fa');
  travelTimeMinutes = travelTimeMinutes && translateNumber(travelTimeMinutes, locale === 'fa');

  const hourString = travelTimeHours ? `${travelTimeHours}${translations.hour_short_lbl}` : '';
  const separator = travelTimeHours && travelTimeMinutes ? ' ' : '';
  const minuteString = travelTimeMinutes
    ? `${travelTimeMinutes}${translations.minute_short_lbl}`
    : '';

  const travelTime = `${hourString}${separator}${minuteString}`;

  return travelTime;
}

export const getPassengerIdTypeFromDynamicForm = (dynamicForm: FormField[], country?: Country) => {
  const passengerTypeFormRules = convertListToHashMap(dynamicForm, 'field');

  const passportRule = passengerTypeFormRules['PASSPORT'];
  const nationalIdRule = passengerTypeFormRules['NATIONAL_ID'];
  const passportOrNationalIdRule = passengerTypeFormRules['PASSPORT_OR_NATIONAL_ID'];

  if (passportOrNationalIdRule?.conditions.nationalities.includes(country?.code)) {
    return PassengerIdType.Passport;
  }

  if (passportRule?.conditions.nationalities.includes(country?.code)) {
    return PassengerIdType.Passport;
  }

  if (nationalIdRule?.conditions.nationalities.includes(country?.code)) {
    return PassengerIdType.NationalId;
  }

  return undefined;
};

export const getTripTypeStringForGenzoTracking = (tripType: TripType): string => {
  switch (tripType) {
    case TripType.OneWay:
      return 'oneway';
    case TripType.RoundTrip:
      return 'roundtrip';
    case TripType.MultiCity:
      return 'multicity';
  }
};

export const formatSearchLocationCodeWithCityPrefix = (searchLocation: FlightLocation): string => {
  return searchLocation.type === LocationType.Airport
    ? searchLocation.code.toUpperCase()
    : `c${searchLocation.cityCode.toUpperCase()}`;
};

const formatNearbyLocationCodeWithCityPrefix = (location: SuggestedNearbyLocationState) => {
  return `${location.type === 'CITY' ? 'c' : ''}${location.code}`;
};

export const constructNearbyRoutesQuery = (
  suggestedDepartures: Array<SuggestedNearbyLocationState>,
  suggestedArrivals: Array<SuggestedNearbyLocationState>,
  mainDeparture: string,
  mainArrival: string,
): string => {
  const checkedSuggestedDepartures = suggestedDepartures.filter((location) => location.checked);
  const checkedSuggestedArrivals = suggestedArrivals.filter((location) => location.checked);

  const suggestedRoutesFromSuggestedDeparturesAndMainArrival = checkedSuggestedDepartures.map(
    (location) => `${formatNearbyLocationCodeWithCityPrefix(location)}-${mainArrival}`,
  );
  const suggestedRoutesFromSuggestedArrivalsAndMainDeparture = checkedSuggestedArrivals.map(
    (location) => `${mainDeparture}-${formatNearbyLocationCodeWithCityPrefix(location)}`,
  );

  const suggestedRoutesFromSuggestedAirports = checkedSuggestedDepartures.flatMap((departure) =>
    checkedSuggestedArrivals.map(
      (arrival) =>
        `${formatNearbyLocationCodeWithCityPrefix(
          departure,
        )}-${formatNearbyLocationCodeWithCityPrefix(arrival)}`,
    ),
  );

  return suggestedRoutesFromSuggestedDeparturesAndMainArrival
    .concat(
      suggestedRoutesFromSuggestedArrivalsAndMainDeparture,
      suggestedRoutesFromSuggestedAirports,
    )
    .toString();
};

const constructNearbyRoutesLegItem = (
  departureCode: string,
  arrivalCode: string,
): NearbyRoutesLegItem => {
  const isDepartureCity = departureCode.startsWith('c');
  const isArrivalCity = arrivalCode.startsWith('c');

  return {
    ...(isDepartureCity
      ? { departureCityCode: departureCode.substring(1) }
      : { departureAirportCode: departureCode }),
    ...(isArrivalCity
      ? { arrivalCityCode: arrivalCode.substring(1) }
      : { arrivalAirportCode: arrivalCode }),
  };
};

export const parseNearbyRoutesFromParams = (
  nearbyRoutesFromParam: string[],
  isRoundTrip: boolean,
): Array<NearbyRoutesPayloadItem> => {
  return nearbyRoutesFromParam.map((route) => {
    const codes = route.split('-');
    const departure = codes[0];
    const arrival = codes[1];

    if (isRoundTrip) {
      return {
        legs: [
          constructNearbyRoutesLegItem(departure, arrival),
          constructNearbyRoutesLegItem(arrival, departure),
        ],
      };
    } else {
      return {
        legs: [constructNearbyRoutesLegItem(departure, arrival)],
      };
    }
  });
};

export const parseNearbyRoutesFromAPIResponse = (
  actualDepartureCode: string,
  actualArrivalCode: string,
  nearbyRoutes: Array<{
    legs: Array<FlightSearchResultSearchLeg>;
  }>,
): {
  nearbyDepartureLocations: Array<SuggestedNearbyLocationState>;
  nearbyArrivalLocations: Array<SuggestedNearbyLocationState>;
} => {
  // Only take the 1st because if round trip, the 2nd one is reversed from the 1st
  const flatNearbyRoutes = nearbyRoutes.map((route) => route.legs[0]);

  const nearbyDepartureLocations: SuggestedNearbyLocationState[] = [];
  const nearbyArrivalLocations: SuggestedNearbyLocationState[] = [];

  flatNearbyRoutes.map((nearbyRoute) => {
    const [nearbyRouteDepartureCode, nearbyRouteArrivalCode] = nearbyRoute.id.split(':');
    if (
      nearbyRouteDepartureCode !== actualDepartureCode &&
      !isNearbyRouteAlreadyAddedToSuggestedLocations(
        nearbyRouteDepartureCode,
        nearbyDepartureLocations,
      )
    ) {
      nearbyDepartureLocations.push({
        checked: true,
        code: nearbyRoute.departureAirport?.code || nearbyRoute.departureCity.code,
        name: nearbyRoute.departureAirport?.name || nearbyRoute.departureCity.name,
        type: nearbyRoute.departureAirport ? 'AIRPORT' : 'CITY',
      });
    }

    if (
      nearbyRouteArrivalCode !== actualArrivalCode &&
      !isNearbyRouteAlreadyAddedToSuggestedLocations(nearbyRouteArrivalCode, nearbyArrivalLocations)
    ) {
      nearbyArrivalLocations.push({
        checked: true,
        code: nearbyRoute.arrivalAirport?.code || nearbyRoute.arrivalCity.code,
        name: nearbyRoute.arrivalAirport?.name || nearbyRoute.arrivalCity.name,
        type: nearbyRoute.arrivalAirport ? 'AIRPORT' : 'CITY',
      });
    }
  });

  return {
    nearbyDepartureLocations,
    nearbyArrivalLocations,
  };
};

function isNearbyRouteAlreadyAddedToSuggestedLocations(
  newNearbyRouteCode: string,
  suggestedNearbyLocations: SuggestedNearbyLocationState[],
) {
  return suggestedNearbyLocations.some((location) => {
    const locationCode = location.type === 'CITY' ? `c${location.code}` : location.code;
    return newNearbyRouteCode === locationCode;
  });
}

export const getSegmentDetails = (departureLeg: Leg, returnLeg?: Leg) => {
  let tripSegments = departureLeg.segments;
  if (!!returnLeg) {
    tripSegments = [...tripSegments, ...returnLeg.segments];
  }
  return tripSegments.map((segment) => ({
    airline_codes: segment.airlineCode ?? '',
    arrival_airport_code: segment.arrivalAirportCode ?? '',
    arrival_city_code: segment.arrivalAirportCode ?? '',
    arrival_city_name: segment.arrivalCityName ?? '',
    arrival_continent_code: '',
    arrival_continent_name: '',
    arrival_country_code: segment.arrivalCountryCode ?? '',
    arrival_country_name: segment.arrivalCountryCode
      ? getCountryConfig(segment.arrivalCountryCode)?.name
      : '',
    departure_airport_code: segment.departureAirportCode ?? '',
    departure_city_code: segment.departureAirportCode ?? '',
    departure_city_name: segment.departureCityName ?? '',
    departure_continent_code: '',
    departure_continent_name: '',
    departure_country_code: segment.departureCountryCode ?? '',
    departure_country_name: segment.departureCountryCode
      ? getCountryConfig(segment.departureCountryCode)?.name
      : '',
    inbound_date: segment.arrivalDateTime ?? '',
    outbound_date: segment.departureDateTime ?? '',
  }));
};

function getFlightSearchLocationCodesFromLocalStorage(): Array<string> {
  const flightSearch = localStorageGet<FlightSearch>('flightSearch');
  const flightRecentSearches = localStorageGet<Array<FlightSearch>>('flightRecentSearches');
  let locationCodes = [];

  if (flightSearch?.departureLocation) {
    locationCodes.push(
      flightSearch.departureLocation.airportCode ?? `c${flightSearch.departureLocation.cityCode}`,
    );
  }

  if (flightSearch?.arrivalLocation) {
    locationCodes.push(
      flightSearch.arrivalLocation.airportCode ?? `c${flightSearch.arrivalLocation.cityCode}`,
    );
  }

  if (!!flightRecentSearches) {
    flightRecentSearches.forEach((recentSearch) => {
      if (recentSearch?.departureLocation) {
        locationCodes.push(
          recentSearch.departureLocation.airportCode ??
            `c${recentSearch.departureLocation.cityCode}`,
        );
      }

      if (recentSearch?.arrivalLocation) {
        locationCodes.push(
          recentSearch.arrivalLocation.airportCode ?? `c${recentSearch.arrivalLocation.cityCode}`,
        );
      }
    });
  }
  return locationCodes;
}

function frameUpdatedFlightSearch(
  flightSearch: FlightSearch,
  locationDetails: Array<FlightLocation>,
  locale: string,
): FlightSearch {
  let departureLocation;
  let arrivalLocation;

  if (!!flightSearch?.departureLocation) {
    departureLocation = locationDetails.find((locationDetail) => {
      if (flightSearch.departureLocation!.airportCode) {
        return locationDetail.airportCode === flightSearch.departureLocation!.airportCode;
      } else {
        return locationDetail.cityCode === flightSearch.departureLocation!.cityCode;
      }
    });
  }

  if (!!flightSearch?.arrivalLocation) {
    arrivalLocation = locationDetails.find((locationDetail) => {
      if (flightSearch.arrivalLocation!.airportCode) {
        return locationDetail.airportCode === flightSearch.arrivalLocation!.airportCode;
      } else {
        return locationDetail.cityCode === flightSearch.arrivalLocation!.cityCode;
      }
    });
  }

  return {
    ...flightSearch,
    departureLocation,
    arrivalLocation,
    locale,
  };
}

function frameUpdatedFlightRecentSearches(
  flightRecentSearches: Array<FlightSearch>,
  locationDetails: Array<FlightLocation>,
  locale: string,
): Array<FlightSearch> {
  return flightRecentSearches.map((flightRecentSearch) => {
    return frameUpdatedFlightSearch(flightRecentSearch, locationDetails, locale);
  });
}

export async function getUpdatedFlightSearchDetails(
  apiBaseUrl: string,
  locale: string,
  cancelToken: CancelToken,
) {
  const flightSearch = localStorageGet<FlightSearch>('flightSearch');
  const flightRecentSearches = localStorageGet<Array<FlightSearch>>('flightRecentSearches');
  let locationCodes = getFlightSearchLocationCodesFromLocalStorage();

  const locationDetails = await getLocationDetails(apiBaseUrl, locale, locationCodes, cancelToken);

  let newFlightSearch: FlightSearch | null = flightSearch;
  let newFlightRecentSearches: Array<FlightSearch> | null = flightRecentSearches;

  if (!!flightSearch) {
    newFlightSearch = frameUpdatedFlightSearch(flightSearch, locationDetails, locale);
  }

  if (!!flightRecentSearches) {
    newFlightRecentSearches = frameUpdatedFlightRecentSearches(
      flightRecentSearches,
      locationDetails,
      locale,
    );
  }

  return {
    flightSearch: newFlightSearch,
    flightRecentSearches: newFlightRecentSearches,
  };
}

export const sortPaymentMethodsBasedOnPopularityAndSelection = (
  availablePaymentMethods: PaymentMethod[],
  selectedPaymentIds: number[],
) => {
  return availablePaymentMethods.sort(
    (aPaymentMethod: PaymentMethod, bPaymentMethod: PaymentMethod) => {
      const isAPaymentMethodSelected = selectedPaymentIds.includes(aPaymentMethod.id);
      const isBPaymentMethodSelected = selectedPaymentIds.includes(bPaymentMethod.id);

      if (isAPaymentMethodSelected && isBPaymentMethodSelected) {
        return 0;
      } else if (!isAPaymentMethodSelected && isBPaymentMethodSelected) {
        return 1;
      } else if (isAPaymentMethodSelected && !isBPaymentMethodSelected) {
        return -1;
      } else {
        if (!aPaymentMethod.isPopular && bPaymentMethod.isPopular) {
          return 1;
        } else if (aPaymentMethod.isPopular && !bPaymentMethod.isPopular) {
          return -1;
        } else {
          if (aPaymentMethod.name < bPaymentMethod.name) {
            return -1;
          } else if (aPaymentMethod.name > bPaymentMethod.name) {
            return 1;
          } else {
            return 0;
          }
        }
      }
    },
  );
};

// Read more here: https://stackoverflow.com/a/55652503
export const handleFocusInputIOS = (inputId: string) => {
  if (isIOS) {
    const fakeInput = document.createElement('input');
    fakeInput.setAttribute('type', 'text');
    fakeInput.style.position = 'absolute';
    fakeInput.style.opacity = '0';
    fakeInput.style.height = '0';
    fakeInput.style.fontSize = '16px'; // disable auto zoom

    document.body.prepend(fakeInput);
    fakeInput.focus();

    setTimeout(() => {
      const inputElement = document.getElementById(inputId);

      inputElement?.focus();
      fakeInput.remove();
    }, 500);
  }
};

export function filterTripViewModelBasedOnBookingOptions(
  tripViewModels: Array<TripViewModel>,
  isBookOnWego: boolean,
  isBookWithAirlines: boolean,
): Array<TripViewModel> {
  if (tripViewModels.length > 0 && (isBookWithAirlines || isBookOnWego)) {
    const filteredTripViewModelsBasedOnBookingOptions: Array<TripViewModel> = [];
    tripViewModels.forEach((tripViewModel) => {
      const filteredFareViewModels = tripViewModel?.fareViewModels?.filter(({ provider }) => {
        if (isBookWithAirlines && !isBookOnWego) {
          return provider.type === 'airline';
        }
        if (!isBookWithAirlines && isBookOnWego) {
          return provider.wegoFare;
        }
        return provider.wegoFare || provider.type === 'airline';
      });

      if (filteredFareViewModels?.length > 0) {
        tripViewModel.fareViewModels = filteredFareViewModels;
        filteredTripViewModelsBasedOnBookingOptions.push(tripViewModel);
      }
    });
    return filteredTripViewModelsBasedOnBookingOptions;
  } else {
    return tripViewModels;
  }
}

export function removeCombinedViewSortAndFilterParams(
  searchParams: URLSearchParams,
): URLSearchParams {
  searchParams.delete('selected_payment_methods');
  searchParams.delete('sort');
  searchParams.delete('order');
  searchParams.delete('flexibilities');
  searchParams.delete('stops');
  searchParams.delete('price');
  searchParams.delete('experience');
  searchParams.delete('depart_duration');
  searchParams.delete('return_duration');
  searchParams.delete('stopover_duration');
  searchParams.delete('trip_options');
  searchParams.delete('alliances');
  searchParams.delete('airlines');
  searchParams.delete('origin');
  searchParams.delete('destination');
  searchParams.delete('stopover_options');
  searchParams.delete('stopovers');
  searchParams.delete('provider_codes');
  searchParams.delete('providers');
  searchParams.delete('departLeg_departure_time_blocks');
  searchParams.delete('departLeg_arrival_time_blocks');
  searchParams.delete('returnLeg_departure_time_blocks');
  searchParams.delete('returnLeg_arrival_time_blocks');

  return searchParams;
}

export function mapStopCodesSearchParamToStopCode(stopCodesSearchParam: string): string {
  stopCodesSearchParam = stopCodesSearchParam.toUpperCase();
  if (stopCodesSearchParam === '0' || stopCodesSearchParam === 'DIRECT') {
    return 'DIRECT';
  }

  if (stopCodesSearchParam === '1' || stopCodesSearchParam === 'ONE_STOP') {
    return 'ONE_STOP';
  }

  return 'MORE_THAN_ONE_STOP';
}

export const hasCheapestTags = (tripViewModelTags: TripCardTag[] | undefined) => {
  if (tripViewModelTags) {
    return isArrayIncludesAnyValue(tripViewModelTags, CHEAPEST_TAGS_ARRAY);
  }
  return false;
};

export const getCheapestTagText = (
  tripViewModelTags: TripCardTag[] | undefined,
  translations: Translations,
  isTooltip: boolean = false,
) => {
  const tags = tripViewModelTags;

  if (tags?.length) {
    const activeTag = CHEAPEST_TAGS_ARRAY.find((t) => tags.includes(t));
    if (activeTag) {
      if (isTooltip) {
        return translations[`${activeTag}_tooltip`];
      }

      return translations[activeTag];
    }
    return '';
  }
};

export const getBaggageDetail = (
  passengerInfos: Array<BrandedFarePassengerInfo>,
  baggageId: number,
  paxCount: Record<string, number>,
) => {
  const paxInfo = {
    adult: 0,
    child: 0,
    infant: 0,
  };
  let legInfo = [
    {
      legIndex: 0,
      segmentIndexes: [] as number[],
      paxInfo: { ...paxInfo },
    },
  ];
  for (let passengerInfo of passengerInfos) {
    const { baggages } = passengerInfo;

    for (let legIndex = 0; legIndex < baggages.length; legIndex++) {
      const leg = baggages[legIndex];
      for (let segmentIndex = 0; segmentIndex < leg.length; segmentIndex++) {
        const segment = leg[segmentIndex];
        if (segment.includes(baggageId)) {
          legInfo[legIndex] = {
            legIndex,
            segmentIndexes: Array.from(
              new Set([...(legInfo[legIndex]?.segmentIndexes || []), segmentIndex]),
            ),
            paxInfo: {
              ...(legInfo[legIndex]?.paxInfo || { adult: 0, child: 0, infant: 0 }),
              [passengerInfo.type.toLowerCase() as keyof typeof paxInfo]: Number(
                paxCount[passengerInfo.type.toLowerCase()] || 0,
              ),
            },
          };
        }
      }
    }
  }

  return { legInfo };
};
