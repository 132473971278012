import { facebookSignOut, googleSignOut, wegoSignOut } from '@alexis/helpers/authentication';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { LoggedInType } from '@alexis/types/loggedInType';
import type { User } from '@alexis/types/user';
import type { Location } from 'location';
import React, { lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import LoginCallback from '@components/LoginCallback';

import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID, WEGO_CLIENT_ID } from '@constants/env';

import { useIsAuthNew } from '@hooks/useIsAuthNew';

import { getCoreConfigState } from '@redux/selectors';

import Home from '../components/home/Home';
import {
  homePaths,
  loginPaths,
  registerPaths,
  changePasswordPaths,
  resetPasswordPaths,
  sendConfirmationPaths,
  dataPrivacyPaths,
  walletOverviewPaths,
  walletWithdrawPaths,
  loginCallbackPaths,
} from '../helpers/path';
import { webEngageTrackLogin, webEngageTrackLogout } from '../helpers/webEngage';
import { changeUser, resetUser } from '../redux/actions/userActions';

const ChangePassword = lazy(
  () => import(/* webpackChunkName: "ChangePassword" */ '@alexis/components/ChangePassword'),
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "ResetPassword" */ '@alexis/components/ResetPassword'),
);
const ResendConfirmation = lazy(
  () =>
    import(/* webpackChunkName: "ResendConfirmation" */ '@alexis/components/ResendConfirmation'),
);
const DataPrivacy = lazy(
  () => import(/* webpackChunkName: "DataPrivacy" */ '@alexis/components/DataPrivacy'),
);
const WalletOverview = lazy(
  () => import(/* webpackChunkName: "WalletOverview" */ '../components/shopcash/WalletOverview'),
);
const WalletWithdraw = lazy(
  () => import(/* webpackChunkName: "WalletWithdraw" */ '../components/shopcash/WalletWithdraw'),
);
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '@alexis/components/Login'));
const SignUp = lazy(() => import(/* webpackChunkName: "SignUp" */ '@alexis/components/SignUp'));

interface HomeRoutesProps {
  apiBaseUrl: string;
  clientId: string | undefined;
  clientSessionId: string | undefined;
  currency: Currency;
  currentSite: CurrentSite;
  domainBasedApiBaseUrl: string;
  exchangeRate: ExchangeRate;
  homepage: Homepage | undefined;
  isRtl: boolean;
  locale: string;
  nearestCity: Location | undefined;
  translations: { [key: string]: string };
  user: User | null;
  shopCashUser: ShopCashUser | null;
}

const HomeRoutes: React.FC<HomeRoutesProps> = ({
  apiBaseUrl,
  clientId,
  clientSessionId,
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  homepage,
  isRtl,
  locale,
  nearestCity,
  translations,
  user,
  shopCashUser,
}): JSX.Element => {
  const hostname = window.location.hostname;
  const gtmAuth: string =
    isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)
      ? 'obU596jZkQ8pXq1df8v0OA'
      : 'JhTkGQKxflcbelVyldTavg';
  const gtmPreview: string =
    isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname) ? 'env-5' : 'env-2';

  const dispatch = useDispatch();
  const { login, isAuthNew, logout, usedNewLogin } = useIsAuthNew();
  const coreConfigs = useSelector(getCoreConfigState);

  const { search, state } = useLocation<{ from?: string }>();
  const history = useHistory();

  const handleOnBack = (): void => {
    if (!!state && !!state.from) {
      history.goBack();
    } else {
      history.replace({ pathname: '/', search: search });
    }
  };

  const handleGoToLogin = (): void => {
    if (isAuthNew) {
      login();
    } else {
      history.replace({ pathname: '/login', search: search, state: state });
    }
  };

  const handleGoToSendConfirmationEmail = (): void => {
    history.replace({ pathname: '/sendconfirmation', search: search, state: state });
  };

  const handleLogout = (): void => {
    if (usedNewLogin) {
      logout();
    } else {
      switch (user!.loggedInType) {
        case LoggedInType.GoogleOneTap:
          googleSignOut(domainBasedApiBaseUrl);
          break;
        case LoggedInType.GoogleSignIn:
          googleSignOut(domainBasedApiBaseUrl);
          break;
        case LoggedInType.Facebook:
          facebookSignOut(domainBasedApiBaseUrl, FACEBOOK_APP_ID);
          break;
        case LoggedInType.Wego:
          wegoSignOut(domainBasedApiBaseUrl);
          break;
      }
    }

    webEngageTrackLogout();
    dispatch(resetUser());
  };

  const handleOnLoginOrSignUp = (user: User): void => {
    dispatch(changeUser(user));
    webEngageTrackLogin(user);
    handleOnBack();
  };

  const isUsingNewLoginCallbackRoute =
    coreConfigs?.find((coreConfig) => coreConfig.key === 'mweb_login_callback_route')?.value ===
    '1';

  return (
    <Switch>
      <Route exact strict path={homePaths}>
        <Home
          clientId={clientId}
          clientSessionId={clientSessionId}
          domainBasedApiBaseUrl={domainBasedApiBaseUrl}
          gtmAuth={gtmAuth}
          gtmPreview={gtmPreview}
          homepage={homepage}
          nearestCity={nearestCity}
        />
      </Route>

      {isUsingNewLoginCallbackRoute && (
        <Route path={loginCallbackPaths}>
          <LoginCallback />
        </Route>
      )}

      {!isAuthNew && (
        <Route exact strict path={loginPaths}>
          <Login
            locale={locale}
            translations={translations}
            isRtl={isRtl}
            apiBaseUrl={domainBasedApiBaseUrl}
            googleClientId={GOOGLE_CLIENT_ID}
            facebookAppId={FACEBOOK_APP_ID}
            wegoClientId={WEGO_CLIENT_ID}
            onBack={handleOnBack}
            onLogin={handleOnLoginOrSignUp}
            onSignUp={() =>
              history.replace({ pathname: '/register', search: search, state: state })
            }
            onForgetPassword={() =>
              history.replace({ pathname: '/resetpassword', search: search, state: state })
            }
            onSendConfirmationEmail={handleGoToSendConfirmationEmail}
          />
        </Route>
      )}

      {!isAuthNew && (
        <Route path={registerPaths}>
          <SignUp
            locale={locale}
            translations={translations}
            isRtl={isRtl}
            apiBaseUrl={domainBasedApiBaseUrl}
            googleClientId={GOOGLE_CLIENT_ID}
            facebookAppId={FACEBOOK_APP_ID}
            wegoClientId={WEGO_CLIENT_ID}
            countryCode={currentSite.countryCode}
            onBack={handleOnBack}
            onLogin={handleGoToLogin}
            onSignUp={handleOnLoginOrSignUp}
            onSendConfirmationEmail={handleGoToSendConfirmationEmail}
            onContinue={handleOnBack}
          />
        </Route>
      )}

      <Route path={changePasswordPaths}>
        <ChangePassword
          locale={locale}
          translations={translations}
          isRtl={isRtl}
          apiBaseUrl={domainBasedApiBaseUrl}
          onBack={handleOnBack}
          onChangePassword={handleLogout}
          onGoToLogin={handleGoToLogin}
        />
      </Route>

      <Route path={resetPasswordPaths}>
        <ResetPassword
          locale={locale}
          translations={translations}
          isRtl={isRtl}
          apiBaseUrl={domainBasedApiBaseUrl}
          onBack={handleOnBack}
          onGoToLogin={handleGoToLogin}
        />
      </Route>

      <Route path={sendConfirmationPaths}>
        <ResendConfirmation
          locale={locale}
          translations={translations}
          isRtl={isRtl}
          apiBaseUrl={domainBasedApiBaseUrl}
          onBack={handleOnBack}
          onGoToLogin={handleGoToLogin}
        />
      </Route>

      <Route path={dataPrivacyPaths}>
        <DataPrivacy
          translations={translations}
          onBack={handleOnBack}
          onGoToLogin={handleGoToLogin}
        />
      </Route>

      <Route path={walletOverviewPaths}>
        <WalletOverview
          user={user}
          apiBaseUrl={apiBaseUrl}
          clientId={clientId}
          clientSessionId={clientSessionId}
          shopCashUser={shopCashUser}
          translations={translations}
          isRtl={isRtl}
          currency={currency}
          currentSite={currentSite}
          locale={locale}
          exchangeRate={exchangeRate}
        />
      </Route>

      <Route path={walletWithdrawPaths}>
        <WalletWithdraw
          user={user}
          apiBaseUrl={apiBaseUrl}
          clientId={clientId}
          clientSessionId={clientSessionId}
          translations={translations}
          isRtl={isRtl}
          currentSite={currentSite}
          locale={locale}
        />
      </Route>
    </Switch>
  );
};

export default HomeRoutes;
