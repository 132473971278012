// import 'core-js/stable';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-hot-loader';
import 'regenerator-runtime/runtime';

import App from './App';
import './polyfills';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import styles from './styles/index.module.scss';

console.log(
  '%c\n██╗    ██╗███████╗ ██████╗  ██████╗ \n██║    ██║██╔════╝██╔════╝ ██╔═══██╗\n██║ █╗ ██║█████╗  ██║  ███╗██║   ██║\n██║███╗██║██╔══╝  ██║   ██║██║   ██║\n╚███╔███╔╝███████╗╚██████╔╝╚██████╔╝\n ╚══╝╚══╝ ╚══════╝ ╚═════╝  ╚═════╝ \n\n',
  'color:#44b50c;',
);

datadogRum.init({
  applicationId: 'cfa3c4b4-0570-470f-98a3-d16ba390b735',
  clientToken: 'pub942b3c6ac72af2ce172dcb5e2fd6ff14',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'mobile-web',
  env:
    isDevelopmentEnvironment(window.location.hostname) ||
    isStagingEnvironment(window.location.hostname)
      ? 'staging'
      : 'production',
  // Specify a version number to identify the deployed version of your application in Datadog
  version: DEPLOYMENT_VERSION,
  // `sessionSampleRate` is the percentage of sessions to track: 100 for all, 0 for none.
  //  Set to 10 to collect only 10% of all sessions.
  sessionSampleRate: 10,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask',
  beforeSend(event) {
    if (event.type === 'error' && event.error.message.includes('Component unmount.')) {
      return false;
    }

    return true;
  },
});

ReactDOM.render(
  <>
    {/* Section: Fake Background Image */}
    <div className={styles.fakeBackgroundImage}></div>

    <App />
  </>,
  document.getElementById('app'),
);

serviceWorkerRegistration.register();
