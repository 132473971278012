import forterEventListenerScript from '!!raw-loader!../forterEventListenerScript';
import forterScript from '!!raw-loader!../forterScript';
import { getCookie } from '@alexis/helpers/cookie';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { getSiteConfigByHostname } from '@alexis/helpers/site';

declare global {
  interface Window {
    forterSiteId: string;
    forterTokenKey: string;
  }
}

export const LOCAL_STORAGE_FORTER_SITE_ID_KEY = 'forterSiteId';

const FORTER_TOKEN_KEY = 'forterToken';
const FORTER_SITE_IDS = {
  development: {
    WegoGCC: '6459b0539a61',
    WegoNA: '8ee3c8b881c0',
  },
  production: {
    WegoGCC: 'c07a9cc4c91f',
    WegoNA: '57c42402d83b',
  },
};

export const loadForterScript = (): void => {
  const hostname = window.location.hostname;
  const siteCode: string = getSiteConfigByHostname(hostname).countryCode;
  const siteId: string = getForterSiteId(hostname, siteCode);

  // Store the siteId in localStorage
  window.localStorage.setItem(LOCAL_STORAGE_FORTER_SITE_ID_KEY, siteId);

  window.forterSiteId = siteId;
  window.forterTokenKey = FORTER_TOKEN_KEY;

  const elementId = siteId;
  const document$: Document = document;

  const eventListenerElementId = elementId + '-eventListener';
  const existingEventListenerScriptElement = document$.getElementById(eventListenerElementId);
  if (!existingEventListenerScriptElement) {
    const scriptElement = document$.createElement('script');
    scriptElement.id = eventListenerElementId;
    // https://portal.forter.com/app/developer/api/api/services-and-apis/javascript-integration
    // need to register this event listener before the actual forter snippet
    // we primarily set/get the forter token in the sessionStorage for scenarios where cookies are blocked
    // we fallback to cookies if we cannot find the token that was sent from the TTI event
    scriptElement.text = forterEventListenerScript;
    // append to body so it will not block the page loading
    document$.body.appendChild(scriptElement);
  }

  const existingScriptElement = document$.getElementById(elementId);
  if (!existingScriptElement) {
    const scriptElement = document$.createElement('script');
    scriptElement.id = elementId;
    scriptElement.text = forterScript;
    // append to body so it will not block the page loading
    document$.body.appendChild(scriptElement);
  }
};

export const getForterToken = (): string | null => {
  let forterTokenCookie = window.sessionStorage.getItem(FORTER_TOKEN_KEY);

  // Fallback to cookie if we cannot retrieve from session storage.
  if (forterTokenCookie === null) {
    let cookie = getCookie(FORTER_TOKEN_KEY);
    if (cookie) {
      // need to get the token part since we do not control setting the token in the cookie
      forterTokenCookie = cookie.split('_')[0];
    }
  }

  return forterTokenCookie;
};

// getForterSiteId returns the Forter siteId based on the hostname and siteCode
const getForterSiteId = (hostname: string, siteCode: string): string => {
  // Note: AE is the only site that uses WegoGCC siteId
  if (isDevelopmentEnvironment(hostname) || isStagingEnvironment(hostname)) {
    // Return the development siteId
    if (siteCode === 'AE') {
      return FORTER_SITE_IDS.development.WegoGCC;
    } else {
      return FORTER_SITE_IDS.development.WegoNA;
    }
  }

  // Return the production siteId
  if (siteCode === 'AE') {
    return FORTER_SITE_IDS.production.WegoGCC;
  } else {
    return FORTER_SITE_IDS.production.WegoNA;
  }
};
