import React, { StrictMode } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import LocaleNavigator from '@components/LocaleNavigator';

import Routes from './config/Routes';
import DatadogProvider from './contexts/Datadog';
import { getStore, injectReducers } from './redux/store';

const store = getStore();

const App: React.FC = (): JSX.Element => {
  return (
    <StrictMode>
      <BrowserRouter>
        <LocaleNavigator>
          <Provider store={store}>
            <DatadogProvider>
              <Routes onInjectReducers={injectReducers} />
            </DatadogProvider>
          </Provider>
        </LocaleNavigator>
      </BrowserRouter>
    </StrictMode>
  );
};

export default hot(App);
