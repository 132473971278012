import { getCurrencyConfig } from '@alexis/helpers/currency';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import { getSiteConfigByHostname } from '@alexis/helpers/site';
import cookies from 'js-cookie';

import { WEGO_CURRENCY_COOKIE_KEY } from '@constants/cookie';

import { CurrencyActionTypes } from '../actions/types';

const currentSite = getSiteConfigByHostname(window.location.hostname);

const cookieCurrency = cookies.get(WEGO_CURRENCY_COOKIE_KEY);

const currency =
  (cookieCurrency ? getCurrencyConfig(cookieCurrency) : undefined) ??
  getCurrencyConfig(currentSite.currencyCode)!;

const initialState: Currency = currency;

export default function (
  state: Currency = initialState,
  action: { type: CurrencyActionTypes; payload: Currency },
): Currency {
  switch (action.type) {
    case CurrencyActionTypes.ChangeCurrency:
      cookies.set(WEGO_CURRENCY_COOKIE_KEY, action.payload.code);
      return action.payload;
    default:
      return state;
  }
}
