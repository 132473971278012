import { facebookSignOut, googleSignOut, wegoSignOut } from '@alexis/helpers/authentication';
import clsx from '@alexis/helpers/clsx';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { genzoTrackActionEvent } from '@alexis/helpers/genzo';
import {
  getLocaleByUlangParam,
  isSiteCodePartOfMENA,
  locales,
  sitesConfig,
} from '@alexis/helpers/site';
import { LoggedInType } from '@alexis/types/loggedInType';
import type { User } from '@alexis/types/user';
import cookies from 'js-cookie';
import type { MenuProps } from 'props/menuProps';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { WEGO_LOCALE_COOKIE_KEY } from '@constants/cookie';
import { FACEBOOK_APP_ID } from '@constants/env';

import { flightsRoute, hotelsRoute } from '@helpers/routeTranslation';

import { useIsAuthNew } from '@hooks/useIsAuthNew';
import { useSearchParams } from '@hooks/useSearchParams';

import TickIcon from '@icons/tick.svg';
import WegoProIcon from '@icons/wegopro_logo.svg';

import { changeLocale } from '@redux/actions/localeActions';
import { resetUser } from '@redux/actions/userActions';

import styles from '@styles/components/Menu.module.scss';

import { DropdownDirection } from '../types/dropdownDirection';
import CurrencyDropdown from './CurrencyDropdown';
import Dropdown from './form/Dropdown';
import Price from './Price';

const Menu: React.FC<MenuProps> = ({
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  isOpen,
  locale,
  shopCashUser,
  translations,
  user,
  source,
  apiBaseUrl,
  clientId,
  clientSessionId,
  pageViewId,
}) => {
  const [isAccountSectionExpanded, setIsAccountSectionExpanded] = useState<boolean>(false);
  const [isBookingHistorySectionExpanded, setIsBookingHistorySectionExpanded] =
    useState<boolean>(false);
  const [isWalletSectionExpanded, setIsWalletSectionExpanded] = useState<boolean>(false);

  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState<boolean>(false);
  const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState<boolean>(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState<boolean>(false);

  const { isAuthNew, login, logout, usedNewLogin } = useIsAuthNew();

  const isActivitiesLinkDisplayed = useMemo<boolean>(() => {
    if (isStagingEnvironment(window.location.hostname)) {
      return /^(?:BH|GH|MY|OM|SG|TN|US)$/i.test(currentSite.countryCode);
    }
    return false;
  }, [currentSite]);

  const isShopcashLinkDisplayed = useMemo<boolean>(() => {
    return /^(?:AE|EG|SA)$/i.test(currentSite.countryCode);
  }, [currentSite]);

  const shopcashLink = useMemo<string>(() => {
    const shopCashDomain = currentSite.countryCode === 'SA' ? 'shopcash.sa' : 'shopcash.ae';
    const normalizedLocale = /^(?:ar|en)$/i.test(locale) ? locale : 'en'; // default to en if locale is unsupported
    const defaultLocaleForShopcashPOS = currentSite.countryCode === 'SA' ? 'ar' : 'en';

    return `https://${shopCashDomain}${
      normalizedLocale === defaultLocaleForShopcashPOS ? '' : `/${locale}`
    }`;
  }, [currentSite, locale]);

  const blogUrl = useMemo<string>(() => {
    if (isSiteCodePartOfMENA(currentSite.countryCode)) {
      return 'https://rahhal.wego.com';
    }

    if (/^(?:ID)$/i.test(currentSite.countryCode)) {
      return 'https://www.wego.co.id/berita';
    }

    if (/^(?:CN|HK|MO|TW)$/i.test(currentSite.countryCode)) {
      return 'https://travel.wego.com/zh-tw';
    }

    return 'https://blog.wego.com';
  }, [currentSite]);

  const dispatch = useDispatch();

  const { search } = useLocation();
  const { url } = useRouteMatch();
  const params = useParams<{ locale?: string; flights?: string; hotels?: string }>();
  const history = useHistory();
  const searchParams = useSearchParams();

  const ulangSearchParam = searchParams.get('ulang');

  // Remove all filter params from search
  const searchWithoutExtraParams = useMemo<string>(() => {
    const searchParams = new URLSearchParams(search);

    searchParams.delete('booking_id');
    searchParams.delete('cityCode');
    searchParams.delete('cityName');
    searchParams.delete('countryCode');
    searchParams.delete('hotelId');
    searchParams.delete('hotelName');
    searchParams.delete('booking_page_view_id'); //From hotel confirmation page

    const updatedSearchParams = searchParams.toString();

    return !!updatedSearchParams ? `?${updatedSearchParams}` : '';
  }, [search]);

  const handleLogout = (user: User) => {
    if (usedNewLogin) {
      logout();
    } else {
      switch (user.loggedInType) {
        case LoggedInType.GoogleOneTap:
          googleSignOut(domainBasedApiBaseUrl);
          break;
        case LoggedInType.GoogleSignIn:
          googleSignOut(domainBasedApiBaseUrl);
          break;
        case LoggedInType.Facebook:
          facebookSignOut(domainBasedApiBaseUrl, FACEBOOK_APP_ID);
          break;
        case LoggedInType.Wego:
          wegoSignOut(domainBasedApiBaseUrl);
          break;
      }
    }

    dispatch(resetUser());
  };

  const LoginWrapper: React.FC = ({ children }) => {
    if (isAuthNew) {
      return (
        <div className={styles.menu} onClick={() => login()}>
          {children}
        </div>
      );
    }

    return (
      <Link
        className={styles.menu}
        to={{ pathname: '/login', search: searchWithoutExtraParams, state: { from: url } }}
      >
        {children}
      </Link>
    );
  };

  const handleOverlayClick = useCallback(() => {
    setIsCountryDropdownOpen(false);
    setIsCurrencyDropdownOpen(false);
    setIsLanguageDropdownOpen(false);
  }, []);

  const handleCountryClick = useCallback<
    (event: React.MouseEvent<HTMLDivElement>, currentSite: CurrentSite) => void
  >(
    (event: React.MouseEvent<HTMLDivElement>, currentSite: CurrentSite) => {
      event.stopPropagation();

      const { hostname, pathname, search } = window.location;

      if (!isDevelopmentEnvironment(hostname)) {
        const url = isStagingEnvironment(hostname)
          ? `https://${currentSite.countryCode.toLowerCase()}-beta.wegostaging.com`
          : `https://${currentSite.host}`;

        if (!!params.flights) {
          window.location.href = `${url}${flightsRoute(currentSite, params.locale)}`;
        } else if (!!params.hotels) {
          window.location.href = `${url}${hotelsRoute(currentSite, params.locale)}`;
        } else {
          window.location.href = `${url}${pathname}${search}`;
        }
      }
      setIsCountryDropdownOpen(false);
    },
    [params.flights, params.hotels, params.locale],
  );

  const handleLocaleClick = useCallback<
    (event: React.MouseEvent<HTMLDivElement>, locale: string) => void
  >(
    (event: React.MouseEvent<HTMLDivElement>, locale: string) => {
      event.stopPropagation();

      dispatch(changeLocale(locale));
      cookies.set(WEGO_LOCALE_COOKIE_KEY, locale);

      const searchParams = new URLSearchParams(searchWithoutExtraParams);
      searchParams.delete('ulang');

      if (currentSite.supportedLocales.includes(locale)) {
        const localParam = currentSite.defaultLocale !== locale ? `/${locale}` : '';

        if (!!params.locale) {
          const pathname = url.replace(`/${params.locale}`, localParam);

          history.replace({
            pathname: !!pathname ? pathname : '/',
            search: searchParams.toString(),
          });
        } else {
          history.replace({
            pathname: `${localParam}${url === '/' ? '' : url}`,
            search: searchParams.toString(),
          });
        }
      } else {
        searchParams.append('ulang', locale);

        if (!!params.locale) {
          const pathname = url.replace(`/${params.locale}`, '');

          history.replace({
            pathname: !!pathname ? pathname : '/',
            search: searchParams.toString(),
          });
        } else {
          history.replace({ pathname: url, search: searchParams.toString() });
        }
      }
      setIsLanguageDropdownOpen(false);
    },
    [searchWithoutExtraParams, params.locale],
  );

  const handleOnWegoProClick = () => {
    if (source === 'home' && !!apiBaseUrl && !!clientId && !!clientSessionId) {
      const eventData = {
        id: pageViewId,
        category: 'drawer',
        object: 'wegopro_businesstravel',
        action: 'clicked',
        value: '',
      };

      genzoTrackActionEvent(apiBaseUrl, clientId, clientSessionId, undefined, eventData);
    }
  };

  return (
    <div className={clsx(styles.container)}>
      {isCountryDropdownOpen || isCurrencyDropdownOpen || isLanguageDropdownOpen ? (
        <div className={styles.overlay} onClick={handleOverlayClick}></div>
      ) : null}

      <div className={styles.header}>
        <Link
          className={styles.logo}
          to={{
            pathname: `${!!params.locale ? `/${params.locale}` : '/'}`,
            search: searchWithoutExtraParams,
          }}
        />
      </div>

      <div className={styles.content}>
        <div className={styles.group}>
          {/* Search Flights */}
          <Link
            className={styles.menu}
            to={{
              pathname: flightsRoute(currentSite, params.locale),
              search: searchWithoutExtraParams,
            }}
          >
            <i className={clsx(styles.icons, styles.flights)}></i>
            {translations.search_flights}
          </Link>

          {/* Search Hotes */}
          <Link
            className={styles.menu}
            to={{
              pathname: hotelsRoute(currentSite, params.locale),
              search: searchWithoutExtraParams,
            }}
          >
            <i className={clsx(styles.icons, styles.hotels)}></i>
            {translations.search_hotels}
          </Link>

          {/* WegoPro Business Travel */}
          <a
            className={clsx(styles.menu, styles.wegoPro)}
            href='https://www.wegopro.com/?utm_source=wego&utm_medium=web&utm_campaign=top-nav-bar'
            rel='noopener noreferrer'
            target='_blank'
            onClick={() => handleOnWegoProClick()}
          >
            <WegoProIcon />

            <div className={styles.menuContent}>
              <div>
                <strong>WegoPro</strong> <br />
                {translations.business_travel}
              </div>

              <div className={styles.newLabel}>
                {locale === 'ar' ? translations.menue_new : 'New'}
              </div>
            </div>
          </a>

          {/* Search Activities */}
          {isActivitiesLinkDisplayed ? (
            <a
              className={styles.menu}
              href={`/activities${searchWithoutExtraParams}`}
              rel='noopener noreferrer'
            >
              <i className={clsx(styles.icons, styles.activities)}></i>
              {translations.search_activities}
            </a>
          ) : null}

          {/* Shopcash */}
          {isShopcashLinkDisplayed ? (
            <a
              className={styles.menu}
              href={shopcashLink}
              rel='noopener noreferrer'
              target='_blank'
            >
              <i className={clsx(styles.icons, styles.shopCash)}></i>
              {translations.cashback}
            </a>
          ) : null}
        </div>

        <div className={styles.group}>
          {/* Login */}
          {!user ? (
            <LoginWrapper>
              <i className={clsx(styles.icons, styles.loginOrSignUp)}></i>
              {translations.login} / {translations.links_sign_up}
            </LoginWrapper>
          ) : null}

          {/* Change Password */}
          {!!user ? (
            <>
              <div
                className={clsx(
                  styles.menu,
                  styles.dropdown,
                  isAccountSectionExpanded && styles.expanded,
                )}
                onClick={() => setIsAccountSectionExpanded(!isAccountSectionExpanded)}
              >
                <div>
                  <i className={clsx(styles.icons, styles.account)}></i>
                  <div>
                    <div>{translations.lbl_my_account}</div>
                    <div className={styles.value}>{user.displayName}</div>
                  </div>
                </div>
                <i className={clsx(styles.triangle, styles.arrowDown)}></i>
              </div>

              {isAccountSectionExpanded ? (
                <>
                  <Link
                    className={styles.subMenu}
                    to={{
                      pathname: '/changepassword',
                      search: searchWithoutExtraParams,
                      state: { from: url },
                    }}
                  >
                    <div>{translations.helpers_label_user_password}</div>
                  </Link>

                  <NavLink
                    className={(isActive) => clsx(styles.subMenu, isActive && styles.active)}
                    to={{
                      pathname: '/preferences',
                      search: searchWithoutExtraParams,
                    }}
                  >
                    <div>{translations.lbl_data_privacy}</div>
                  </NavLink>
                </>
              ) : null}
            </>
          ) : null}

          {/* Wallet */}
          {!!user && !!shopCashUser && !!shopCashUser.ledgerUsd ? (
            <>
              <div
                className={clsx(
                  styles.menu,
                  styles.dropdown,
                  isWalletSectionExpanded && styles.expanded,
                )}
                onClick={() => setIsWalletSectionExpanded(!isWalletSectionExpanded)}
              >
                <div>
                  <i className={clsx(styles.icons, styles.wallet)}></i>
                  <div>
                    <div>{translations.wallet}</div>
                    <div className={styles.value}>
                      <Price
                        className={styles.price}
                        locale={locale}
                        price={{
                          amount: shopCashUser.ledgerUsd!.displayBalance,
                          amountUsd: shopCashUser.ledgerUsd!.displayBalance,
                          currencyCode: 'USD',
                        }}
                        showDecimal={true}
                        currency={currency}
                        exchangeRate={exchangeRate}
                        isInline
                      />
                    </div>
                  </div>
                </div>
                <i className={clsx(styles.triangle, styles.arrowDown)}></i>
              </div>

              {isWalletSectionExpanded ? (
                <>
                  <Link
                    className={styles.subMenu}
                    to={{
                      pathname: '/preferences/wallet-overview',
                      search: searchWithoutExtraParams,
                      state: { from: url },
                    }}
                  >
                    <div>{translations.overview}</div>
                  </Link>
                  <Link
                    className={styles.subMenu}
                    to={{
                      pathname: '/preferences/wallet-withdraw',
                      search: searchWithoutExtraParams,
                      state: { from: url },
                    }}
                  >
                    <div>{translations.withdraw_cashback}</div>
                  </Link>
                </>
              ) : null}
            </>
          ) : null}

          {/* My Booking */}
          <div
            className={clsx(
              styles.menu,
              styles.dropdown,
              isBookingHistorySectionExpanded && styles.expanded,
            )}
            onClick={() => setIsBookingHistorySectionExpanded(!isBookingHistorySectionExpanded)}
          >
            <div>
              <i className={clsx(styles.icons, styles.bookingHistory)}></i>
              <div>
                <div>{translations.booking_history}</div>
              </div>
            </div>
            <i className={clsx(styles.triangle, styles.arrowDown)}></i>
          </div>

          {isBookingHistorySectionExpanded ? (
            <>
              <NavLink
                className={(isActive) => clsx(styles.subMenu, isActive && styles.active)}
                to={{
                  pathname: `${hotelsRoute(currentSite, params.locale)}/booking/history`,
                  search: searchWithoutExtraParams,
                }}
              >
                <div>{translations.Hotels}</div>
              </NavLink>
              <NavLink
                className={(isActive) => clsx(styles.subMenu, isActive && styles.active)}
                to={{
                  pathname: `${flightsRoute(currentSite, params.locale)}/booking/history`,
                  search: searchWithoutExtraParams,
                }}
              >
                <div>{translations.Flights}</div>
              </NavLink>
            </>
          ) : null}

          {/* Price Alert */}
          {/* <Link className={styles.menu} to="/">
            <i className={clsx(styles.icons, styles.priceAlert)}></i>
            {translations.price_alerts}
          </Link> */}

          {/* Logout */}
          {!!user ? (
            <>
              {/* <Link className={styles.menu} to="/">
              <i className={clsx(styles.icons, styles.dataPrivacy)}></i>
              {translations.lbl_data_privacy}
            </Link> */}

              <div className={styles.menu} onClick={() => handleLogout(user)}>
                <i className={clsx(styles.icons, styles.logout)}></i>
                {translations.account_logout}
              </div>
            </>
          ) : null}
        </div>

        <div className={styles.group}>
          <div className={styles.label}>{translations.settings}</div>

          {/* Country */}
          <div className={styles.menu} onClick={() => setIsCountryDropdownOpen(true)}>
            <i className={clsx(styles.icons, styles.country)}></i>
            <div>
              <div>{translations.country_region}</div>
              <div className={styles.value}>{currentSite.countryName}</div>
            </div>

            {isOpen ? (
              <Dropdown
                className={styles.dropdown}
                isOpen={isCountryDropdownOpen}
                direction={DropdownDirection.Top}
              >
                {sitesConfig.map((siteConfig, index) => (
                  <div
                    key={index}
                    className={clsx(
                      styles.option,
                      currentSite.countryCode === siteConfig.countryCode && styles.selected,
                    )}
                    onClick={(e) => handleCountryClick(e, siteConfig)}
                  >
                    <div>
                      <i
                        className={clsx(
                          styles.countryFlags,
                          styles.medium,
                          styles.withLightBackground,
                          styles[`${siteConfig.countryCode.toLowerCase()}`],
                        )}
                      ></i>
                      <div className={styles.name}>
                        {translations[`pos_${siteConfig.countryCode.toLowerCase()}`]}
                      </div>
                    </div>
                    <TickIcon className={styles.icon} />
                  </div>
                ))}
              </Dropdown>
            ) : null}
          </div>

          {/* Currency */}
          <div className={styles.menu} onClick={() => setIsCurrencyDropdownOpen(true)}>
            <i className={clsx(styles.icons, styles.currency)}></i>
            <div>
              <div>{translations.currency}</div>
              <div className={styles.value}>{currency.code}</div>
            </div>
            {isOpen ? (
              <CurrencyDropdown
                isOpen={isCurrencyDropdownOpen}
                onClose={() => setIsCountryDropdownOpen(false)}
              />
            ) : null}
          </div>

          {/* Language */}
          <div className={styles.menu} onClick={() => setIsLanguageDropdownOpen(true)}>
            <i className={clsx(styles.icons, styles.language)}></i>
            <div>
              <div>{translations.language}</div>
              <div className={styles.value}>{getLocaleByUlangParam(locale)?.name}</div>
            </div>
            {isOpen ? (
              <Dropdown
                className={styles.dropdown}
                isOpen={isLanguageDropdownOpen}
                direction={DropdownDirection.Top}
              >
                {locales.map((localeObject) => (
                  <div
                    key={localeObject.code}
                    className={clsx(styles.option, locale === localeObject.code && styles.selected)}
                    onClick={(e) => handleLocaleClick(e, localeObject.code)}
                  >
                    <div>
                      <div className={styles.code}>{localeObject.code}</div>
                      <div className={styles.name}>{localeObject.name}</div>
                    </div>
                    <TickIcon className={styles.icon} />
                  </div>
                ))}
              </Dropdown>
            ) : null}
          </div>
        </div>

        {/* Travel Blog */}
        <div className={styles.group}>
          <a className={styles.menu} href={blogUrl} target='_blank' rel='noreferrer'>
            <i className={clsx(styles.icons, styles.travelBlog)}></i>
            {translations.travel_guides}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
