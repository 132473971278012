import { useEffect } from 'react';

// Set html direction and lang upon locale and rtl change
const useRtl = (locale: string, isRtl: boolean) => {
  useEffect(() => {
    document.getElementsByTagName('html')[0].setAttribute('lang', locale);

    document.getElementsByTagName('html')[0].removeAttribute('dir');

    if (isRtl) {
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
  }, [isRtl, locale]);
};

export default useRtl;
