export enum LocaleActionTypes {
  ChangeLocale = 'CHANGE_LOCALE',
}

export enum TranslationsActionTypes {
  ChangeTranslations = 'CHANGE_TRANSLATIONS',
}

export enum IsRtlActionTypes {
  ChangeIsRtl = 'CHANGE_IS_RTL',
}

export enum CurrencyActionTypes {
  ChangeCurrency = 'CHANGE_CURRENCY',
}

export enum ExchangeRateActionTypes {
  ChangeExchangeRate = 'CHANGE_EXCHANGE_RATE',
}

export enum UserActionTypes {
  ChangeUser = 'CHANGE_USER',
  ResetUser = 'RESET_USER',
}

export enum FlightSearchActionTypes {
  ChangeTripType = 'CHANGE_TRIP_TYPE',
  ChangeDepartureLocation = 'CHANGE_DEPARTURE_LOCATION',
  ChangeArrivalLocation = 'CHANGE_ARRIVAL_LOCATION',
  ChangeDepartureDate = 'CHANGE_DEPARTURE_DATE',
  ChangeReturnDate = 'CHANGE_RETURN_DATE',
  ChangeAdultCount = 'CHANGE_ADULT_COUNT_FLIGHT',
  ChangeChildrenCount = 'CHANGE_CHILDREN_COUNT_FLIGHT',
  ChangeInfantCount = 'CHANGE_INFANT_COUNT_FLIGHT',
  ChangeCabinClass = 'CHANGE_CABIN_CLASS',
  ChangePaymentMethods = 'CHANGE_PAYMENT_METHODS',
  AddPaymentMethod = 'ADD_PAYMENT_METHOD',
  RemovePaymentMethod = 'REMOVE_PAYMENT_METHOD',
  ChangeFlightSearch = 'CHANGE_FLIGHT_SEARCH',
  ChangeNearbyDepartureLocations = 'CHANGE_NEARBY_DEPARTURE_LOCATIONS',
  ChangeNearbyArrivalLocations = 'CHANGE_NEARBY_ARRIVAL_LOCATIONS',
  ChangePerLegSearchSelectedDepartLegId = 'CHANGE_PER_LEG_SEARCH_SELECTED_DEPART_LEG_ID',
  ChangePerLegSearchSelectedTripViewModelId = 'CHANGE_PER_LEG_SEARCH_SELECTED_TRIP_VIEW_MODEL_ID',
  ChangePerLegSearchDepartLegScrollPosition = 'CHANGE_PER_LEG_SEARCH_DEPART_LEG_SCROLL_POSITION',
  ChangePerLegSearchReturnLegScrollPosition = 'CHANGE_PER_LEG_SEARCH_RETURN_LEG_SCROLL_POSITION',
}

export enum CoreConfigActionTypes {
  ChangeCoreConfig = 'CHANGE_CORE_CONFIG',
}

export enum WebEngageActionTypes {
  ChangeAnonymousId = 'CHANGE_ANONYMOUS_ID',
}

export enum FlightRecentSearchesActionTypes {
  AddFlightRecentSearch = 'ADD_FLIGHT_RECENT_SEARCH',
  UpdateFlightRecentSearches = 'UPDATE_FLIGHT_RECENT_SEARCHES',
  RemoveFlightRecentSearch = 'REMOVE_FLIGHT_RECENT_SEARCH',
  ClearFlightRecentSearches = 'CLEAR_FLIGHT_RECENT_SEARCHES',
}

export enum FlightMetaSearchActionTypes {
  ChangeFlightMetaSearch = 'CHANGE_FLIGHT_META_SEARCH',
  ChangeFlightExchangeRates = 'CHANGE_FLIGHT_EXCHANGE_RATES',
  ChangeFlightSearchPath = 'CHANGE_FLIGHT_SEARCH_PATH',
  ChangeTrips = 'CHANGE_TRIPS',
  ChangeLegs = 'CHANGE_LEGS',
  // ChangeFares = 'CHANGE_FARES',
  // ChangeAirlines = 'CHANGE_AIRLINES',
  // ChangeAirports = 'CHANGE_AIRPORTS',
  // ChangeCities = 'CHANGE_CITIES',
  ChangeProviders = 'CHANGE_PROVIDERS',
  // ChangeCountries = 'CHANGE_COUNTRIES',
  // ChangeTripScoreMap = 'CHANGE_TRIP_SCORE_MAP',
  // ChangePaymentMethods = 'CHANGE_PAYMENT_METHODS',
  // ChangeFareConditions = 'CHANGE_FARE_CONDITIONS',
  // ChangeTripFareCountMap = 'CHANGE_TRIP_FARE_COUNT_MAP',
  // ChangeTripPromoCountMap = 'CHANGE_TRIP_PROMO_COUNT_MAP',
  ChangeStopsFilter = 'CHANGE_STOPS_FILTER',
  ChangeStopoverDurationsFilter = 'CHANGE_STOPOVER_DURATIONS_FILTER',
  ChangeLegsFilter = 'CHANGE_LEGS_FILTER',
  ChangeAlliancesFilter = 'CHANGE_ALLIANCES_FILTER',
  ChangeAirlinesFilter = 'CHANGE_AIRLINES_FILTER',
  ChangeOriginAirportsFilter = 'CHANGE_ORIGIN_AIRPORTS_FILTER',
  ChangeDestinationAirportsFilter = 'CHANGE_DESTINATION_AIRPORTS_FILTER',
  ChangeStopoverAirportsFilter = 'CHANGE_STOPOVER_AIRPORTS_FILTER',
  ChangeTripViewModels = 'CHANGE_TRIP_VIEW_MODELS',
  ChangeInlineAdTripViewModels = 'CHANGE_INLINE_AD_TRIP_VIEW_MODELS',
  ChangeFlightAmenities = 'CHANGE_FLIGHT_AMENITIES',
  ResetFlightMetaSearch = 'RESET_FLIGHT_META_SEARCH',
}

export enum HotelSearchActionTypes {
  ChangeDestinationLocation = 'CHANGE_DESTINATION_LOCATION',
  ChangeCheckInDate = 'CHANGE_CHECK_IN_DATE',
  ChangeCheckOutDate = 'CHANGE_CHECK_OUT_DATE',
  ChangeAdultsCount = 'CHANGE_ADULTS_COUNT_HOTEL',
  ChangeChildrenCount = 'CHANGE_CHILDREN_COUNT_HOTEL',
  ChangeChildrenAges = 'CHANGE_CHILDREN_AGES',
  ChangeChildAge = 'CHANGE_CHILD_AGE',
  ChangeGuestRooms = 'CHANGE_GUEST_ROOMS',
  AddRoom = 'ADD_ROOM',
  RemoveRoom = 'REMOVE_ROOM',
  ChangeHotelSearch = 'CHANGE_HOTEL_SEARCH',
}

export enum HotelRecentSearchesActionTypes {
  AddHotelRecentSearch = 'ADD_HOTEL_RECENT_SEARCH',
  ClearHotelRecentSearches = 'CLEAR_HOTEL_RECENT_SEARCHES',
  RemoveHotelRecentSearch = 'REMOVE_HOTEL_RECENT_SEARCH',
}

export enum HotelMetaSearchActionTypes {
  ChangeHotelMetaSearch = 'CHANGE_HOTEL_META_SEARCH',
  ChangeHotelSearchPath = 'CHANGE_HOTEL_SEARCH_PATH',
  ChangeHotelRateProviders = 'CHANGE_HOTEL_RATE_PROVIDERS',

  ChangeHotelLandmarks = 'CHANGE_HOTEL_LANDMARKS',
  ChangeHotelRates = 'CHANGE_HOTEL_RATES',
  ChangeRateAmenities = 'CHANGE_RATE_AMENITIES',
  ChangeIsGroupSearch = 'CHANGE_IS_GROUP_SEARCH',
  ChangeRoomMap = 'CHANGE_ROOM_MAP',

  ChangeAmenitiesFilter = 'CHANGE_AMENITIES_FILTER',
  ChangeBookingOptionsFilter = 'CHANGE_BOOKING_OPTIONS_FILTER',
  ChangeBrandsFilter = 'CHANGE_BRANDS_FILTER',
  ChangeChainsFilter = 'CHANGE_CHAINS_FILTER',
  ChangeDistrictsFilter = 'CHANGE_DISTRICTS_FILTER',
  ChangePropertyTypesFilter = 'CHANGE_PROPERTY_TYPES_FILTER',
  ChangeRateAmenitiesFilter = 'CHANGE_RATE_AMENITIES_FILTER',
  ChangeReviewerGroupsFilter = 'CHANGE_REVIEWER_GROUPS_FILTER',
  ChangeStarsFilter = 'CHANGE_STARS_FILTER',

  ChangeHotelViewModels = 'CHANGE_HOTEL_VIEW_MODELS',
  ChangeSponsoredAdHotelViewModels = 'CHANGE_SPONSORED_AD_HOTEL_VIEW_MODELS',
  ResetHotelMetaSearch = 'RESET_HOTEL_META_SEARCH',
}

export enum InterstitialProviderActionTypes {
  AddInterstitialProviderMap = 'ADD_INTERSTITIAL_PROVIDER_MAP',
}

export enum PriceOptionsActionTypes {
  ChangeShowTotalPrice = 'CHANGE_SHOW_TOTAL_PRICE',
  ChangeIsIncludingTaxOrFee = 'CHANGE_IS_INCLUDING_TAX_OR_FEE',
}

export enum ShopCashActionTypes {
  ChangeShopCashUser = 'CHANGE_SHOPCASH_USER',
  ResetShopCashUser = 'RESET_SHOPCASH_USER',
}
