import clsx from '@alexis/helpers/clsx';
import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { destinationsRoute } from '../../helpers/routeTranslation';
import styles from '../../styles/components/home/HomePopularDestination.module.scss';
import Image from '../Image';
import Price from '../Price';

interface HomePopularDestinationProps {
  className?: string;
  currentSite: CurrentSite;
  locale: string;
  translations: any;
  currency: Currency;
  exchangeRate: ExchangeRate;
  city: FlexSearchCity;
}

const HomePopularDestination: React.FC<HomePopularDestinationProps> = ({
  className,
  currentSite,
  locale,
  translations,
  currency,
  exchangeRate,
  city,
}): JSX.Element => {
  const { search } = useLocation();
  const params = useParams<{ locale?: string }>();

  return (
    <a
      className={clsx(styles.container, className)}
      href={`${destinationsRoute(currentSite, params.locale)}/${city.country.permalink}/${
        city.permalink
      }${search}`}
      target='_blank'
      rel='noreferrer'
    >
      <div className={styles.imageContainer}>
        <Image
          className={styles.image}
          isLazy
          imageUrl={`https://zen.wego.com/cdn-cgi/image/width=480/destinations/cities/${city.code}.jpg`}
        />

        <div className={styles.cityInfo}>
          <div className={styles.cityName}>{city.name}</div>
          <div className={styles.countryName}>{city.country.name}</div>
        </div>
      </div>

      <div className={styles.priceInfo}>
        <div className={styles.label}>
          {!!city.fare ? translations.lbl_round_trip_from : translations.lbl_price_not_available}
        </div>

        {!!city.fare ? (
          <Price
            isInline
            className={styles.price}
            price={city.fare.price}
            locale={locale}
            currency={currency}
            exchangeRate={exchangeRate}
          />
        ) : (
          <div className={styles.noFareLabel}>{translations.lbl_check_fares}</div>
        )}
      </div>
    </a>
  );
};

export default HomePopularDestination;
