import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useIsAuthNew } from '@hooks/useIsAuthNew';
import { useSearchParams } from '@hooks/useSearchParams';

const AuthMagicLink = () => {
  const { isAuthNew, login } = useIsAuthNew();
  const searchParams = useSearchParams();
  const history = useHistory();

  const reAuthenticate = searchParams.get('re-authenticate');

  useEffect(() => {
    if (!reAuthenticate || !isAuthNew) {
      return;
    }

    searchParams.delete('re-authenticate');
    login();
    history.replace({ search: searchParams.toString() });
  }, [reAuthenticate, isAuthNew, login]);

  return null;
};

export default AuthMagicLink;
