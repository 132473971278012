import { dateDisplayFormat } from '@alexis/helpers/date';

/**
 *
 * @param date DD/MM/YYYY
 * @returns boolean
 */
export function isDateValid(date: string): boolean {
  const isDateFormatValid = /(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}/.test(date);

  if (!isDateFormatValid) return false;

  const [day, month, year] = date.split('/');
  const d = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

  if (
    d.getFullYear() === parseInt(year) &&
    d.getMonth() === parseInt(month) - 1 &&
    d.getDate() === parseInt(day)
  )
    return true;

  return false;
}

/**
 *
 * @param date
 * @param maxAge
 * @returns boolean
 */
export function isWithinMaxAgeLimit(date: string, maxAge: number): boolean {
  const [day, month, year] = date.split('/');
  const givenDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  givenDate.setUTCFullYear(givenDate.getUTCFullYear() + maxAge);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return currentDate.getTime() < givenDate.getTime();
}

/**
 * @description Convert date string from DD/MM/YYYY to YYYY-MM-DD
 * @param date DD/MM/YYYY
 */
export function convertDateStringToDateApiFormat(date: string): string {
  const [DD, MM, YYYY] = date.split('/');
  return `${YYYY}-${MM}-${DD}`;
}

/**
 *
 * @param total
 * @returns { hours, minutes, seconds}
 */
export function getTimeRemaining(total: number): {
  hours: number;
  minutes: number;
  seconds: number;
} {
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  return { hours, minutes, seconds };
}

/**
 *
 * @param dateMilliseconds number
 * @param translations any
 * @param locale string
 * @returns string
 */
export function dateLabel(
  dateMilliseconds: number,
  translations: any,
  locale: string,
  formatType?: number,
): string {
  return dateDisplayFormat(
    new Date(dateMilliseconds),
    translations.short_months,
    translations.short_weekdays,
    locale === 'fa',
    formatType,
  );
}

export const getDateWithUTCtimeZone = (dateString: string, locale: string, withTime?: boolean) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    timeZone: 'UTC',
  };
  if (withTime) {
    options.hour = '2-digit';
    options.minute = '2-digit';
  }
  try {
    return date.toLocaleDateString(locale, options);
  } catch (error) {
    // Fallback to a default locale
    return date.toLocaleDateString('en-US', options);
  }
};
