import type { Currency } from '@alexis/helpers/currency/currencyTypes';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrencyState, getShopCashUserState } from '@redux/selectors';

import { isGoogleBot } from '../helpers/bots';
import { webEngageSetUserAttribute } from '../helpers/webEngage';
import { createExchangeRateService } from '../observables/exchangeRateObservables';
import { changeExchangeRate } from '../redux/actions/exchangeRateActions';
import { changeShopCashUser } from '../redux/actions/shopCashActions';

const ExchangeRate = (): null => {
  const currency: Currency = useSelector(getCurrencyState);
  const shopCashUser: ShopCashUser | null = useSelector(getShopCashUserState);

  const [exchangeRates, setExchangeRates] = useState<Array<ExchangeRate>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const exchangeRateService = createExchangeRateService();

    const unsubscribe = exchangeRateService.subscribe((rates: Array<ExchangeRate>) => {
      setExchangeRates(rates);
    });

    const fetchExchangeRateWithPolling = async (): Promise<void> => {
      await exchangeRateService.startPolling(`${API_BASE_URL}/places/v1/currencies/latest`);
    };

    fetchExchangeRateWithPolling();

    return () => {
      unsubscribe();
    };
  }, []);

  // Set exchange rate of selected currency to redux store
  useEffect(() => {
    const exchangeRate = exchangeRates.find((exchangeRate) => exchangeRate.code === currency.code);

    if (exchangeRate) {
      dispatch(changeExchangeRate(exchangeRate));
    }

    if (!isGoogleBot()) {
      webEngageSetUserAttribute('currency', currency.code);
    }
  }, [currency, exchangeRates]);

  useEffect(() => {
    if (!!shopCashUser?.ledgerUsd || !shopCashUser?.ledger || !exchangeRates) return;

    const exchangeRate = exchangeRates.find(
      (exchangeRate) => exchangeRate.code === shopCashUser.ledger.currencyCode,
    );

    if (exchangeRate) {
      const { rate } = exchangeRate;
      const ledgerUsd = {
        availableBalance: shopCashUser.ledger.availableBalance / rate,
        displayBalance: shopCashUser.ledger.displayBalance / rate,
        lifetimeEarning: shopCashUser.ledger.lifetimeEarning / rate,
        lifetimeWithdrawn: shopCashUser.ledger.lifetimeWithdrawn / rate,
        pendingBalance: shopCashUser.ledger.pendingBalance / rate,
      };
      dispatch(changeShopCashUser({ ...shopCashUser, ledgerUsd }));
    }
  }, [shopCashUser, exchangeRates]);

  return null;
};

export default ExchangeRate;
