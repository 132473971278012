export const paymentMethodFilterItemsForEachCountry: { [key: string]: Array<string> } = {
  default: [],
  KW: ['knet'],
  SA: ['mada'],
};

export const CHEAPEST_TAGS_ARRAY: Array<TripCardTag> = [
  'cheapest',
  'cheapest_direct',
  'cheapest_early_morning',
  'cheapest_morning',
  'cheapest_afternoon',
  'cheapest_night',
  'cheapest_daytime',
  'cheapest_nighttime',
];

export const REGEX_VALID_EMAIL = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// Search all available providers here: https://github.com/search?q=repo%3Awego%2Fuser-services%20pseudo&type=code
export const REGEX_WEGO_PSEUDO_EMAIL =
  /^\w+([\.+-]?\w+)_wego*@+(sms|google|huawei|facebook|apple)\.com$/;

export const NEW_NAME_MAX_LENGTH_COUNTRY_CODE = ['AE'];

export const FIRST_NAME_MAX_LENGTH = 24;

export const LAST_NAME_MAX_LENGTH = 29;

export const FULL_NAME_MAX_LENGTH = 64;

export const PHONE_NUMBER_MAX_LENGTH = 15;

export const ALLOWED_MAX_AGE = 130;

export const GOOGLE_FLIGHT_SEARCH = 'gfs';

export const CURRENCY_LOCAL_STORAGE_KEY = 'currency';

export const BANNER_AD_POSITION_3_CORE_CONFIG_KEY = 'mw_fmeta789_bannerad_position3_mweb_v2';

export const LAZYLOADING_ADS_WEB_CORE_CONFIG_KEY = 'w_fmeta1206_lazy_loading_ads_web_v1';

export const AUTOCOMPLETE_CORE_CONFIG_KEY = 'w_fmeta606_autocomplete_all_markets';

export const PER_LEG_SEARCH_CORE_CONFIG_KEY = 'mw_fmeta1116_per_leg_search_v1_mweb';

export const NATIVE_ADS_CORE_CONFIG_KEY = 'mw_fmeta631_native_ads_mweb';

export const EXCLUDED_PROVIDERS_PARAM = 'ep';

export const AIRLINE_BACKFILL_CORE_CONFIG_KEY = 'mw_fmeta1536_airline_backfill_mweb_v1';
