const localePathRegex = '([a-zA-Z]{2}|[a-zA-Z]{2}\\-[a-zA-Z]{2})';
const datePathRegex = '([0-9]{4}\\-[0-9]{2}\\-[0-9]{2})';

const flightsPathRegex =
  '(flights|fluge|vuelos|vols|tiket-pesawat|voli|penerbangan|vluchten|loty|voos|flyg|ve-may-bay)';
const locationCodePathRegex = '([c]?[a-zA-Z]{3})';
const cabinClassRegex = '(economy|premium_economy|business|first)';
const adultCountRegex = '([0-9]{1}[a])';
const childrenCountRegex = '(:[0-9]{1}[c])?';
const infantCountRegex = '(:[0-9]{1}[i])?';
const searchIdRegex = '([a-zA-Z0-9]+)';

const hotelsPathRegex = '(hotels|hoteles|hotel|hotele|hoteis|hotell|khach-san)';
const cityCodePathRegex = '([a-zA-Z]{3}|[\\w\\d-]+)';
const regionIdRegex = '([q]{1}[\\d]+)';
const districtIdRegex = '([d]{1}[\\d]+)';
const hotelIdRegex = '([-\\d]+)';

export const loginCallbackPaths: Array<string> = [
  `/login-callback(/.*)?`,
  `/:locale${localePathRegex}/login-callback(/.*)?`,
];
export const homePaths: Array<string> = [`/`, `/:locale${localePathRegex}`];
export const loginPaths: Array<string> = [`/login`, `/:locale${localePathRegex}/login`];
export const registerPaths: Array<string> = [`/register`, `/:locale${localePathRegex}/register`];
export const changePasswordPaths: Array<string> = [
  `/changepassword`,
  `/:locale${localePathRegex}/changepassword`,
];
export const resetPasswordPaths: Array<string> = [
  `/resetpassword`,
  `/:locale${localePathRegex}/resetpassword`,
];
export const sendConfirmationPaths: Array<string> = [
  `/sendconfirmation`,
  `/:locale${localePathRegex}/sendconfirmation`,
];
export const dataPrivacyPaths: Array<string> = [
  `/dataprivacy`,
  `/:locale${localePathRegex}/dataprivacy`,
];
export const walletOverviewPaths: Array<string> = [
  `/preferences/wallet-overview`,
  `/:locale${localePathRegex}/preferences/wallet-overview`,
];
export const walletWithdrawPaths: Array<string> = [
  `/preferences/wallet-withdraw`,
  `/:locale${localePathRegex}/preferences/wallet-withdraw`,
];

export const homeRoutesPaths: Array<string> = [
  ...loginCallbackPaths,
  ...homePaths,
  ...loginPaths,
  ...registerPaths,
  ...changePasswordPaths,
  ...resetPasswordPaths,
  ...sendConfirmationPaths,
  ...dataPrivacyPaths,
  ...walletOverviewPaths,
  ...walletWithdrawPaths,
];

export const flightSearchFormPaths: Array<string> = [
  `/:flights${flightsPathRegex}`,
  `/:locale${localePathRegex}/:flights${flightsPathRegex}`,
];

export const noPassengerCountFlightSearchResultPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}-:departureDate${datePathRegex}/:cabinClass${cabinClassRegex}`,
  `${flightSearchFormPaths[0]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}-:departureDate${datePathRegex}:${locationCodePathRegex}-${locationCodePathRegex}-:returnDate${datePathRegex}/:cabinClass${cabinClassRegex}`,

  `${flightSearchFormPaths[1]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}-:departureDate${datePathRegex}/:cabinClass${cabinClassRegex}`,
  `${flightSearchFormPaths[1]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}-:departureDate${datePathRegex}:${locationCodePathRegex}-${locationCodePathRegex}-:returnDate${datePathRegex}/:cabinClass${cabinClassRegex}`,

  // Old path. To be remove after marketing side and amp pages update to new path
  `${flightSearchFormPaths[0]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}/:departureDate${datePathRegex}/:cabinClass${cabinClassRegex}`,
  `${flightSearchFormPaths[0]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}/:departureDate${datePathRegex}::returnDate${datePathRegex}/:cabinClass${cabinClassRegex}`,

  `${flightSearchFormPaths[1]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}/:departureDate${datePathRegex}/:cabinClass${cabinClassRegex}`,
  `${flightSearchFormPaths[1]}/searches/:departureLocationCode${locationCodePathRegex}-:arrivalLocationCode${locationCodePathRegex}/:departureDate${datePathRegex}::returnDate${datePathRegex}/:cabinClass${cabinClassRegex}`,
];

export const flightSearchResultPaths: Array<string> = [
  `${noPassengerCountFlightSearchResultPaths[0]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,
  `${noPassengerCountFlightSearchResultPaths[1]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,

  `${noPassengerCountFlightSearchResultPaths[2]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,
  `${noPassengerCountFlightSearchResultPaths[3]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,

  // Old path. To be remove after marketing side and amp pages update to new path
  `${noPassengerCountFlightSearchResultPaths[4]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,
  `${noPassengerCountFlightSearchResultPaths[5]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,

  `${noPassengerCountFlightSearchResultPaths[6]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,
  `${noPassengerCountFlightSearchResultPaths[7]}/:adultCount${adultCountRegex}:childrenCount${childrenCountRegex}:infantCount${infantCountRegex}`,
];

export const tripDetailPaths: Array<string> = [
  `${flightSearchResultPaths[0]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[1]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[2]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[3]}/:searchId${searchIdRegex}::tripCode`,

  // Old path. To be remove after marketing side and amp pages update to new path
  `${flightSearchResultPaths[4]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[5]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[6]}/:searchId${searchIdRegex}::tripCode`,
  `${flightSearchResultPaths[7]}/:searchId${searchIdRegex}::tripCode`,
];

export const tripFareComparisonPaths: Array<string> = [
  `${tripDetailPaths[0]}/:fareCode`,
  `${tripDetailPaths[1]}/:fareCode`,
  `${tripDetailPaths[2]}/:fareCode`,
  `${tripDetailPaths[3]}/:fareCode`,

  // Old path. To be remove after marketing side and amp pages update to new path
  `${tripDetailPaths[4]}/:fareCode`,
  `${tripDetailPaths[5]}/:fareCode`,
  `${tripDetailPaths[6]}/:fareCode`,
  `${tripDetailPaths[7]}/:fareCode`,
];

export const tripFareComparisonBookingPaths: Array<string> = [
  `${tripFareComparisonPaths[0]}/:booking(booking)`,
  `${tripFareComparisonPaths[1]}/:booking(booking)`,
  `${tripFareComparisonPaths[2]}/:booking(booking)`,
  `${tripFareComparisonPaths[3]}/:booking(booking)`,

  // Old path. To be remove after marketing side and amp pages update to new path
  `${tripFareComparisonPaths[4]}/:booking(booking)`,
  `${tripFareComparisonPaths[5]}/:booking(booking)`,
  `${tripFareComparisonPaths[6]}/:booking(booking)`,
  `${tripFareComparisonPaths[7]}/:booking(booking)`,
];

export const tripBookingConfirmationPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/booking/confirmation`,
  `${flightSearchFormPaths[1]}/booking/confirmation`,
];

export const tripBookingRetryPaymentPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/booking/retrypayment`,
  `${flightSearchFormPaths[1]}/booking/retrypayment`,
];

export const flightExternalHandoffPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/handoff`,
  `${flightSearchFormPaths[1]}/handoff`,
];

export const flightBookingHistoryDetailsPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/booking/history/:bookingRef`,
  `${flightSearchFormPaths[1]}/booking/history/:bookingRef`,
];

export const flightBookingHistoryPaths: Array<string> = [
  `${flightSearchFormPaths[0]}/booking/history`,
  `${flightSearchFormPaths[1]}/booking/history`,
];

export const flightRoutesPaths: Array<string> = [
  ...flightSearchFormPaths,
  ...flightSearchResultPaths,
  ...noPassengerCountFlightSearchResultPaths,
  ...tripDetailPaths,
  ...tripFareComparisonPaths,
  ...tripFareComparisonBookingPaths,
  ...tripBookingConfirmationPaths,
  ...tripBookingRetryPaymentPaths,
  ...flightExternalHandoffPaths,
  ...flightBookingHistoryDetailsPaths,
  ...flightBookingHistoryPaths,
];

export const hotelSearchFormPaths: Array<string> = [
  `/:hotels${hotelsPathRegex}`,
  `/:locale${localePathRegex}/:hotels${hotelsPathRegex}`,

  `/hotels-handoff`,
  `/:locale${localePathRegex}/hotels-handoff`,
];

export const hotelSearchResultPaths: string[] = hotelSearchFormPaths.flatMap((formPath) => [
  `${formPath}/searches/:regionId${regionIdRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}`,
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:districtId${districtIdRegex}`,
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}`,

  // Todo: Remove after SEO, APP and Marketing side does their changes to guest param
  `${formPath}/searches/:regionId${regionIdRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:roomCount([\\d]+)/:guestCount([\\d]+)`,
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:roomCount([\\d]+)/:guestCount([\\d]+)/:districtId${districtIdRegex}`,
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:roomCount([\\d]+)/:guestCount([\\d]+)`,
]);

export const hotelDetailPaths: string[] = hotelSearchFormPaths.flatMap((formPath) => [
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:hotelId${hotelIdRegex}`,

  // Todo: Remove after SEO, APP and Marketing side does their changes to guest param
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:roomCount([\\d]+)/:guestCount([\\d]+)/:hotelId${hotelIdRegex}`,
]);

export const hotelRoomDetailPaths: string[] = hotelSearchFormPaths.flatMap((formPath) => [
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:hotelId${hotelIdRegex}/rooms`,

  // Todo: Remove after SEO, APP and Marketing side does their changes to guest param
  `${formPath}/searches/:cityCode${cityCodePathRegex}/:checkInDate${datePathRegex}/:checkOutDate${datePathRegex}/:roomCount([\\d]+)/:guestCount([\\d]+)/:hotelId${hotelIdRegex}/rooms`,
]);

export const hotelBookingCheckoutPaths = hotelSearchFormPaths.map(
  (formPath) => `${formPath}/booking/checkout`,
);

export const hotelBookingConfirmPaths = hotelSearchFormPaths.map(
  (formPath) => `${formPath}/booking/confirm`,
);

export const hotelBookingHistoryPaths = hotelSearchFormPaths.map(
  (formPath) => `${formPath}/booking/history`,
);

export const hotelBookingReviewPaths = hotelSearchFormPaths.map(
  (formPath) => `${formPath}/booking/review`,
);

export const hotelRoutesPaths: string[] = [
  ...hotelSearchFormPaths,
  ...hotelSearchResultPaths,
  ...hotelDetailPaths,
  ...hotelRoomDetailPaths,
  ...hotelBookingCheckoutPaths,
  ...hotelBookingConfirmPaths,
  ...hotelBookingHistoryPaths,
  ...hotelBookingReviewPaths,
];

export const bookingPaths: Array<string> = [`/booking`, `/:locale${localePathRegex}/booking`];

export const bookingConfirmationPaths: Array<string> = [
  `/booking/confirmation`,
  `/:locale${localePathRegex}/booking/confirmation`,
];

export const bookingRoutesPaths: Array<string> = [...bookingPaths, ...bookingConfirmationPaths];

export const preferencesRoutesPaths: Array<string> = [
  `/preferences`,
  `/:locale${localePathRegex}/preferences`,
];
