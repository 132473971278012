import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';

import { HotelSearch } from '@wegoTypes/Hotel/Search/hotelSearch';

import { HotelRecentSearchesActionTypes } from '../actions/types';

const hotelRecentSearchesLocalStorageKey = 'hotelRecentSearches';
let hotelRecentSearches: Array<HotelSearch> = [];

const localStorageHotelRecentSearches = localStorageGet<Array<HotelSearch>>(
  hotelRecentSearchesLocalStorageKey,
);

if (!!localStorageHotelRecentSearches) {
  const today = new Date();
  const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

  hotelRecentSearches = localStorageHotelRecentSearches.filter(
    (localStorageHotelRecentSearch) =>
      !(localStorageHotelRecentSearch.checkInDateMilliseconds! < todayDateMilliseconds),
  );
  localStorageSave(hotelRecentSearchesLocalStorageKey, hotelRecentSearches);
}

const initialState: Array<HotelSearch> = hotelRecentSearches;

export default function (
  state: Array<HotelSearch> = initialState,
  action: { type: HotelRecentSearchesActionTypes; payload: HotelSearch },
): Array<HotelSearch> {
  switch (action.type) {
    case HotelRecentSearchesActionTypes.AddHotelRecentSearch: {
      const stringifyPayload = JSON.stringify(action.payload);
      const today = new Date();
      const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

      const checkedHotelRecentSearches = state.filter((hotelRecentSearch) => {
        const stringifyHotelRecentSearch = JSON.stringify(hotelRecentSearch);

        return (
          stringifyHotelRecentSearch !== stringifyPayload &&
          !(hotelRecentSearch.checkInDateMilliseconds! < todayDateMilliseconds)
        );
      });

      const currentHotelRecentSearches = [action.payload, ...checkedHotelRecentSearches];
      localStorageSave(hotelRecentSearchesLocalStorageKey, currentHotelRecentSearches);
      return currentHotelRecentSearches;
    }
    case HotelRecentSearchesActionTypes.RemoveHotelRecentSearch: {
      const newRecentSearch = action.payload as HotelSearch;
      const stringifyPayload = JSON.stringify({
        destinationLocation: newRecentSearch.destinationLocation,
        checkInDateMilliseconds: newRecentSearch.checkInDateMilliseconds,
        checkOutDateMilliseconds: newRecentSearch.checkOutDateMilliseconds,
        guestRooms: newRecentSearch.guestRooms,
      });
      const today = new Date();
      const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

      const checkedHotelRecentSearches = state.filter((HotelRecentSearch) => {
        const stringifyHotelRecentSearch = JSON.stringify({
          destinationLocation: HotelRecentSearch.destinationLocation,
          checkInDateMilliseconds: HotelRecentSearch.checkInDateMilliseconds,
          checkOutDateMilliseconds: HotelRecentSearch.checkOutDateMilliseconds,
          guestRooms: HotelRecentSearch.guestRooms,
        });

        return (
          stringifyHotelRecentSearch !== stringifyPayload &&
          !(HotelRecentSearch.checkInDateMilliseconds! < todayDateMilliseconds)
        );
      });

      localStorageSave(hotelRecentSearchesLocalStorageKey, checkedHotelRecentSearches);
      return checkedHotelRecentSearches;
    }
    case HotelRecentSearchesActionTypes.ClearHotelRecentSearches: {
      localStorageSave(hotelRecentSearchesLocalStorageKey, []);
      return [];
    }
    default:
      return state;
  }
}
