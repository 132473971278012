/**
 * Safely parses a JSON string.
 * @param jsonString - The JSON string to parse.
 * @param defaultValue - The default value to return if parsing fails.
 * @returns The parsed object or the default value.
 */
export const safeJsonParse = <T>(jsonString: string, defaultValue: T | null = null): T | null => {
  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    return defaultValue;
  }
};
