import {
  googleOneTapSignIn,
  retrieveWegoUserInformation,
  wegoRefreshSignIn,
} from '@alexis/helpers/authentication';
import { getCookie } from '@alexis/helpers/cookie';
import { getCurrencyConfig } from '@alexis/helpers/currency';
import { getGMTOffsetFromDate, toISOStringWithTimezone } from '@alexis/helpers/date';
import { isDevelopmentEnvironment, isStagingEnvironment } from '@alexis/helpers/environment';
import { initializeGenzo, genzoTrackNewSession, genzoTrack } from '@alexis/helpers/genzo';
import { generateCUID, initializeIdentity } from '@alexis/helpers/identity';
import { localStorageClear, localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { appendSearchParams } from '@alexis/helpers/searchParams';
import { LoggedInType } from '@alexis/types/loggedInType';
import type { User } from '@alexis/types/user';
import { AuthProvider, TAuthConfig } from '@vendor/react-oauth2-code-pkce';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import type { Location } from 'location';
import isEmpty from 'lodash/isEmpty';
import type { LocaleRoutesProps } from 'props/localeRoutesProps';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useParams, useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import AuthMagicLink from '@components/AuthMagicLink';

import { GOOGLE_CLIENT_ID, WEGO_CLIENT_ID } from '@constants/env';
import { NEW_AUTH } from '@constants/localStorage';

import { isGoogleBot } from '@helpers/bots';
import { getUpdatedFlightSearchDetails } from '@helpers/flight';
import { loadForterScript } from '@helpers/forter';
import { getLocaleParam } from '@helpers/locale';
import {
  homeRoutesPaths,
  flightRoutesPaths,
  hotelRoutesPaths,
  bookingRoutesPaths,
  preferencesRoutesPaths,
} from '@helpers/path';
import { flightsRouteTranslation, hotelsRouteTranslation } from '@helpers/routeTranslation';
import {
  getWebEngageAnonymousId,
  webEngageSetUserAttribute,
  webEngageTrackLogin,
} from '@helpers/webEngage';

import useCoreConfig from '@hooks/useCoreConfig';
import { useIsAuthNew } from '@hooks/useIsAuthNew';

import { updateFlightRecentSearches } from '@redux/actions/flightRecentSearchesActions';
import { changeFlightSearch } from '@redux/actions/flightSearchActions';
import { getCoreConfigState } from '@redux/selectors';

import type { FlightSearch } from '@wegoTypes/flightSearch';

import { useSearchParams } from '../hooks/useSearchParams';
import { changeCurrency } from '../redux/actions/currencyActions';
import { changeUser, resetUser } from '../redux/actions/userActions';
import { changeWebEngageAnonymousId } from '../redux/actions/webEngage';
import flightRecentSearchesReducer from '../redux/reducers/flightRecentSearches';
import hotelRecentSearchesReducer from '../redux/reducers/hotelRecentSearches';
import HomeRoutes from './HomeRoutes';

const BookingRoutes = lazy(
  () =>
    import(/* webpackChunkName: "BookingRoutes" */ /* webpackPrefetch: true */ './BookingRoutes'),
);
const FlightRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "FlightAndHotelRoutes" */ /* webpackPrefetch: true */ './FlightRoutes'
    ),
);
const HotelRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "FlightAndHotelRoutes" */ /* webpackPrefetch: true */ './HotelRoutes'
    ),
);

const PreferencesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PreferencesPage" */ /* webpackPrefetch: true */ '../components/preferences'
    ),
);

loadForterScript();

type AuthConfig = {
  locale: string;
  siteCode: string;
  domainBasedApiBaseUrl: string;
  dispatch: Dispatch<any>;
  wegoAnalyticsClientId?: string;
  wegoAnalyticsClientSessionId?: string;
  vendorId?: string;
  coreConfigs: CoreConfig[];
  enabled: boolean;
  searchParams: URLSearchParams;
  history: any;
};

const baseUrl = () => {
  return isDevelopmentEnvironment(window.location.hostname) ||
    isStagingEnvironment(window.location.hostname)
    ? 'wegostaging'
    : 'wego';
};

const getUserInfo = async (domainBasedApiBaseUrl: string, dispatch: Dispatch<any>) => {
  const wegoUserInformation = await retrieveWegoUserInformation(domainBasedApiBaseUrl);
  const expireByDate = new Date();
  expireByDate.setDate(expireByDate.getDate() + 1);

  const userData: User = {
    loggedInType: LoggedInType.Wego,
    displayName: !!wegoUserInformation.name.trim()
      ? wegoUserInformation.name
      : wegoUserInformation.email,
    title: wegoUserInformation.title,
    firstName: wegoUserInformation.first_name,
    lastName: wegoUserInformation.last_name,
    nationality: wegoUserInformation.nationality,
    countryCode: wegoUserInformation.country_code,
    phoneCountryCode: wegoUserInformation.phone_country_code,
    phoneNumber: wegoUserInformation.phone_number,
    email: wegoUserInformation.email,
    csRestoreId: wegoUserInformation.cs_restore_id,
    photoUrl: wegoUserInformation.photo_url ?? undefined,
    userHash: wegoUserInformation.user_hash,
    idHash: wegoUserInformation.id_hash,
    sprinklrUserHash: wegoUserInformation.sprinklr_user_hash,
    accountPhoneNumber: wegoUserInformation.account_phone_number,
    accountPhoneCountryCode: wegoUserInformation.account_phone_country_code,
    expireBy: expireByDate.getTime(),
  };
  dispatch(changeUser(userData));
  webEngageTrackLogin(userData);

  return userData;
};

const authConfig = ({
  locale,
  siteCode,
  domainBasedApiBaseUrl,
  dispatch,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
  vendorId,
  coreConfigs,
  enabled,
  searchParams,
  history,
}: AuthConfig) => {
  const authKeys = coreConfigs
    .filter((x) => x.key.startsWith('auth_'))
    .reduce((acc: { [key: string]: string }, cur) => {
      acc[cur.key] = cur.value;
      return acc;
    }, {});

  return {
    clientId: '9724e2a44a5276a4e7a180fe32ac3b389afa289a972987c2670d863358d79cb8',
    extraAuthParameters: {
      locale: locale ?? 'en',
      site_code: siteCode ?? 'SA',
      app_type: 'MOBILE_WEB_APP',
      wc: wegoAnalyticsClientId || '',
      ws: wegoAnalyticsClientSessionId || '',
      vendor_id: vendorId || '',
      additional_attributes: isEmpty(authKeys) ? '' : btoa(JSON.stringify(authKeys)),
    },
    authorizationEndpoint: `https://auth.${baseUrl()}.com/users/oauth/authorize`,
    tokenEndpoint: `${domainBasedApiBaseUrl}/users/oauth/token`,
    logoutEndpoint: `https://auth.${baseUrl()}.com/user-auth/v2/users/logout`,
    redirectUri: window.location.origin,
    scope: 'users',
    decodeToken: true,
    autoLogin: false,
    postLogin: () => {
      localStorageSave(NEW_AUTH, true);
      getUserInfo(domainBasedApiBaseUrl, dispatch);
      searchParams.delete('code');

      const currentPath = window.location.pathname;
      let newPath = currentPath;
      if (currentPath.includes('/login-callback')) {
        newPath = currentPath.replace('/login-callback', '') || '/';
      }

      history?.replace({
        pathname: newPath,
        search: searchParams.toString(),
      });
    },
    onRefreshTokenExpire: ({ logOut }) => {
      localStorageClear(NEW_AUTH);
      logOut(undefined, undefined, { post_logout_redirect_path: window.location.pathname });
    },
    enabled,
  } as TAuthConfig;
};

const LocaleRoutes: React.FC<LocaleRoutesProps> = ({
  apiBaseUrl,
  currency,
  currentSite,
  domainBasedApiBaseUrl,
  exchangeRate,
  isRtl,
  locale,
  onInjectReducers,
  translations,
  user,
  shopCashUser,
}) => {
  onInjectReducers({
    flightRecentSearches: flightRecentSearchesReducer,
    hotelRecentSearches: hotelRecentSearchesReducer,
  });

  const [wegoAnalyticsClientId, setWegoAnalyticsClientId] = useState<string>();
  const [wegoAnalyticsClientSessionId, setWegoAnalyticsClientSessionId] = useState<string>();
  const [vendorId, setVendorId] = useState<string>();

  const [lastTrackedWegoAnalyticsClientSessionId, setLastTrackedWegoAnalyticsClientSessionId] =
    useState<string>();
  const [wegoVendorId, setWegoVendorId] = useState<string>();

  const [homepage, setHomepage] = useState<Homepage>();
  const [nearestCity, setNearestCity] = useState<Location>();

  const dispatch = useDispatch();

  const params = useParams<{ locale?: string; flights?: string; hotels?: string }>();

  const { pathname, search, state } = useLocation();
  const { url } = useRouteMatch();
  const history = useHistory();
  const searchParams = useSearchParams();

  const isMobileApp = !!searchParams.get('from_mobile_app');
  useCoreConfig(wegoAnalyticsClientId);
  const coreConfigs = useSelector(getCoreConfigState);
  const ulangSearchParam = searchParams.get('ulang');
  const currencySearchParam = searchParams.get('wego_currency');

  const { isAuthNew, coreConfigsLoaded, usedNewLogin } = useIsAuthNew();

  const getClientId = (): string | undefined => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('client_id') || searchParams.get('wc') || undefined;
  };

  const getClientSessionId = (): string | undefined => {
    const searchParams = new URLSearchParams(window.location.search);
    return (
      getCookie('wego_analytics_client_session_id') ||
      searchParams.get('session_id') ||
      searchParams.get('ws') ||
      undefined
    );
  };
  // vendor_id added for IOS
  const getVendorId = (): string | undefined => {
    const searchParams = new URLSearchParams(window.location.search);
    const vendorId = searchParams.get('vendor_id') || undefined;
    setVendorId(vendorId);
    return vendorId;
  };

  // Update Flight Search Details based on locale
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const updateSearchInfo = async (cancelToken: CancelToken) => {
      const updatedDetails = await getUpdatedFlightSearchDetails(apiBaseUrl, locale, cancelToken);

      if (updatedDetails.flightSearch) {
        localStorageSave('flightSearch', updatedDetails.flightSearch);
        dispatch(changeFlightSearch(updatedDetails.flightSearch));
      }

      if (updatedDetails.flightRecentSearches?.length) {
        localStorageSave('flightRecentSearches', updatedDetails.flightRecentSearches);
        dispatch(updateFlightRecentSearches(updatedDetails.flightRecentSearches));
      }
    };

    const flightSearch: FlightSearch | null = localStorageGet('flightSearch');
    const flightRecentSearches: Array<FlightSearch> | null =
      localStorageGet('flightRecentSearches');
    if (
      (!!flightSearch && locale !== flightSearch.locale) ||
      (!!flightRecentSearches?.length && locale !== flightRecentSearches[0].locale)
    ) {
      updateSearchInfo(cancelTokenSource.token);
    }

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [apiBaseUrl, locale]);

  // Initialize indentiy, tracking and cookie bot
  useEffect(() => {
    if (!isGoogleBot()) {
      webEngageSetUserAttribute('time_zone', `GMT${getGMTOffsetFromDate(new Date())}`);
      webEngageSetUserAttribute('wego_country', currentSite.countryCode);

      initializeGenzo();

      const wegoAnalyticsClientIdCookie = getCookie('wego_analytics_client_id');
      setWegoAnalyticsClientId(wegoAnalyticsClientIdCookie);

      /**
       * This code handles setting the wegoAnalyticsClientIdCookie on Android devices
       * where the cookie may not be written due to Chrome limitations.
       */
      // Check if the device is a mobile app and the cookie doesn't exist
      // and getClientId is defined
      const clientId = getClientId();
      if (isMobileApp && !wegoAnalyticsClientIdCookie && clientId) {
        // Set the wegoAnalyticsClientIdCookie
        setWegoAnalyticsClientId(clientId);
      }

      const wegoAnalyticsClientSessionIdCookie = getCookie('wego_analytics_client_session_id');
      setLastTrackedWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionIdCookie);
      setWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionIdCookie);

      /**
       * This code handles setting the wegoAnalyticsClientSessionIdCookie on Android devices
       * where the cookie may not be written due to Chrome limitations.
       */
      // Check if the device is a mobile app and the cookie doesn't exist
      // and getClientId is defined
      const clientSessionId = getClientSessionId();
      if (isMobileApp && !wegoAnalyticsClientSessionIdCookie && clientSessionId) {
        // Set the wegoAnalyticsClientSessionId
        setWegoAnalyticsClientSessionId(clientSessionId);
      }

      const vendorId = getVendorId();
      if (isMobileApp && vendorId) {
        // Set the wegovendorId
        setWegoVendorId(vendorId);
      }

      const handleNewWegoClient = ({ detail }: CustomEventInit): void => {
        if (!!detail.wegoAnalyticsClientId) {
          setWegoAnalyticsClientId(detail.wegoAnalyticsClientId);
        }
      };
      document.addEventListener('newWegoClient', handleNewWegoClient);

      const handleNewWegoSession = ({ detail }: CustomEventInit): void => {
        if (!!detail.wegoAnalyticsClientSessionId) {
          setWegoAnalyticsClientSessionId(detail.wegoAnalyticsClientSessionId);
        }
      };
      document.addEventListener('newWegoSession', handleNewWegoSession);

      initializeIdentity();

      return () => {
        document.removeEventListener('newWegoClient', handleNewWegoClient);
        document.removeEventListener('newWegoSession', handleNewWegoSession);
      };
    }
  }, []);

  // Track Web Engage user attribute user location
  useEffect(() => {
    if (!isGoogleBot() && !!nearestCity) {
      webEngageSetUserAttribute('user_country_code', nearestCity.countryCode);
      webEngageSetUserAttribute('user_city_code', nearestCity.cityCode);
      webEngageSetUserAttribute('cityName', nearestCity.cityName);
    }
  }, [nearestCity]);

  // Get webEngageAnonymousId
  useEffect(() => {
    const getWebengageAnonymousId = async (): Promise<void> => {
      try {
        const webEngageAnonymousId = await getWebEngageAnonymousId();

        dispatch(changeWebEngageAnonymousId(webEngageAnonymousId));
      } catch (error) {}
    };

    getWebengageAnonymousId();
  }, []);

  // Track genzo new session
  useEffect(() => {
    if (
      !!wegoAnalyticsClientId &&
      !!wegoAnalyticsClientSessionId &&
      wegoAnalyticsClientSessionId !== lastTrackedWegoAnalyticsClientSessionId
    ) {
      genzoTrackNewSession(
        apiBaseUrl,
        'MOBILE_WEB_APP',
        wegoAnalyticsClientId,
        wegoAnalyticsClientSessionId,
        locale,
        currentSite.countryCode,
        !!user ? user.userHash : undefined,
      );
      setLastTrackedWegoAnalyticsClientSessionId(wegoAnalyticsClientSessionId);
    }
  }, [wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  // Validate locale from ulang search param, locale param and local storage locale
  useEffect(() => {
    const routeTranslationLocale = getLocaleParam(pathname) || currentSite.defaultLocale;

    // Update flights route translation
    // Use current site default locale if locale param does not exist
    if (!!params.flights) {
      const flightsRoute = flightsRouteTranslation(routeTranslationLocale);

      if (flightsRoute !== params.flights) {
        history.replace({
          pathname: url.replace(`/${params.flights}`, `/${flightsRoute}`),
          search: search,
          state: state,
        });
      }
    }

    // Update hotels route translation
    // Use current site default locale if locale param does not exist
    if (!!params.hotels) {
      const hotelsRoute = hotelsRouteTranslation(routeTranslationLocale);

      if (hotelsRoute !== params.hotels) {
        history.replace({
          pathname: url.replace(`/${params.hotels}`, `/${hotelsRoute}`),
          search: search,
          state: state,
        });
      }
    }
  }, [ulangSearchParam, params, pathname]);

  // If the valid currency query param exists, set the currency state with it.
  // Immediately remove the wego_currency param to make it clean.
  //
  // Context:
  // Setting the currency to the user desired currency for traffic from
  // Google Hotel and other meta distribution partners.
  useEffect(() => {
    if (!!currencySearchParam) {
      const currency = getCurrencyConfig(currencySearchParam);

      if (!!currency) {
        dispatch(changeCurrency(currency));
      }

      searchParams.delete('wego_currency');
      history.replace({
        pathname: url,
        search: searchParams.toString(),
      });
    }
  }, [currencySearchParam]);

  // Initialize google one tap sign in
  useEffect(() => {
    const initializeGoogleOneTapSignIn = async (): Promise<void> => {
      try {
        const user = await googleOneTapSignIn(
          domainBasedApiBaseUrl,
          GOOGLE_CLIENT_ID,
          WEGO_CLIENT_ID,
        );
        dispatch(changeUser(user));
      } catch (error) {}
    };

    const refreshUserAuthenticationToken = async (user: User): Promise<void> => {
      if (usedNewLogin) {
        return;
      }

      try {
        const expireBy = await wegoRefreshSignIn(domainBasedApiBaseUrl);
        dispatch(changeUser({ ...user, expireBy }));
      } catch (error) {
        dispatch(resetUser());
      }
    };

    const checkUserAuthenticationTokenValidity = async (user: User): Promise<void> => {
      if (user.expireBy > Date.now()) {
        try {
          const _ = await retrieveWegoUserInformation(domainBasedApiBaseUrl);
        } catch (error) {
          await refreshUserAuthenticationToken(user);
        }
      } else {
        await refreshUserAuthenticationToken(user);
      }
    };

    if (!coreConfigsLoaded) {
      return;
    }

    if (user) {
      checkUserAuthenticationTokenValidity(user);
    } else {
      if (!isGoogleBot() && !isAuthNew) {
        initializeGoogleOneTapSignIn();
      }
    }
  }, [isAuthNew, coreConfigsLoaded]);

  // Send A/B testing data to genzo
  useEffect(() => {
    if (
      wegoAnalyticsClientId &&
      wegoAnalyticsClientSessionId &&
      !isMobileApp &&
      !!coreConfigs &&
      coreConfigs.length
    ) {
      const getConnectAPIResponseAndSendToGenzo = async () => {
        if (coreConfigs.length > 0) {
          const data: GenzoExperimentPayload = {
            id: generateCUID(),
            created_at: toISOStringWithTimezone(new Date()),
            client: {
              id: wegoAnalyticsClientId,
              session_id: wegoAnalyticsClientSessionId,
              user_hash: user?.userHash,
              user_agent: window.navigator.userAgent,
            },
            experiments: coreConfigs
              .filter((coreConfig) => coreConfig.experimentName && coreConfig.variantName)
              .map((coreConfig) => ({
                experiment: coreConfig.experimentName,
                variant: coreConfig.variantName,
              })),
          };

          await genzoTrack(`${apiBaseUrl}/genzo/v3/experiments/event`, data);
        }
      };

      getConnectAPIResponseAndSendToGenzo();
    }
  }, [coreConfigs, wegoAnalyticsClientId, wegoAnalyticsClientSessionId]);

  useEffect(() => {
    if (isMobileApp) {
      const initUser = async (): Promise<void> => {
        try {
          const wegoUserInformation = await retrieveWegoUserInformation(domainBasedApiBaseUrl);

          const expireByDate = new Date();
          expireByDate.setDate(expireByDate.getDate() + 1);

          const user: User = {
            loggedInType: LoggedInType.Wego,
            displayName: !!wegoUserInformation.name.trim()
              ? wegoUserInformation.name
              : wegoUserInformation.email,
            title: wegoUserInformation.title,
            firstName: wegoUserInformation.first_name,
            lastName: wegoUserInformation.last_name,
            nationality: wegoUserInformation.nationality,
            countryCode: wegoUserInformation.country_code,
            phoneCountryCode: wegoUserInformation.phone_country_code,
            phoneNumber: wegoUserInformation.phone_number,
            email: wegoUserInformation.email,
            csRestoreId: wegoUserInformation.cs_restore_id,
            photoUrl: wegoUserInformation.photo_url ?? undefined,
            userHash: wegoUserInformation.user_hash,
            idHash: wegoUserInformation.id_hash,
            sprinklrUserHash: wegoUserInformation.sprinklr_user_hash,
            accountPhoneNumber: wegoUserInformation.account_phone_number,
            accountPhoneCountryCode: wegoUserInformation.account_phone_country_code,
            expireBy: expireByDate.getTime(),
          };

          dispatch(changeUser(user));
        } catch (error) {
          dispatch(resetUser());
        }
      };

      initUser();
    }
  }, []);

  // Revalidate login user when access token is expired
  useEffect(() => {
    const nowMilliseconds = Date.now();

    if (usedNewLogin) {
      return;
    }

    if (!!user && user.expireBy > nowMilliseconds) {
      const wegorefreshTimeout = setTimeout(async (): Promise<void> => {
        const expireBy = await wegoRefreshSignIn(domainBasedApiBaseUrl);
        dispatch(changeUser({ ...user, expireBy }));
      }, user.expireBy - nowMilliseconds);

      return () => {
        clearTimeout(wegorefreshTimeout);
      };
    }
  }, [user, usedNewLogin]);

  // Get nearest city based on IP address of the request
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initNearestCity = async (cancelToken: CancelToken): Promise<void> => {
      const url = appendSearchParams(`${apiBaseUrl}/places/search/nearest`, {
        locale,
        'types[]': 'city',
      });

      try {
        const response = await axios.get<Array<Location>>(url, {
          cancelToken,
          withCredentials: true,
        });

        if (response.status === 200) {
          setNearestCity(response.data[0]);
        }
      } catch (_) {}
    };

    initNearestCity(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [apiBaseUrl, locale]);

  // Get homepage data
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initHomepage = async (cancelToken: CancelToken): Promise<void> => {
      const url = appendSearchParams(`${apiBaseUrl}/places/homepages`, {
        site_code: currentSite.countryCode,
        language: locale,
      });

      try {
        const response = await axios.get<Homepage>(url, { cancelToken, withCredentials: true });

        if (response.status === 200) {
          setHomepage(response.data);
        }
      } catch (_) {}
    };

    initHomepage(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [apiBaseUrl, locale]);

  return (
    <>
      <AuthProvider
        authConfig={authConfig({
          locale,
          siteCode: currentSite.countryCode,
          domainBasedApiBaseUrl,
          dispatch,
          wegoAnalyticsClientId: wegoAnalyticsClientId as string,
          wegoAnalyticsClientSessionId: wegoAnalyticsClientSessionId as string,
          vendorId,
          coreConfigs,
          enabled: isAuthNew || false,
          searchParams,
          history,
        })}
      >
        <AuthMagicLink />
        <Suspense fallback={null}>
          <Switch>
            <Route exact strict path={homeRoutesPaths}>
              <HomeRoutes
                apiBaseUrl={apiBaseUrl}
                clientId={wegoAnalyticsClientId}
                clientSessionId={wegoAnalyticsClientSessionId}
                currency={currency}
                currentSite={currentSite}
                domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                exchangeRate={exchangeRate}
                homepage={homepage}
                isRtl={isRtl}
                locale={locale}
                nearestCity={nearestCity}
                translations={translations}
                user={user}
                shopCashUser={shopCashUser}
              />
            </Route>
            <Route exact strict path={flightRoutesPaths}>
              <FlightRoutes
                currentSite={currentSite}
                locale={locale}
                translations={translations}
                isRtl={isRtl}
                currency={currency}
                exchangeRate={exchangeRate}
                apiBaseUrl={apiBaseUrl}
                domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                user={user}
                shopCashUser={shopCashUser}
                clientId={wegoAnalyticsClientId}
                clientSessionId={wegoAnalyticsClientSessionId}
                vendorId={wegoVendorId}
                homepageFlights={homepage?.flights}
                nearestCity={nearestCity}
                onInjectReducers={onInjectReducers}
              />
            </Route>
            <Route exact strict path={hotelRoutesPaths}>
              <HotelRoutes
                clientId={wegoAnalyticsClientId}
                clientSessionId={wegoAnalyticsClientSessionId}
                homepageHotels={homepage?.hotels}
                nearestCity={nearestCity}
                onInjectReducers={onInjectReducers}
                shopCashUser={shopCashUser}
              />
            </Route>
            <Route exact strict path={bookingRoutesPaths}>
              <BookingRoutes
                locale={locale}
                translations={translations}
                isRtl={isRtl}
                exchangeRate={exchangeRate}
                currentSite={currentSite}
                apiBaseUrl={apiBaseUrl}
              />
            </Route>
            <Route exact strict path={preferencesRoutesPaths}>
              <PreferencesPage
                currency={currency}
                currentSite={currentSite}
                domainBasedApiBaseUrl={domainBasedApiBaseUrl}
                exchangeRate={exchangeRate}
                isRtl={isRtl}
                locale={locale}
                shopCashUser={shopCashUser}
                translations={translations}
                user={user}
              />
            </Route>
          </Switch>
        </Suspense>
      </AuthProvider>
    </>
  );
};

export default LocaleRoutes;
