export enum PaymentCard {
  Amex = 'American Express',
  DinersClub = 'Diners Club',
  Discover = 'Discover',
  Elo = 'Elo',
  Hiper = 'Hiper',
  Hipercard = 'Hipercard',
  Jcb = 'JCB',
  Knet = 'KNET',
  Mada = 'Mada',
  Maestro = 'Maestro',
  Mastercard = 'Mastercard',
  Mir = 'Mir',
  UnionPay = 'UnionPay',
  Visa = 'Visa',
  Tabby = 'TABBY',
  Xpay = 'xpay',
}
