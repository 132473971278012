import { TranslationsActionTypes } from '../actions/types';

const initialState: { [key: string]: string } = {};

export default function (
  state: { [key: string]: string } = initialState,
  action: { type: TranslationsActionTypes; payload: { [key: string]: string } },
): { [key: string]: string } {
  switch (action.type) {
    case TranslationsActionTypes.ChangeTranslations:
      return action.payload;
    default:
      return state;
  }
}
