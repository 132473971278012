import { appendSearchParams } from '@alexis/helpers/searchParams';
import axios, { CancelTokenSource, CancelToken } from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { useSearchParams } from '@hooks/useSearchParams';

import {
  getTranslationsState,
  getCurrentSiteState,
  getLocaleState,
  getApiBaseUrlState,
} from '@redux/selectors';

import styles from './MiniBlog.module.scss';
import MiniBlogCard from './MiniBlogCard';

const MiniBlog = () => {
  const [stories, setStories] = useState<Array<Story>>([]);

  const searchParams = useSearchParams();
  const translations = useSelector(getTranslationsState);
  const currentSite = useSelector(getCurrentSiteState);
  const locale = useSelector(getLocaleState);
  const apiBaseUrl = useSelector(getApiBaseUrlState);

  const isBowOnly = searchParams.get('bow_only') === 'true';

  // Get stories
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const initStories = async (cancelToken: CancelToken): Promise<void> => {
      const url = appendSearchParams(`${apiBaseUrl}/story/v1/homepages`, {
        locale,
        site_code: currentSite.countryCode,
      });

      try {
        const response = await axios.get<Array<any>>(url, { cancelToken });

        if (response.status === 200) {
          setStories(response.data);
        }
      } catch (error) {
        console.error('error fetching stories', error);
      }
    };

    initStories(cancelTokenSource.token);

    return () => {
      cancelTokenSource.cancel('Component unmount.');
    };
  }, [currentSite, locale]);

  return (
    <>
      {stories.length > 2 && !isBowOnly ? (
        <>
          <Link className={styles.sectionLink} to='/'>
            <div className={styles.heading}>{translations.title_stories}</div>
          </Link>

          <div className={styles.stories}>
            {stories.map((story) => {
              return <MiniBlogCard key={story.id} className={styles.story} story={story} />;
            })}
          </div>
        </>
      ) : null}
    </>
  );
};

export default MiniBlog;
