import type { WorkboxLifecycleWaitingEvent } from 'workbox-window';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export async function register(): Promise<void> {
  if (
    !(
      window.location.search.indexOf('app_client=olympias') !== -1 ||
      /^(?:\/[a-zA-Z]{2}|\/[a-zA-Z]{2}\-[a-zA-Z]{2})?\/booking/.test(window.location.pathname) ||
      /^(?:\/[a-zA-Z]{2}|\/[a-zA-Z]{2}\-[a-zA-Z]{2})?\/booking\/confirmation/.test(
        window.location.pathname,
      )
    )
  ) {
    if ('serviceWorker' in navigator) {
      const isHostnameSaudiArabia = ['sa-beta.wegostaging.com', 'sa.wego.com'].includes(
        window.location.hostname,
      );

      const { Workbox } = await import('workbox-window');

      const wb = new Workbox(
        isHostnameSaudiArabia ? '/olympias-service-worker-sa.js' : '/olympias-service-worker.js',
      );

      const showSkipWaitingPrompt = async (event: WorkboxLifecycleWaitingEvent) => {
        wb.addEventListener('controlling', () => {
          window.location.reload();
        });

        wb.messageSkipWaiting();
      };

      wb.addEventListener('waiting', (event) => {
        showSkipWaitingPrompt(event);
      });

      await wb.register();
      document.dispatchEvent(new CustomEvent('registerServiceWorker'));
    }
  }
}
