import { translateText } from '@alexis/helpers/translation';
import { escapeRegExp, uniqBy } from 'lodash-es';

import {
  Itinerary,
  Passenger,
  SegmentDetails,
} from '@components/flightBooking/BookingHistoryDetails/FlightBookingDetailsResponse';
import { FeePrice } from '@components/flightBooking/PaymentForm/hooks/useGetPaymentFees';

import { Translations } from '@wegoTypes/translations';
import { BookingStatusText } from '@wegoTypes/WegoFares/bookingStatusText';

import { getDateWithUTCtimeZone } from './date';
import { convertBrandedFarePriceToPrice, convertBrandedFareWithoutBookingFee } from './price';

export function sumMultiBrandedFares(
  brandedFarePrices: Array<BrandedFarePrice>,
  isExcludeBookingFee: boolean = false,
): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFarePrices[0].currencyCode,
  };
  brandedFarePrices.forEach((brandedFarePrice) => {
    const brandedFare = isExcludeBookingFee
      ? convertBrandedFareWithoutBookingFee(brandedFarePrice)
      : convertBrandedFarePriceToPrice(brandedFarePrice);
    price.amount += brandedFare.amount;
    price.amountUsd += brandedFare.amountUsd;
  });
  return price;
}

export function sumAllBrandedFaresPrice(
  brandedFarePrices: Array<BrandedFarePrice>,
  addOnFarePrice?: AddonFarePrice | undefined,
  paymentProcessingFee?: PaymentProcessingFee | FeePrice | undefined,
  promo?: FlightReservePromoCodeResponse,
  seatTotalFares?: SeatTotalFares,
): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFarePrices[0].currencyCode,
  };
  brandedFarePrices.forEach((brandedFarePrice) => {
    const brandedFare = convertBrandedFarePriceToPrice(brandedFarePrice);
    price.amount += brandedFare.amount;
    price.amountUsd += brandedFare.amountUsd;
  });

  if (!!addOnFarePrice) {
    price.amount += addOnFarePrice.totalAmount;
    price.amountUsd += addOnFarePrice.totalAmountUsd;
  }

  if (!!paymentProcessingFee) {
    const totalProcessingFee = paymentProcessingFee.amount;
    const totalProcessingInUsd = paymentProcessingFee.amountInUsd;
    price.amount += totalProcessingFee;
    price.amountUsd += totalProcessingInUsd;
  }

  if (!!promo?.applicable) {
    price.amount -= promo.amount;
    price.amountUsd -= promo.amountUsd;
  }
  if (!!seatTotalFares?.totalPrice) {
    price.amount += seatTotalFares?.totalPrice;
    price.amountUsd += seatTotalFares?.totalPriceUSD;
    price.currencyCode = seatTotalFares?.currencyCode;
  }

  return price;
}

export function sumAllBookingFees(brandedFares: Array<BrandedFareV2>): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFares[0].price.currencyCode,
  };
  brandedFares.forEach((brandedFare) => {
    price.amount += brandedFare.price.totalBookingFee;
    price.amountUsd += brandedFare.price.totalBookingFeeUsd;
  });
  return price;
}

export const getAirlinePnrByPassenger = (
  segments: SegmentDetails[],
  passenger: Passenger,
): string => {
  const eticketViews = segments
    .map((segment) => segment.eticketViews)
    .reduce((acc, val) => acc.concat(val), []);
  const airlinePnrs = eticketViews.filter(
    (eticketView) => eticketView.passengerId === passenger.id,
  );
  return uniqBy(
    airlinePnrs.map((airlinePnr) => airlinePnr.airlineRef),
    'airlineRef',
  ).join(' / ');
};

export const getBookingStatusByText = (bookingStatusCode?: BookingStatusText) => {
  switch (bookingStatusCode) {
    case BookingStatusText.ticketed:
    case BookingStatusText.completed:
    case BookingStatusText.confirmed:
      return 'success';
    case BookingStatusText.failed:
      return 'failed';
    case BookingStatusText.validated:
    case BookingStatusText.reviewInProcess:
      return 'pending';
    case BookingStatusText.offline:
      return 'offline';

    default:
      return 'default';
  }
};

export const getBookingStatusMsg = (
  isModifiedOffline: boolean,
  bookingStatusText: BookingStatusText | undefined,
  isLoading: boolean,
  translations: Translations,
  locale: string,
  userEmail: string | null,
  refundDetails?:
    | {
        currencyCode: string;
        refundAmount: number;
        refundDate: string;
        chargedCurrencyCode: string;
        refundAmountInChargedCurrency: number;
      }
    | undefined,
) => {
  if (isLoading) {
    return {
      title: translations.processing_your_booking as string,
      message: translations.bow_flights_loading_desc as string,
      subTitle: translations.please_wait as string,
    };
  }
  let status = {
    title: translations.processing_your_booking as string,
    message: translations.please_wait as string,
    subTitle: '',
  };

  if (isModifiedOffline) {
    status.title = translations.booking_modified_offline as string;
    status.message = translateText(
      translations.booking_modified_offline_desc,
      locale ?? '',
      userEmail ?? '',
    );
    return status;
  }

  switch (bookingStatusText) {
    case BookingStatusText.ticketed:
    case BookingStatusText.completed:
      status.title = translations.hope_pleasant_flight as string;
      status.message = translations.next_adventure as string;
      status.subTitle = translations.thanks_for_booking_wego as string;
      break;
    case BookingStatusText.confirmed:
      status.title = translations.booking_confirmed as string;
      status.message = translateText(
        translations.booking_confirm_desc,
        locale ?? '',
        userEmail ?? '',
      );
      break;
    case BookingStatusText.onHold:
      status.title = translations.booking_on_hold as string;
      status.message = translations.booking_on_hold_desc as string;
      break;
    case BookingStatusText.refundIsProcessed:
    case BookingStatusText.processingRefund:
      status.title = translations.booking_cancelled as string;
      status.message = !!refundDetails
        ? translateText(
            translations.booking_in_process_desc,
            locale,
            refundDetails?.chargedCurrencyCode,
            refundDetails?.refundAmountInChargedCurrency?.toFixed(2),

            getDateWithUTCtimeZone(refundDetails.refundDate, locale),
          )
        : '';
      break;
    case BookingStatusText.cancelled:
      status.title = translations.booking_cancelled as string;
      status.message = translations.booking_cancelled_desc as string;
      break;

    case BookingStatusText.reviewInProcess:
      status.title = translations.processing_your_booking as string;
      status.message = translations.thanks_for_choosing_wego as string;
      break;
    case BookingStatusText.validated:
      status.title = translations.booking_in_process as string;
      status.message = translations.booking_in_review_desc as string;
      break;
    case BookingStatusText.failed:
      status.title = translations.booking_failed as string;
      status.message = translations.bow_flights_failed_desc as string;
      break;
    default:
      status.title = translations.unable_to_find_booking as string;
      status.message = translations.retrieve_booking_message as string;
      break;
  }
  return status;
};

export function fillEndpointWithParams(url: string, params: Array<string>): string {
  params.forEach((param: string, index: number) => {
    const replacement = new RegExp(escapeRegExp(`{${index}}`), 'g');

    url = url.replace(replacement, param);
  });
  return url.trim();
}

export function getTotalPrice<T>(
  arrayOfObject: T[],
  keysToExtract: { amountKey: keyof T; amountInUsdKey: keyof T; currencyCodeKey: keyof T },
): Price {
  return arrayOfObject.reduce(
    (price, object) => {
      const { amountKey, amountInUsdKey, currencyCodeKey } = keysToExtract;
      const {
        [amountKey]: amount,
        [amountInUsdKey]: amountInUsd,
        [currencyCodeKey]: currencyCode,
      } = object;
      return {
        amount: price.amount + (amount as number),
        amountUsd: price.amountUsd + (amountInUsd as number),
        currencyCode,
      } as Price;
    },
    {
      amount: 0,
      amountUsd: 0,
      currencyCode: '',
    },
  );
}

export function getTripInfo(itineraries: Itinerary[]) {
  const isRoundTrip = itineraries[0].legs.length > 1;
  if (isRoundTrip) {
    // For round trip, we only need to show the 2nd leg info
    const leg = itineraries[0].legs[1];
    return [
      {
        legId: leg.legId,
        departureCityName: leg.departureCityName,
        arrivalCityName: leg.arrivalCityName,
        airlineCode: leg.airlineCodes,
        status: leg.status,
      },
    ];
  }

  const legsWithLegId = itineraries.map((itinerary) => {
    return itinerary.legs.map((leg) => {
      return {
        ...leg,
        legId: itinerary.legId,
      };
    });
  });

  const legs = legsWithLegId.reduce((acc, leg) => acc.concat(leg), []);

  return legs.map((leg) => {
    return {
      legId: leg.legId,
      departureCityName: leg.departureCityName,
      arrivalCityName: leg.arrivalCityName,
      airlineCode: leg.airlineCodes,
      status: leg.status,
    };
  });
}

export const getFareComparisonPageUrl = (pathname: string, queryString: string) => {
  const separator = !queryString.includes('?') ? '?' : '';
  return `${pathname}${separator}${queryString}`;
};
