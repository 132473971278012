import type { User } from '@alexis/types/user';

import { UserActionTypes } from './types';

export const changeUser = (user: User): { type: UserActionTypes; payload: User } => ({
  type: UserActionTypes.ChangeUser,
  payload: user,
});

export const resetUser = (): { type: UserActionTypes; payload: null } => ({
  type: UserActionTypes.ResetUser,
  payload: null,
});
