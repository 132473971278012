import { ThunkAction } from 'redux-thunk';

import { TranslationsActionTypes } from './types';

function loadTranslationsJson(locale: string): Promise<{ [key: string]: string }> {
  // return import(/* webpackChunkName: "translations/[request]" */ /* webpackMode: "lazy-once" */ `../../translations/${locale}.json`);
  return import(
    /* webpackChunkName: "translations/[request]" */ `../../translations/${locale}.json`
  );
}

export const changeTranslations =
  (
    locale: string,
  ): ThunkAction<
    Promise<void>,
    { [key: string]: string },
    undefined,
    { type: TranslationsActionTypes; payload: { [key: string]: string } }
  > =>
  async (dispatch) => {
    const translationsJson = await loadTranslationsJson(locale);
    dispatch({ type: TranslationsActionTypes.ChangeTranslations, payload: translationsJson });
  };
