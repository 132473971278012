import { localStorageGet, localStorageSave } from '@alexis/helpers/localStorage';
import { FlightSearch } from 'flightSearch';

import { FlightRecentSearchesActionTypes } from '../actions/types';

const flightRecentSearchesLocalStorageKey = 'flightRecentSearches';
let flightRecentSearches: Array<FlightSearch> = [];

const localStorageFlightRecentSearches = localStorageGet<Array<FlightSearch>>(
  flightRecentSearchesLocalStorageKey,
);

if (!!localStorageFlightRecentSearches) {
  const today = new Date();
  const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

  flightRecentSearches = localStorageFlightRecentSearches.filter(
    (localStorageFlightRecentSearch) =>
      !(localStorageFlightRecentSearch.departureDateMilliseconds! < todayDateMilliseconds),
  );
  localStorageSave(flightRecentSearchesLocalStorageKey, flightRecentSearches);
}

const initialState: Array<FlightSearch> = flightRecentSearches;

export default function (
  state: Array<FlightSearch> = initialState,
  action: { type: FlightRecentSearchesActionTypes; payload: FlightSearch | Array<FlightSearch> },
): Array<FlightSearch> {
  switch (action.type) {
    case FlightRecentSearchesActionTypes.AddFlightRecentSearch: {
      const newRecentSearch = action.payload as FlightSearch;
      const stringifyPayload = JSON.stringify({
        tripType: newRecentSearch.tripType,
        departureLocationType: newRecentSearch.departureLocation?.type,
        departureLocationCode: newRecentSearch.departureLocation?.code,
        arrivalLocationType: newRecentSearch.arrivalLocation?.type,
        arrivalLocationCode: newRecentSearch.arrivalLocation?.code,
        departureDateMilliseconds: newRecentSearch.departureDateMilliseconds,
        returnDateMilliseconds: newRecentSearch.returnDateMilliseconds,
        adultCount: newRecentSearch.adultCount,
        childrenCount: newRecentSearch.childrenCount,
        infantCount: newRecentSearch.infantCount,
      });
      const today = new Date();
      const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

      const checkedFlightRecentSearches = state.filter((flightRecentSearch) => {
        const stringifyFlightRecentSearch = JSON.stringify({
          tripType: flightRecentSearch.tripType,
          departureLocationType: flightRecentSearch.departureLocation?.type,
          departureLocationCode: flightRecentSearch.departureLocation?.code,
          arrivalLocationType: flightRecentSearch.arrivalLocation?.type,
          arrivalLocationCode: flightRecentSearch.arrivalLocation?.code,
          departureDateMilliseconds: flightRecentSearch.departureDateMilliseconds,
          returnDateMilliseconds: flightRecentSearch.returnDateMilliseconds,
          adultCount: flightRecentSearch.adultCount,
          childrenCount: flightRecentSearch.childrenCount,
          infantCount: flightRecentSearch.infantCount,
        });

        return (
          stringifyFlightRecentSearch !== stringifyPayload &&
          !(flightRecentSearch.departureDateMilliseconds! < todayDateMilliseconds)
        );
      });

      // Maintain a maximum of 5 flight recent searches record
      const currentFlightRecentSearches = [
        action.payload as FlightSearch,
        ...checkedFlightRecentSearches.slice(0, 4),
      ];
      localStorageSave(flightRecentSearchesLocalStorageKey, currentFlightRecentSearches);
      return currentFlightRecentSearches;
    }
    case FlightRecentSearchesActionTypes.RemoveFlightRecentSearch: {
      const newRecentSearch = action.payload as FlightSearch;
      const stringifyPayload = JSON.stringify({
        tripType: newRecentSearch.tripType,
        departureLocationType: newRecentSearch.departureLocation?.type,
        departureLocationCode: newRecentSearch.departureLocation?.code,
        arrivalLocationType: newRecentSearch.arrivalLocation?.type,
        arrivalLocationCode: newRecentSearch.arrivalLocation?.code,
        departureDateMilliseconds: newRecentSearch.departureDateMilliseconds,
        returnDateMilliseconds: newRecentSearch.returnDateMilliseconds,
        adultCount: newRecentSearch.adultCount,
        childrenCount: newRecentSearch.childrenCount,
        infantCount: newRecentSearch.infantCount,
      });
      const today = new Date();
      const todayDateMilliseconds = today.setHours(0, 0, 0, 0);

      const checkedFlightRecentSearches = state.filter((flightRecentSearch) => {
        const stringifyFlightRecentSearch = JSON.stringify({
          tripType: flightRecentSearch.tripType,
          departureLocationType: flightRecentSearch.departureLocation?.type,
          departureLocationCode: flightRecentSearch.departureLocation?.code,
          arrivalLocationType: flightRecentSearch.arrivalLocation?.type,
          arrivalLocationCode: flightRecentSearch.arrivalLocation?.code,
          departureDateMilliseconds: flightRecentSearch.departureDateMilliseconds,
          returnDateMilliseconds: flightRecentSearch.returnDateMilliseconds,
          adultCount: flightRecentSearch.adultCount,
          childrenCount: flightRecentSearch.childrenCount,
          infantCount: flightRecentSearch.infantCount,
        });

        return (
          stringifyFlightRecentSearch !== stringifyPayload &&
          !(flightRecentSearch.departureDateMilliseconds! < todayDateMilliseconds)
        );
      });

      localStorageSave(flightRecentSearchesLocalStorageKey, checkedFlightRecentSearches);
      return checkedFlightRecentSearches;
    }
    case FlightRecentSearchesActionTypes.ClearFlightRecentSearches: {
      localStorageSave(flightRecentSearchesLocalStorageKey, []);
      return [];
    }
    case FlightRecentSearchesActionTypes.UpdateFlightRecentSearches: {
      const newRecentSearches = action.payload as Array<FlightSearch>;
      return [...newRecentSearches];
    }
    default:
      return state;
  }
}
