import clsx from '@alexis/helpers/clsx';
import { translateNumber } from '@alexis/helpers/translation';
import type { PriceProps } from 'props/priceProps';
import React, { useEffect, useState } from 'react';

import { isBigValueCurrency } from '@helpers/price';

import styles from '../styles/components/Price.module.scss';

const Price: React.FC<PriceProps> = ({
  className,
  currency,
  exchangeRate,
  id,
  isInline,
  isStrikethrough,
  locale,
  price,
  showDecimal,
  style,
  amountContainerId,
  dataPw,
}): JSX.Element => {
  const [showAdditionalZeroPlaceholder, setShowAdditionalZeroPlaceholder] =
    useState<boolean>(false);
  const [amountDisplay, setAmountDisplay] = useState<string>('');

  const [isNegative, setIsNegative] = useState<boolean>(false);

  useEffect(() => {
    const decimalPoints: number = showDecimal ? 2 : 0;
    const isPriceCurrencySameAsSelectedCurrency = price.currencyCode === currency.code;

    let amount = isPriceCurrencySameAsSelectedCurrency
      ? price.amount
      : price.amountUsd * exchangeRate?.rate;

    if (amount < 0) {
      amount = amount * -1;
      setIsNegative(true);
    } else {
      setIsNegative(false);
    }

    if (isBigValueCurrency(currency.code || '') && amount > 99999) {
      amount = amount / 1000;
      setShowAdditionalZeroPlaceholder(true);
    } else {
      setShowAdditionalZeroPlaceholder(false);
    }

    const stringAmount =
      isBigValueCurrency(currency.code || '') || amount > 99999
        ? amount.toFixed(0)
        : amount.toFixed(decimalPoints);
    const stringAmountLength = stringAmount.length;

    let modValue: number = 0;

    if (!(isBigValueCurrency(currency.code || '') || amount > 99999) && showDecimal) {
      modValue = stringAmountLength - 3 > 3 ? stringAmountLength % 3 : 0;
    } else {
      modValue = stringAmountLength > 3 ? stringAmountLength % 3 : 0;
    }

    setAmountDisplay(
      translateNumber(
        `${
          !!modValue ? `${stringAmount.substr(0, modValue)}${currency.separator}` : ''
        }${stringAmount.substr(modValue).replace(/(\d{3})(?=\d)/g, `$1${currency.separator}`)}`,
        locale === 'fa',
      ),
    );
  }, [price, locale, currency, exchangeRate, showDecimal]);

  function symbolPlaceholderView(isInline: boolean): JSX.Element {
    if (isInline) {
      return <span className={styles.symbol}>{currency.symbol}</span>;
    }
    return <sup className={styles.symbol}>{currency.symbol}</sup>;
  }

  return (
    <div
      data-pw={dataPw}
      id={id ?? ''}
      className={clsx(styles.container, isStrikethrough && styles.strikethrough, className)}
      style={style}
    >
      {isNegative ? '-' : null}

      {currency.symbolFirst ? symbolPlaceholderView(!!isInline) : null}

      <span
        id={amountContainerId}
        data-currency-code={currency.code}
        data-price-accuracy={showDecimal ? 2 : 0}
        className={clsx(
          styles.amount,
          !showAdditionalZeroPlaceholder && !currency.symbolFirst && styles.withRightMargin,
        )}
      >
        {amountDisplay}
      </span>

      {showAdditionalZeroPlaceholder ? (
        isInline ? (
          <span className={clsx(!currency.symbolFirst && styles.withRightMargin)}>
            {translateNumber(`${currency.separator}000`, locale === 'fa')}
          </span>
        ) : (
          <sup className={styles.superscript}>
            {translateNumber(`${currency.separator}000`, locale === 'fa')}
          </sup>
        )
      ) : null}

      {!currency.symbolFirst ? symbolPlaceholderView(!!isInline) : null}
    </div>
  );
};

export default Price;
